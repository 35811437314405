/* Actions */
import { InvestmentMainImportReceivedAction, InvestmentResetDataStoredAction, InvestmentSetIterationsAction, InvestmentResetIterationsAction, LoanSetIterationsAction, LoanResetLoanIterationsInformation } from "./investment_actions"
import { DefaultType } from '../../utils/action-type-helper'

/* Constants */
import * as ACTION_NAMES from "../action_names"

/* State */
import { initialState, InvestmentState } from "./investment_states"

/*Storage action*/
import { webStorage } from "../../utils/web_storage"

type Actions = InvestmentMainImportReceivedAction | InvestmentResetDataStoredAction | InvestmentSetIterationsAction | LoanSetIterationsAction | InvestmentResetIterationsAction | LoanResetLoanIterationsInformation;

function investmentReducer( state = initialState, action: Actions ) {
    let stateToModify: InvestmentState = JSON.parse( JSON.stringify( state ) );
    let indexInvestmentToModify = null;
    let indexLoanToModify = null;

    switch ( action.type ) {
        case ACTION_NAMES.MAIN_IMPORT_RECEIVED:
            let data = JSON.parse( action.payload.investment ) as InvestmentState;
            let investmentData = data ? data : state;
            return Object.assign( {}, state, investmentData );

        case ACTION_NAMES.RESET_DATA_STORED:
            webStorage.clearWebStorage();
            return initialState;

        case ACTION_NAMES.INVESTMENT_SET_ITERATIONS_INFORMATION:
            return Object.assign( {}, state, {
                investment: action.payload.investment,
                investmentTable: action.payload.investmentIterations
            } );


        case ACTION_NAMES.INVESTMENT_RESET_ITERATIONS_INFORMATION:
            return Object.assign( {}, state, {
                investment: initialState.investment,
                investmentTable: []
            } );

        case ACTION_NAMES.LOAN_SET_ITERATIONS_INFORMATION:
            return Object.assign( {}, state, {
                loan: action.payload.loan,
                loanTable: action.payload.loanIterations
            } );

        case ACTION_NAMES.LOAN_RESET_ITERATIONS_INFORMATION:
            return Object.assign( {}, state, {
                loan: initialState.loan,
                loanTable: []
            } );

        default:
            return state;
    }

};

export default investmentReducer;

