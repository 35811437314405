declare var navigator: any

const WS_KEYS = {
    MAIN_KEY: "persist:root",
    LANGUAGE: 'language',
    LAST_TRIGGERED_UPDATE: 'last_update',
    LOGIN_REDIRECT: 'login_redirect',
    IBKR_GW: 'ibkr_gw'
}

//exported webStorage utils
export const webStorage = {

    getMainData,

    getIBKRGateway,
    storeIBKRGateway,

    getLanguage,
    storeLanguage,

    getLastTriggeredUpdate,
    storeLastTriggeredUpdate,

    getLoginRedirect,
    storeLoginRedirect,
    clearLoginRedirect,

    clearWebStorage

}

function getMainData(): string {
    return getFromStorage( WS_KEYS.MAIN_KEY, true )
}

function storeLanguage( language: string ) {
    storeToStorage( WS_KEYS.LANGUAGE, language, true )
}

function getLanguage( withoutRegion?: boolean ): string {
    let storedLanguage = getFromStorage( WS_KEYS.LANGUAGE, true )

    if ( !storedLanguage ) {
        /* Define user's language. Different browsers have the user locale defined
        on different fields on the `navigator` object, so we make sure to account
        for these different by checking all of them */
        storedLanguage = ( navigator.languages && navigator.languages[0] ) || navigator.language || navigator.userLanguage
        storeLanguage( storedLanguage )
    }

    return withoutRegion ? storedLanguage.toLowerCase().split( /[_-]+/ )[0] : storedLanguage
}

function getLastTriggeredUpdate(): string {
    return getFromStorage( WS_KEYS.LAST_TRIGGERED_UPDATE, true )
}

function storeLastTriggeredUpdate( date: string ) {
    storeToStorage( WS_KEYS.LAST_TRIGGERED_UPDATE, date, true )
}

function getLoginRedirect(): string {
    return getFromStorage( WS_KEYS.LOGIN_REDIRECT, false )
}

function storeLoginRedirect( redirectTo: string ) {
    storeToStorage( WS_KEYS.LOGIN_REDIRECT, redirectTo, false )
}

function clearLoginRedirect() {
    clearFromStorage( WS_KEYS.LOGIN_REDIRECT, false )
}

//clearing function

function clearWebStorage() {

    //keeping a copy of some fields used for bootstrapping the app    
    const language = getLanguage()
    const gateway = getIBKRGateway()

    //clearing all values    
    window.localStorage.clear()
    window.sessionStorage.clear()

    //resetting the saved values
    storeLanguage( language )
    storeIBKRGateway( gateway )
}


//utils

function storeToStorage( key: string, value: any, localStorage?: boolean, encoded?: boolean ) {
    const storage = localStorage ? window.localStorage : window.sessionStorage

    if ( storage ) {
        storage.setItem( key, encoded ? btoa( JSON.stringify( value ) ) : JSON.stringify( value ) )
    }
}

function getFromStorage( key: string, localStorage?: boolean, encoded?: boolean ): any {
    const storage = localStorage ? window.localStorage : window.sessionStorage

    if ( storage ) {

        try {
            return storage.getItem( key ) ? JSON.parse( encoded ? atob( storage.getItem( key ) ) : storage.getItem( key ) ) : undefined
        }
        catch ( e ) {
            console.warn( e )
        }
    }

    return undefined
}

function clearFromStorage( key: string, localStorage?: boolean ) {
    const storage = localStorage ? window.localStorage : window.sessionStorage

    if ( storage ) {
        storage.removeItem( key )
    }
}

function getIBKRGateway(): string {
    return getFromStorage( WS_KEYS.IBKR_GW, true )
}

function storeIBKRGateway( gatewayUrl: string ) {
    storeToStorage( WS_KEYS.IBKR_GW, gatewayUrl, true )
}