import React from 'react';
import { Modal, Input, Form, Row, Col, Button } from 'antd';
import * as uuid from 'uuid';

/*Models*/
import { Product, PayloadString } from '../../models/index'

interface Props {
    visible: boolean;
    productId: string;
    productName: string;
    closeModal: () => void;
    editProduct: ( value: PayloadString ) => void;
}

interface State {
    name: string;
}

class ProductCostEditModal extends React.Component<Props, State> {

    constructor( props: Props ) {
        super( props );

        this.state = {
            name: props.productName
        }
    }

    private handleOk = ( values: any ) => {
        this.props.editProduct( {
            key: this.props.productId,
            value: values.productName,
        } );
    };

    private hideModal = () => {
        this.props.closeModal();
    };

    private handleChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
        this.setState( { name: event.target.value } )
    }

    render() {

        const { visible } = this.props


        return ( <div>
            <Modal
                title="Mettre à jour le produit"
                visible={visible}
                footer={null}
                onCancel={this.hideModal}
            >
                <Form id="modal-create-new-product" onFinish={( values ) => this.handleOk( values )} initialValues={this.props}>

                    <Form.Item label="Nom du produit" name="productName">
                        <Input type="text" placeholder="Saisir le nom du produit" name="productName" />
                    </Form.Item>
                    <Form.Item>
                        <Row justify="end">
                            <Col>
                                <Button onClick={this.hideModal}>Annuler</Button>
                                <Button type="primary" htmlType="submit">Mettre à jour</Button>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            </Modal>

        </div> );
    };
}

export default ProductCostEditModal;