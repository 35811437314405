import React from 'react';
import { Menu, notification, Row, Col } from "antd"
import { MapStateToProps, connect } from 'react-redux'
import {
    SaveOutlined,
    UserOutlined,
    VerticalAlignBottomOutlined,
    UnorderedListOutlined,
    DeleteOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    CheckOutlined
} from '@ant-design/icons';
import Cookies from 'js-cookie';
/* Helpers */
import { webStorage } from "./utils/web_storage"
import { saveDataAsJSONFile } from "./utils/helpers"

/* Models */
import { IRootState } from './redux/store'

/*Components*/
import DropboxAuthorizationModal from './components/authorization/dropbox_authorization'

export interface Props {
    onResetDataStored: () => void;
    saveFile: () => void;
    loadFile: () => void;
    toggle: () => void;
    collapsed: boolean;
}

export interface State {
    visibleModalApiKey: boolean
}

class App_ToolBar extends React.Component<Props, State> {
    public static select: MapStateToProps<Props, State, IRootState> = ( state: IRootState ) => {
        return {
        } as Props
    }

    constructor( props: any ) {

        super( props );
        this.state = {
            visibleModalApiKey: false
        }
    }


    render() {
        const { visibleModalApiKey } = this.state
        const { collapsed, toggle } = this.props

        let token = Cookies.get( "access_token" );

        return ( <>
            <DropboxAuthorizationModal closeModal={this.hideModal} visible={visibleModalApiKey}></DropboxAuthorizationModal>

            <Row >
                <Col flex="60px">
                    {React.createElement( collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                        className: 'responsive-button-sidebar-trigger',
                        onClick: toggle,
                    } )}</Col>
                <Col flex="auto">
                    <Row justify="end">
                        <Col >
                            <div className='menu-large-screen'>
                                <Menu mode="horizontal" onClick={this.handleClick}>
                                    {!!token ? ( <Menu.Item key="logged" disabled icon={<UserOutlined />}>Compte Dropbox Connecté !</Menu.Item> ) : ( <Menu.Item key="connect" icon={<UserOutlined />}>Se connecter</Menu.Item> )}
                                    <Menu.Item key="load" icon={<SaveOutlined />}>Charger</Menu.Item>
                                    <Menu.Item key="save" icon={<SaveOutlined />}>Enregistrer</Menu.Item>
                                    <Menu.Item key="export" icon={<VerticalAlignBottomOutlined />}>Exporter</Menu.Item>
                                    <Menu.Item key="cleanState" icon={<DeleteOutlined />}>Supprimer</Menu.Item>
                                </Menu>
                            </div>
                            <div className='menu-small-screen'>
                                <Menu mode="horizontal" onClick={this.handleClick}>
                                    <Menu.SubMenu key="SubMenu" icon={<UnorderedListOutlined />} >
                                        {!!token ? ( <Menu.Item key="logged" disabled icon={<UserOutlined />}>Compte Dropbox Connecté !</Menu.Item> ) : ( <Menu.Item key="connect" icon={<UserOutlined />}>Se connecter</Menu.Item> )}
                                        <Menu.Item key="load" icon={<SaveOutlined />}>Charger</Menu.Item>
                                        <Menu.Item key="save" icon={<SaveOutlined />}>Enregistrer</Menu.Item>
                                        <Menu.Item key="export" icon={<VerticalAlignBottomOutlined />}>Exporter</Menu.Item>
                                        <Menu.Item key="cleanState" icon={<DeleteOutlined />}>Supprimer</Menu.Item>
                                    </Menu.SubMenu>
                                </Menu>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
        );
    }

    private hideModal = () => {
        this.setState( {
            visibleModalApiKey: false,
        } );
    };

    private openModal = () => {
        let token = Cookies.get( "access_token" );
        if ( !token ) {
            this.setState( {
                visibleModalApiKey: true
            } )
        } else {
            notification.info( {
                message: 'Already connected !',
                description: 'The application is already connected to your dropbox ! You can import your data.'
            } )
        }
    }

    private handleClick = ( e: any ) => {
        let path = window.location.pathname;

        switch ( e.key ) {
            case 'connect':
                this.openModal();
                return;
            case 'export':
                saveDataAsJSONFile( webStorage.getMainData(), 'e-toolbox-export' );
                return;
            case 'save':
                this.props.saveFile();
                return;
            case 'cleanState':
                this.props.onResetDataStored();
                return;
            case 'load':
                this.props.loadFile()
                return;
            default:
                return;
        }
    };

}

export default App_ToolBar;
