import { BudgetEntry } from "../../models"

export const initialState: BudgetState = {
    budget: [],
    expensesAmount: 0,
    incomesAmount: 0,
    taxesAmount: 0
};

export interface BudgetState {
    budget: BudgetEntry[];
    expensesAmount?: number;
    incomesAmount?: number;
    taxesAmount?: number;
}

