import React from 'react';
import { Row, Col, Button, Empty, notification, Switch, Select, Table, Input, Radio, InputNumber } from 'antd';
import { PlusOutlined, MenuOutlined, AppstoreOutlined } from '@ant-design/icons';
import moment from 'moment';
import classNames from 'classnames';


import * as Papa from 'papaparse'
import * as XLSX from 'xlsx'

import { webStorage } from 'src/utils/web_storage';
import { saveDataAsCSVFile, saveDataAsJSONFile } from 'src/utils/helpers';

//data
import { DEFAULT_CONFIG_CHAUMES, DEFAULT_CONFIG_LES_STATIONS, DEFAULT_CONFIG_OSMOZIS } from 'src/data/xls_manager';

const EXPORT_KEYS = [
    'buyer_firstname',
    'buyer_lastname',
    'order_date',
    'event_date_start',
    'event_date_end',
    'order_amount',
    'insurance_amount',
    'order_reference',
    'preferred_locale',
    'buyer_email',
    'buyer_phone',
    'event_type',
    'additional_data',
    'event_name',
    'item_price',
    'item_insurance_amount',
    'currency',
    'item_number',
    'insured_persons'
]

const EXTRA_COLUMNS = [
    'added_column_1',
    'added_column_2',
    'added_column_3',
    'added_column_4',
    'added_column_5',
    'added_column_6',
    'added_column_7',
    'added_column_8',
    'added_column_9',
    'added_column_10'
]

interface Props {

}

interface State {
    keys: string[];
    items: any[];
    selectedProvider: string;
    selectedConfig: ItemsConfig;
    configs: {
        [provider: string]: ItemsConfig;
    }
    fileLoaded: boolean;
}

interface ItemsConfig {
    [key: string]: {
        exportKey: string;
        hide: boolean;
        order: number;
        transformation: {
            activated: boolean;
            mode?: 'hard_value' | 'calculation';
            exportValue?: any;
            exportBaseColumn?: string;
            exportBaseColumnMultiplier?: number;
            exportBaseColumnDivider?: number;
        }
    }
}


class XLSManager extends React.Component<Props, State> {

    private fileInput: HTMLFormElement

    constructor( props: Props ) {
        super( props )

        this.state = {
            keys: [],
            items: [],
            selectedProvider: null,
            selectedConfig: {},
            configs: {
                _lesstations: DEFAULT_CONFIG_LES_STATIONS as any,
                _osmozis: DEFAULT_CONFIG_OSMOZIS,
                _sydevi: {},
                _grandeschaumes: DEFAULT_CONFIG_CHAUMES,
                _53douze: {}
            },
            fileLoaded: false
        }
    }

    componentDidUpdate( prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any ): void {

        if ( prevState.selectedConfig !== this.state.selectedConfig ) {

            let updatedConfigs = JSON.parse( JSON.stringify( this.state.configs ) )

            updatedConfigs[this.state.selectedProvider] = this.state.selectedConfig

            this.setState( {
                configs: updatedConfigs
            } )

        }

    }

    render() {

        let { keys, items, selectedConfig } = this.state

        return (

            <div>

                <div className="text-right">
                    <Button className="mgb-5" type="primary" disabled={!this.state.keys || !this.state.keys.length} onClick={() => {
                        saveDataAsJSONFile( this.state.configs, 'parser_configuration' )
                    }}>Export configuration</Button>
                </div>

                <div className="text-center light-bg pad-20">

                    <div className="mgb-20">
                        <Radio.Group
                            optionType="button"
                            options={[
                                {
                                    value: '_lesstations',
                                    label: 'Les stations'
                                },
                                {
                                    value: '_osmozis',
                                    label: 'Osmozis'
                                },
                                {
                                    value: '_sydevi',
                                    label: 'Sydevi'
                                },
                                {
                                    value: '_grandeschaumes',
                                    label: 'Grandes chaumes'
                                },
                                {
                                    value: '_53douze',
                                    label: '53douze'
                                }
                            ]}
                            onChange={e => {
                                this.setState( {
                                    selectedProvider: e.target.value,
                                    selectedConfig: this.state.configs[e.target.value]
                                } )
                            }}
                            disabled={this.state.fileLoaded}
                        />
                    </div>


                    <form ref={form => this.fileInput = form}>
                        <input disabled={!this.state.selectedProvider} type="file" multiple={false} onChange={this.parseXLSXFile} />
                    </form>

                    {
                        this.state.fileLoaded ? (
                            <Button className="mgt-5" type="dashed" onClick={() => {
                                this.fileInput.reset()
                                this.setState( { fileLoaded: false, keys: [], items: [] } )
                            }}>
                                Effacer
                            </Button>
                        ) : null
                    }
                </div>

                <div className="mgt-20">

                    <Table
                        pagination={false}
                        rowKey={item => item.name}
                        dataSource={keys.map( name => { return { name } } )}
                        rowClassName={item => {
                            const keyConfig = selectedConfig[item.name]
                            if ( keyConfig && keyConfig.hide === true ) {
                                return 'hidden-row'
                            }
                        }}
                        columns={[
                            {
                                title: 'Clé',
                                dataIndex: 'name',
                                width: '30%'
                            },
                            {
                                title: "Nom colonne d'export",
                                dataIndex: 'name',
                                render: ( item, record, index ) => {

                                    const keyConfig = selectedConfig[item]

                                    return (
                                        <div>
                                            <Select
                                                value={keyConfig?.exportKey}
                                                options={EXPORT_KEYS.map( option => { return { value: option, label: option } } )}
                                                onChange={value => {
                                                    this.updateValueOfKey( item, 'exportKey', value )
                                                }}
                                                style={{ display: 'block' }}
                                                allowClear
                                                showSearch
                                                disabled={!!keyConfig?.hide}
                                            />
                                        </div>
                                    )
                                },
                                width: '30%',
                            },
                            {
                                title: "Transformation",
                                dataIndex: 'name',
                                render: ( item, record, index ) => {

                                    const keyConfig = selectedConfig[item]

                                    return (
                                        <div>

                                            <Switch
                                                checked={keyConfig?.transformation?.activated}
                                                onChange={selected => {
                                                    this.updateValueOfTransformation( item, {
                                                        activated: selected
                                                    } )
                                                }}
                                                unCheckedChildren="Non"
                                                checkedChildren="Oui"
                                            />


                                            {
                                                keyConfig?.transformation?.activated ? (
                                                    <div className="mgt-10">

                                                        <Radio.Group
                                                            optionType="button"
                                                            options={[
                                                                {
                                                                    value: 'hard_value',
                                                                    label: 'Texte fixe'
                                                                },
                                                                {
                                                                    value: 'calculation',
                                                                    label: 'Calcul'
                                                                }
                                                            ]}
                                                            onChange={e => {
                                                                this.updateValueOfTransformation( item, {
                                                                    mode: e.target.value
                                                                } )
                                                            }}
                                                        />

                                                        {
                                                            keyConfig.transformation.mode === 'hard_value' ? (
                                                                <div className="mgt-10">
                                                                    <Input
                                                                        value={keyConfig.transformation.exportValue}
                                                                        onChange={e => {
                                                                            this.updateValueOfTransformation( item, {
                                                                                exportValue: e.target.value
                                                                            } )
                                                                        }}
                                                                        style={{ display: 'block' }}
                                                                    />
                                                                </div>
                                                            ) : null
                                                        }

                                                        {
                                                            keyConfig.transformation.mode === 'calculation' ? (
                                                                <div className="mgt-10">
                                                                    <label>Colonne de base du calcul</label>
                                                                    <Select
                                                                        value={keyConfig.transformation.exportBaseColumn}
                                                                        options={keys.map( option => { return { value: option, label: option } } )}
                                                                        onChange={selected => {
                                                                            this.updateValueOfTransformation( item, {
                                                                                exportBaseColumn: selected
                                                                            } )
                                                                        }}
                                                                        style={{ display: 'block' }}
                                                                        allowClear
                                                                        showSearch
                                                                    />
                                                                    <div className="mgt-5">
                                                                        <Row gutter={[10, 5]}>
                                                                            <Col flex={1}>
                                                                                <label>Multiplicateur</label>
                                                                                <InputNumber
                                                                                    value={keyConfig.transformation.exportBaseColumnMultiplier}
                                                                                    precision={2}
                                                                                    addonBefore="x"
                                                                                    onChange={num => {
                                                                                        this.updateValueOfTransformation( item, {
                                                                                            exportBaseColumnMultiplier: num
                                                                                        } )
                                                                                    }}
                                                                                    style={{ display: 'block' }}
                                                                                />
                                                                            </Col>
                                                                            <Col flex={1}>
                                                                                <label>Diviseur</label>
                                                                                <InputNumber
                                                                                    value={keyConfig.transformation.exportBaseColumnDivider}
                                                                                    precision={2}
                                                                                    addonBefore="÷"
                                                                                    onChange={num => {
                                                                                        this.updateValueOfTransformation( item, {
                                                                                            exportBaseColumnDivider: num
                                                                                        } )
                                                                                    }}
                                                                                    style={{ display: 'block' }}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </div>
                                                            ) : null
                                                        }

                                                    </div>
                                                ) : null
                                            }



                                        </div>
                                    )
                                },
                            },
                            /*{
                                title: 'Supprimer la colonne',
                                dataIndex: 'name',
                                width: '15%',
                                render: ( item, record, index ) => {

                                    const keyConfig = config[item]

                                    return (
                                        <Switch
                                            checked={keyConfig.hide}
                                            onChange={selected => {
                                                this.updateValueOfKey( item, 'hide', selected )
                                            }}
                                            unCheckedChildren="Non"
                                            checkedChildren="Oui"
                                            className="danger-switch"
                                        />
                                    )
                                },
                            }*/,
                        ]}
                        size="small"
                        bordered

                    />

                </div>

                <div className="mgt-20">
                    <div className="text-right">
                        <Button className="mgb-5" type="primary" disabled={!this.state.items || !this.state.items.length} onClick={() => {
                            saveDataAsCSVFile( this.getExportFile(), 'export' )
                        }}>Télécharger</Button>
                    </div>
                    <pre className="pad-20" style={{ backgroundColor: "#eee" }}>
                        {this.getExportFile()}
                    </pre>
                </div>

            </div>

        )
    }

    private updateValueOfKey = ( configKey: string, key: 'exportKey' | 'hide' | 'order', value: any ) => {

        const { selectedConfig } = this.state

        let updatedConfig: ItemsConfig = JSON.parse( JSON.stringify( selectedConfig ) )

        if ( !updatedConfig[configKey] ) {
            updatedConfig[configKey] = {
                exportKey: '',
                hide: false,
                transformation: {
                    activated: false
                },
                order: -1
            }
        }

        updatedConfig[configKey][key as any] = value

        if ( key === 'exportKey' ) {
            updatedConfig[configKey].order = EXPORT_KEYS.indexOf( value )
        }

        this.setState( {
            selectedConfig: updatedConfig
        } )
    }

    private updateValueOfTransformation = ( configKey: string, value: any ) => {

        const { selectedConfig } = this.state

        let updatedConfig: ItemsConfig = JSON.parse( JSON.stringify( selectedConfig ) )

        if ( !updatedConfig[configKey].transformation ) {
            updatedConfig[configKey].transformation = { activated: false }
        }

        updatedConfig[configKey].transformation = Object.assign( updatedConfig[configKey].transformation, value )

        this.setState( {
            selectedConfig: updatedConfig
        } )
    }

    private getExportFile = () => {

        const { items, selectedConfig } = this.state

        let rows: string[] = []

        const keysConfigArray = Object.keys( selectedConfig ).map( key => {
            return {
                originalKey: key,
                order: selectedConfig[key].order,
                hide: selectedConfig[key].hide,
                transformation: selectedConfig[key].transformation,
                exportKey: selectedConfig[key].exportKey
            }
        } ).filter( item => !item.hide && !!item.exportKey )

        const keys = keysConfigArray.map( item => item.exportKey )

        rows.push( keys.join( ',' ) )

        rows.push( ...items.map( item => {

            let data = []

            keysConfigArray.forEach( keyConfig => {

                if ( keyConfig.transformation && keyConfig.transformation.activated ) {

                    if ( keyConfig.transformation.mode === 'hard_value' ) {

                        data.push( keyConfig.transformation.exportValue || '' )

                    } else if ( keyConfig.transformation.mode === 'calculation' ) {

                        try {

                            let baseValue = item[keyConfig.transformation.exportBaseColumn]

                            if ( baseValue && baseValue.indexOf( ',' ) !== -1 ) {
                                if ( baseValue.indexOf( '.' ) !== -1 && baseValue.indexOf( ',' ) < baseValue.indexOf( '.' ) ) {
                                    baseValue = baseValue.replace( ',', '' )
                                }
                            }

                            const parsedBaseValue = parseFloat( baseValue ) * 100

                            const multiplierValue = keyConfig.transformation.exportBaseColumnMultiplier || 1

                            const dividerValue = keyConfig.transformation.exportBaseColumnDivider || 1

                            const result = ( parsedBaseValue * multiplierValue / dividerValue )

                            data.push( Math.round( result ) / 100 )

                        } catch ( e ) {
                            alert( JSON.stringify( e ) )
                        }

                    } else {
                        data.push( item[keyConfig.originalKey] )
                    }

                } else {
                    data.push( item[keyConfig.originalKey] )
                }

            } )

            return data.join( ',' )

        } ) )

        return rows.join( '\n' )

    }


    private parseXLSXFile = ( event: React.ChangeEvent<HTMLInputElement> ) => {

        let reader = new FileReader()
        let file = event.target.files[0]

        if ( !file ) {
            return
        }

        reader.onload = ( e: any ) => {

            try {

                let data = new Uint8Array( reader.result as any )
                let workbook = XLSX.read( data, { type: 'array' } )

                const sheetData: any[] = XLSX.utils.sheet_to_json( workbook.Sheets[workbook.SheetNames[0]], {
                    header: undefined,
                    raw: false
                } )

                if ( sheetData && sheetData.length ) {

                    const keys = Object.keys( sheetData[0] ).concat( EXTRA_COLUMNS )

                    this.setState( {
                        keys,
                        items: sheetData,
                        selectedConfig: Object.assign( keys.reduce( ( acc, key, idx ) => {
                            acc[key] = {
                                order: idx + 1,
                                hide: false,
                                exportKey: '',
                                transformation: {
                                    activated: false
                                }
                            }
                            return acc
                        }, {} as ItemsConfig ), this.state.selectedConfig ),
                        fileLoaded: true
                    }, () => {
                        notification.info( {
                            message: 'Fichier importé',
                            placement: 'bottomRight',
                            duration: 15
                        } )
                    } )

                } else {
                    throw 'No data found in parsed file'
                }

            } catch ( error ) {

                console.log( 'File processing error: ', error )

                notification.error( {
                    message: 'Erreur',
                    placement: 'bottomRight',
                    duration: 15
                } )

            }

        }

        reader.onerror = ( e: any ) => {
            console.log( e )
        }

        reader.readAsArrayBuffer( file )

    }

    /*
    private importXLSFile = ( event: React.ChangeEvent<HTMLInputElement> ) => {

        let reader = new FileReader()
        let file = event.target.files[0]

        reader.onload = ( e: any ) => {
            try {
                const result = Papa.parse( reader.result, {
                    header: true,
                    encoding: "utf-8"
                } )

                if ( result.data && result.data.length ) {
                    this.setState( {
                        keys: Object.keys( result.data[0] ).,
                        items: result.data
                    }, () => {
                        notification.info( {
                            message: 'Fichier importé',
                            placement: 'bottomRight',
                            duration: 15
                        } )
                        console.log( this.state )
                    } )
                }
            }
            catch ( error ) {
                console.log( 'JSON content parsing error: ', error )
                notification.error( {
                    message: 'Erreur',
                    placement: 'bottomRight',
                    duration: 15
                } )
            }
        }

        reader.onerror = ( e: any ) => {
            console.log( 'Error: ', e )
            notification.error( {
                message: 'Erreur',
                placement: 'bottomRight',
                duration: 15
            } )
        }

        reader.readAsText( file )

    }
    */

}

export default XLSManager;