import React from 'react';
import { Tabs, Empty, Button } from 'antd';
import { MapStateToProps, connect } from 'react-redux'
import { injectIntl } from 'react-intl'

/* Components */
import ProductCostTable from '../components/product_cost/product_costs_table';
import ProductCostAddModal from '../components/product_cost/product_cost_add_modal';
/* Actions */
import { addProduct, addProductItem, editProductItem, deleteProduct, setProductPotentialPrice, setProductTotalItems, deleteProductItem, editProductName } from "../redux/product_cost/product_cost_actions";

/* Models */
import { ProductDetail, ReduxProps, Product, PayloadProductDetail, PayloadNumber, PayloadString } from '../models';
import { IRootState } from '../redux/store'

const { TabPane } = Tabs;

export interface Props extends ReduxProps {
    products: Product[],
    editProductName: ( payload: PayloadString ) => void;
    addProduct: ( p: any ) => void,
    addProductItem: ( payload: PayloadProductDetail ) => void,
    editProductItem: ( payload: PayloadProductDetail ) => void,
    deleteProductItem: ( payload: PayloadProductDetail ) => void,
    getProduct: () => void,
    deleteProduct: ( payload: { key: string } ) => void,
    setProductPotentialPrice: ( payload: PayloadNumber ) => void;
    setProductTotalItems: ( payload: PayloadNumber ) => void;
}

export interface State {
    visible: boolean;
}

class ProductCost extends React.Component<Props, State> {
    public static select: MapStateToProps<any, any, IRootState> = ( state: IRootState ) => {
        return {
            products: state.productCost.products
        } as Props
    }

    constructor( props: Props ) {
        super( props )
        this.state = {
            visible: false,
        }
    }

    render() {

        const { products } = this.props;
        const { visible } = this.state;

        if ( products.length > 0 ) {
            return (
                <div>
                    <ProductCostAddModal visible={visible} addProduct={this.handleModalCreateNewProduct} closeModal={this.hideModal}></ProductCostAddModal>
                    <Tabs
                        type="editable-card" onEdit={this.onEdit}
                    >
                        {products.map( ( product: Product ) =>

                            <TabPane tab={product.name} key={product.id} >

                                <ProductCostTable
                                    key={product.id}
                                    dataKey={product.id}
                                    productDetails={product}
                                    editProduct={this.handleEditProduct}
                                    addProductItem={this.handleAdd}
                                    editProductItem={this.handleEdit}
                                    deleteProductItem={this.handleDeleteProductItem}
                                    setProductPotentialPrice={this.handleEditPotentialPrice}
                                    setProductTotalItems={this.handleEditTotalItems}
                                />
                            </TabPane>
                        )}
                    </Tabs>

                </div>
            );

        } else {
            return ( <div className="center-zone">

                <ProductCostAddModal visible={visible} addProduct={this.handleModalCreateNewProduct} closeModal={this.hideModal}></ProductCostAddModal>
                <Empty
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                        height: 60,
                    }}
                    description={
                        <span>
                            Aucun produit n'a été encore configuré.
                            Veuillez <b>importer un fichier de données</b> ou <b>créer un nouveau produit !</b>
                        </span>
                    }
                >
                    <Button type="primary" onClick={this.add}>Créer un nouveau produit</Button>
                </Empty>,

            </div> );
        }
    }

    private handleEditProduct = ( values: PayloadString ) => {
        this.props.editProductName( values );
    }

    private handleModalCreateNewProduct = ( values: any ) => {
        this.props.addProduct( values );
        this.setState( {
            visible: false,
        } );
    };

    private hideModal = () => {
        this.setState( {
            visible: false,
        } );
    };

    //onEdit method can call depending of "action" parameter : add() or remove() functions.
    private onEdit = ( targetKey: any | string, action: any | string ) => {
        this[action]( targetKey );
    };

    private add = () => {
        this.setState( {
            visible: true,
        } );
    };

    private remove = ( dataKey: string ) => {
        this.props.deleteProduct( { key: dataKey } );
    }

    private handleEditPotentialPrice = ( key: string, value: number ) => {
        this.props.setProductPotentialPrice( { key, value } );
    }

    private handleEditTotalItems = ( key: string, value: number ) => {
        this.props.setProductTotalItems( { key, value } );
    }

    private handleAdd = ( dataKey: string, product: ProductDetail ) => {
        this.props.addProductItem( { key: dataKey, value: product } );
    }

    private handleEdit = ( dataKey: string, product: ProductDetail ) => {
        this.props.editProductItem( { key: dataKey, value: product } );
    }

    private handleDeleteProductItem = ( dataKey: string, product: ProductDetail ) => {
        this.props.deleteProductItem( { key: dataKey, value: product } );
    }

}

export default injectIntl( connect( ProductCost.select, { editProductName, addProduct, addProductItem, editProductItem, deleteProduct, setProductPotentialPrice, setProductTotalItems, deleteProductItem } )( ProductCost as any ) );
