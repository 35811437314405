import React from 'react';
import { MapStateToProps, connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { PageHeader, Statistic, Row, Col, Tabs } from 'antd';
import {
    MehOutlined, SmileOutlined, FrownOutlined
} from '@ant-design/icons';

/*Utils*/
import { GREEN_COLOR, RED_COLOR, TARGET_COLOR } from '../utils/helpers';

/*Component*/
import EntryLayout from '../components/budget/entry_layout'

/*Models*/
import { ReduxProps, BudgetTypeEnum, BudgetResult, BudgetEntry } from '../models'
import { IRootState } from '../redux/store'

/* Actions */
import { updateExpensesEntry, updateIncomesEntry } from "../redux/budget/budget_actions";


export interface Props extends ReduxProps {
    budget: BudgetEntry[];
    expensesAmount: number;
    incomesAmount: number;
}

export interface State {
    expensesAmount: number,
    incomesAmount: number
}

class Budget extends React.Component<Props, State> {
    public static select: MapStateToProps<any, any, IRootState> = ( state: IRootState ) => {
        return {
            budget: state.budget.budget,
            expensesAmount: state.budget.expensesAmount,
            incomesAmount: state.budget.incomesAmount
        } as Props
    }


    constructor( props: Props ) {
        super( props )
        this.state = {
            expensesAmount: props.expensesAmount,
            incomesAmount: props.incomesAmount
        }
    }

    private onSaveEntries = ( result: BudgetResult ) => {

        if ( result.entryType === BudgetTypeEnum.Expense ) {
            this.setState( {
                expensesAmount: result.sumValue
            } )

            this.props.dispatch( updateExpensesEntry( {
                budget: result.entries,
                expensesAmount: this.state.expensesAmount,
                incomesAmount: this.state.incomesAmount
            } ) )
        } else if ( result.entryType === BudgetTypeEnum.Income ) {
            this.setState( {
                incomesAmount: result.sumValue
            } )

            this.props.dispatch( updateIncomesEntry( {
                budget: result.entries,
                expensesAmount: this.state.expensesAmount,
                incomesAmount: this.state.incomesAmount
            } ) )
        }


    }

    private totalBudgetAmount = (): number => {
        const { expensesAmount, incomesAmount } = this.state

        return incomesAmount - expensesAmount
    }

    render() {

        const { expensesAmount, incomesAmount } = this.state
        const { budget } = this.props
        return (
            <>
                <PageHeader
                    title="Budget"
                    extra={[
                        <Statistic
                            title="Budget"
                            value={this.totalBudgetAmount()}
                            precision={2}
                            valueStyle={{ color: expensesAmount === incomesAmount ? TARGET_COLOR : expensesAmount < incomesAmount ? GREEN_COLOR : RED_COLOR }}
                            prefix={expensesAmount === incomesAmount ? <MehOutlined /> : expensesAmount < incomesAmount ? <SmileOutlined /> : <FrownOutlined />}
                            suffix="€"
                        />
                    ]}>

                </PageHeader>

                <Row gutter={[8, 8]}>
                    <Col xs={24} sm={24} md={24} lg={12}><EntryLayout entryType={BudgetTypeEnum.Income} entries={budget} sumValue={incomesAmount} saveEntries={this.onSaveEntries}></EntryLayout></Col>
                    <Col xs={24} sm={24} md={24} lg={12}><EntryLayout entryType={BudgetTypeEnum.Expense} entries={budget} sumValue={expensesAmount} saveEntries={this.onSaveEntries}></EntryLayout></Col>
                </Row>

            </>
        );
    }

}

export default injectIntl( connect( Budget.select )( Budget as any ) );;
