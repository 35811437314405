import React from 'react';
import { Tabs, Divider, Row, Col, Card, Statistic, Space } from 'antd'
import { MapStateToProps, connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import {
    ArrowUpOutlined,
    ArrowDownOutlined
} from '@ant-design/icons';

/*Models*/
import { ReduxProps, InvestmentConfiguration, InvestmentIteration, LoanConfiguration, LoanIteration } from '../models';
import { IRootState } from '../redux/store'

/*Actions*/
import { setIterationsInformation, resetIterationsInformation, setLoanIterationsInformation, resetLoanIterationsInformation } from '../redux/investment/investment_actions'

/*Components*/
import InvestmentForm from '../components/investment/investment_form'
import InvestmentTable from '../components/investment/investment_table'
import LoanForm from '../components/investment/loan_form'
import LoanTable from '../components/investment/loan_table'

export interface Props extends ReduxProps {
    investment: InvestmentConfiguration,
    investmentIterations: InvestmentIteration[],
    loan: LoanConfiguration,
    loanIterations: LoanIteration[]
}

export interface State {
    visible: boolean
}

class Investment extends React.Component<Props, State> {
    public static select: MapStateToProps<any, any, IRootState> = ( state: IRootState ) => {
        return {
            investment: state.investment.investment,
            investmentIterations: state.investment.investmentTable,
            loan: state.investment.loan,
            loanIterations: state.investment.loanTable
        } as Props
    }

    render() {
        const { loan, investment, investmentIterations, loanIterations } = this.props
        const totalProfit = this.getTotalNetProfitFromInvestmentTable( investmentIterations )
        const totalInvestedCapital = this.getTotalInvestedCapitalFromInvestmentTable()

        return (
            <Tabs centered >
                <Tabs.TabPane tab="Intérêts composés" key="interest">
                    <Divider>Configuration</Divider>
                    <InvestmentForm investment={investment} setInvestment={this.handleSetInvestment} resetInvestment={this.handleResetInvestment}></InvestmentForm>
                    <Divider>Génération</Divider>
                    <InvestmentTable iterations={investmentIterations}></InvestmentTable>

                    <Row justify="center" gutter={[30, 10]} className="mgt-20">
                        <Col>
                            <Card>
                                <Statistic
                                    title={"CAPITAL INVESTI"}
                                    value={totalInvestedCapital}
                                    precision={2}
                                    suffix="€"
                                />
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Statistic
                                    title={"BÉNÉFICE NET"}
                                    value={totalProfit}
                                    precision={2}
                                    valueStyle={{ color: totalProfit > 0 ? '#3f8600' : '#cf1322' }}
                                    prefix={totalProfit > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                                    suffix="€"
                                />
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Statistic
                                    title={"CAPITAL FINAL"}
                                    value={totalInvestedCapital + totalProfit}
                                    precision={2}
                                    suffix="€"
                                />
                            </Card>
                        </Col>
                    </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Amortissement" key="amortization">
                    <Divider>Configuration</Divider>
                    <LoanForm loan={loan} setLoan={this.handleSetLoan} resetLoan={this.handleResetLoan}></LoanForm>
                    <Divider>Génération</Divider>
                    <LoanTable iterations={loanIterations} configuration={loan}></LoanTable>
                </Tabs.TabPane>
            </Tabs>
        );
    }

    private handleSetInvestment = ( configuration: InvestmentConfiguration, iterations: InvestmentIteration[] ) => {
        this.props.dispatch( setIterationsInformation( {
            investment: configuration,
            investmentIterations: iterations
        } ) )
    }

    private handleResetInvestment = () => {
        this.props.dispatch( resetIterationsInformation() )
    }

    private handleResetLoan = () => {
        this.props.dispatch( resetLoanIterationsInformation() )
    }

    private handleSetLoan = ( configuration: LoanConfiguration, iterations: LoanIteration[] ) => {
        this.props.dispatch( setLoanIterationsInformation( {
            loan: configuration,
            loanIterations: iterations
        } ) )
    }

    private getTotalNetProfitFromInvestmentTable = ( invesmentTable: InvestmentIteration[] ) => {

        if ( !invesmentTable.length ) {
            return 0
        }

        return invesmentTable.reduce( ( acc, value ) => { return acc += value.netProfit }, 0 )
    }

    private getTotalInvestedCapitalFromInvestmentTable = () => {

        const { investment, investmentIterations } = this.props

        if ( !investment || !investmentIterations.length ) {
            return 0
        }

        return ( investmentIterations.length * ( investment.addedCapitalByIteration || 0 ) ) + ( investment.startingCapital || 0 )
    }

}

export default injectIntl( connect( Investment.select )( Investment as any ) );;
