import React from 'react';
import Num from 'big.js'
import classNames from 'classnames'

//components
import { Row, Col, Spin, Tag, Popconfirm, InputNumber } from 'antd';
import {
    FormOutlined,
    CloseSquareOutlined,
    CheckCircleOutlined
} from '@ant-design/icons';
import { FormattedNumber } from 'react-intl'

//utils
import { GREEN_COLOR, RED_COLOR } from 'src/utils/helpers';

/*Models*/
import { IBKROrder, IBKROrderRequest } from '../../models'

interface Props {
    loading: boolean;
    parentOrder: IBKROrder;
    childrenOrders?: IBKROrder[];
    editOrder: ( orderId: number, orderConfig: IBKROrderRequest ) => void;
    cancelOrder: ( orderId: number ) => void;
}

interface State {
    editedOrder: IBKROrder;
}

class IBKROrderItem extends React.Component<Props, State> {

    constructor( props: Props ) {
        super( props );
        this.state = {
            editedOrder: null
        }
    }

    render() {

        const { loading, parentOrder, childrenOrders } = this.props

        const stpPrice = parentOrder.auxPrice
        const lmtPrice = parentOrder.price

        return (
            <div className="bordered" style={{ padding: '20px 40px' }}>

                <Row justify="space-between" align="middle">

                    <Col>
                        <div>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Tag className="mgr-10 upper text-center" style={{ width: 60 }} color={parentOrder.side === 'BUY' ? 'cyan' : 'orange'}>{parentOrder.side}</Tag>
                                <span className="mgr-10 bold font-110">{parentOrder.sizeAndFills}</span>
                                <span className="mgr-10 font-110">{parentOrder.ticker}</span>
                                <span className="mgr-10 upper font-90"><em>{parentOrder.orderType}</em></span>
                                <span className="bold font-110">
                                    {stpPrice || lmtPrice ? <FormattedNumber style="currency" value={Num( stpPrice ? stpPrice : lmtPrice ).toNumber()} currency={parentOrder.cashCcy} currencyDisplay="narrowSymbol" /> : null}
                                </span>
                                {stpPrice && lmtPrice ? <span className="mgl-5 mgr-5">→</span> : null}
                                {stpPrice && lmtPrice ? <span><FormattedNumber style="currency" value={Num( lmtPrice ).toNumber()} currency={parentOrder.cashCcy} currencyDisplay="narrowSymbol" /></span> : null}
                                {this.getStatusTag( parentOrder )}
                                {this.getCancelButton( parentOrder )}
                                {this.getEditButton( parentOrder )}
                                {this.getEditForm( parentOrder )}
                            </div>

                            {
                                childrenOrders && childrenOrders.length ? (
                                    <div style={{ borderLeft: '1px dashed #ccc', paddingLeft: 8, paddingTop: 15, paddingBottom: 2, marginLeft: 15 }}>

                                        {
                                            childrenOrders.map( ( order, idx ) => {
                                                return (
                                                    <div key={idx} style={{ display: 'flex', alignItems: 'center' }} className={classNames( {
                                                        'mgb-10': idx < childrenOrders.length - 1
                                                    } )}>
                                                        <Tag className="mgr-10 upper text-center" color={order.side === 'BUY' ? 'cyan' : 'orange'}>{order.side}</Tag>
                                                        <span className="mgr-10 bold font-110">{order.sizeAndFills}</span>
                                                        <span className="mgr-10 font-110">{order.ticker}</span>
                                                        <span className="mgr-10 upper font-90"><em>{order.orderType}</em></span>
                                                        <span className="bold font-110" style={{ color: order.orderType === 'Stop' ? RED_COLOR : GREEN_COLOR }}>
                                                            <FormattedNumber style="currency" value={Num( order.price || order.auxPrice ).toNumber()} currency={order.cashCcy} currencyDisplay="narrowSymbol" />
                                                        </span>
                                                        {this.getStatusTag( order )}
                                                        {this.getCancelButton( order )}
                                                        {this.getEditButton( order )}
                                                        {this.getEditForm( order )}
                                                    </div>
                                                )
                                            } )
                                        }

                                    </div>
                                ) : null
                            }

                        </div>
                    </Col>

                </Row>

            </div>
        )


    };

    private getEditButton = ( order: IBKROrder ) => {

        if ( this.state.editedOrder ) {
            return null
        }

        if ( order && order.status && ( order.status === 'PreSubmitted' || order.status === 'Submitted' ) && order.remainingQuantity && !order.filledQuantity ) {
            return (
                <FormOutlined style={{ fontSize: 16 }} className="mgl-10 clickable clickable-colored" onClick={() => { this.setEditedOrder( order ) }} />
            )
        }

        return null

    }

    private getCancelButton = ( order: IBKROrder ) => {

        if ( this.state.editedOrder ) {
            return null
        }

        if ( order && order.status && ( order.status === 'PreSubmitted' || order.status === 'Submitted' || order.status === 'PendingSubmit' ) ) {
            return (
                <Popconfirm className="mgl-5" title="Cancel order?" onConfirm={() => { this.props.cancelOrder( order.orderId ) }} okText="Yes" cancelText="No">
                    <CloseSquareOutlined style={{ fontSize: 16 }} className="clickable clickable-orange" />
                </Popconfirm>
            )
        }

        return null

    }

    private getStatusTag = ( order: IBKROrder ) => {

        if ( order && order.status ) {

            switch ( order.status ) {

                case 'PendingSubmit': {
                    return <Tag className="mgl-10" color="purple">{order.status}</Tag>
                }

                case 'PendingCancel': {
                    return <Tag className="mgl-10" color="gold">{order.status}</Tag>
                }

                case 'PreSubmitted': {
                    return <Tag className="mgl-10" color="geekblue">{order.status}</Tag>
                }

                case 'Submitted': {
                    return <Tag className="mgl-10" color="blue">{order.status}</Tag>
                }

                case 'Cancelled': {
                    return <Tag className="mgl-10" color="orange">{order.status}</Tag>
                }

                case 'Filled': {
                    return <Tag className="mgl-10" color="green">{order.status}</Tag>
                }

                case 'Inactive': {
                    return <Tag className="mgl-10" color="magenta">{order.status}</Tag>
                }

                default: {
                    return null
                }

            }

        }

        return null

    }

    private setEditedOrder = ( order: IBKROrder ) => {
        this.setState( {
            editedOrder: order
        } )
    }

    private getEditForm = ( order: IBKROrder ) => {

        const { editedOrder } = this.state

        if ( editedOrder && order && order.orderId === editedOrder.orderId ) {

            const labelStyle: React.CSSProperties = {
                position: 'absolute',
                top: -14,
                left: 18,
                textAlign: 'center',
                fontSize: 9,
                textTransform: 'uppercase',
                color: '#666'
            }

            return (
                <div style={{ display: 'flex', alignItems: 'center' }}>

                    <CloseSquareOutlined style={{ fontSize: 16 }} className="mgl-5 clickable clickable-orange" onClick={() => { this.setState( { editedOrder: null } ) }} />

                    <div className="rel">
                        <div style={labelStyle}>Qty</div>
                        <InputNumber
                            size="small"
                            className="mgl-10"
                            value={editedOrder.remainingQuantity}
                            onChange={( value: any ) => { this.updateEditedOrder( 'remainingQuantity', value ) }}
                        />
                    </div>

                    {
                        editedOrder.price ? (
                            <div className="rel">
                                <div style={labelStyle}>LMT Price</div>
                                <InputNumber
                                    size="small"
                                    className="mgl-10"
                                    value={editedOrder.price}
                                    onChange={( value: any ) => { this.updateEditedOrder( 'price', value ) }}
                                    step={0.01}
                                    formatter={value => `$ ${ value }`}
                                    parser={value => ( value as any ).replace( /\$\s/g, '' )}
                                    style={{ minWidth: 105 }}
                                />
                            </div>
                        ) : null
                    }

                    {
                        editedOrder.auxPrice ? (
                            <div className="rel">
                                <div style={labelStyle}>STP Price</div>
                                <InputNumber
                                    size="small"
                                    className="mgl-10"
                                    value={editedOrder.auxPrice as number}
                                    onChange={( value: any ) => { this.updateEditedOrder( 'auxPrice', value ) }}
                                    step={0.01}
                                    formatter={value => `$ ${ value }`}
                                    parser={value => ( value as any ).replace( /\$\s/g, '' )}
                                    style={{ minWidth: 105 }}
                                />
                            </div>
                        ) : null
                    }

                    <CheckCircleOutlined onClick={this.submitEditedOrder} className="mgl-10 clickable clickable-colored" style={{ fontSize: 20 }} />

                </div>
            )
        }

        return null

    }

    private updateEditedOrder = ( key: 'remainingQuantity' | 'price' | 'auxPrice', value: number ) => {

        let updatedOrder = JSON.parse( JSON.stringify( this.state.editedOrder ) )

        updatedOrder[key] = value

        this.setState( {
            editedOrder: updatedOrder
        } )

    }

    private submitEditedOrder = () => {

        const { editedOrder } = this.state

        if ( editedOrder && editedOrder.remainingQuantity ) {

            let orderRequest: IBKROrderRequest = {
                conid: editedOrder.conid,
                orderType: editedOrder.orderType,
                quantity: editedOrder.remainingQuantity,
                secType: 'STK',
                side: editedOrder.side,
                ticker: editedOrder.ticker,
                tif: 'DAY',
                useAdaptive: false
            }

            if ( editedOrder.auxPrice ) {
                orderRequest.auxPrice = Num( editedOrder.auxPrice ).round( 2 ).toNumber()
            }

            if ( editedOrder.price ) {
                orderRequest.price = Num( editedOrder.price ).round( 2 ).toNumber()
            }

            this.props.editOrder( editedOrder.orderId, orderRequest )
        }

    }

}

export default IBKROrderItem;