/*Utils*/
import { createAction } from "./action-type-helper"
import { webStorage } from "./web_storage"

import { Dispatch } from "redux"
import Cookies from 'js-cookie';

/*Constant*/
import * as ACTION_NAMES from "../redux/action_names";

/*Modules */
import { notification } from "antd";

const DROPBOX_API: string = "https://api.dropboxapi.com/2/";
const DROPBOX_API_CONTENT: string = "https://content.dropboxapi.com/2/";

export function dataManagementLoadFile() {
    return async ( dispatch: Dispatch ) => {
        let fileContent = null;
        try {

            let token = Cookies.get( "access_token" )
            let fileId = await getFileId( token )


            if ( fileId ) {
                let url = `${ DROPBOX_API_CONTENT }files/download`
                let response = await fetch( url, {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${ token }`,
                        'Dropbox-API-Arg': JSON.stringify( {
                            path: fileId
                        } )
                    }
                } );

                let body = await response.json();
                if ( !body.error ) {
                    fileContent = body;
                    dispatch( createAction( ACTION_NAMES.RESET_DATA_STORED, null ) );
                    dispatch( createAction( ACTION_NAMES.MAIN_IMPORT_RECEIVED, fileContent ) );
                    notification.success( {
                        message: 'Success',
                        description: 'Your data has been retrieved.'
                    } )
                } else {
                    notification.error( {
                        message: 'Error',
                        description: 'An error occured while retrieving your data from Dropbox.'
                    } )
                }
            }
        } catch ( e ) {

            notification.error( {
                message: 'Error',
                description: e
            } )
        }

    };
};

export function dataManagementSaveFile() {
    return async ( dispatch: Dispatch ) => {

        let token = Cookies.get( "access_token" )
        try {
            let data = webStorage.getMainData()
            let json = JSON.stringify( data, undefined, '\t' )

            let blob = new Blob( [json], {
                type: 'application/json'
            } )

            let fileId = await getFileId( token )

            if ( fileId ) {
                let url = `${ DROPBOX_API_CONTENT }files/upload`
                let response = await fetch( url, {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${ token }`,
                        "Content-Type": "application/octet-stream",
                        'Dropbox-API-Arg': JSON.stringify( {
                            path: fileId,
                            mode: {
                                ".tag": "overwrite"
                            },
                        } )
                    },
                    body: blob
                } );

                let body = await response.json();
                notification.success( {
                    message: 'Success',
                    description: 'Your data has been saved on Dropbox.'
                } )
                if ( body.error ) {
                    notification.error( {
                        message: 'Error',
                        description: 'An error occured during uploading your data on Dropbox'
                    } )
                }
            }
        } catch ( e ) {

            notification.error( {
                message: 'Error',
                description: e
            } )

        }

    };
};

async function getFileId( token: string ) {
    try {
        let url = `${ DROPBOX_API }files/list_folder`
        let token = Cookies.get( "access_token" )
        let response = await fetch( url, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${ token }`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify( {
                path: "",
                recursive: false
            } )
        } );

        let body = await response.json();

        if ( !body.error ) {
            if ( body.entries.length === 1 ) {
                return body.entries[0].id
            } else {
                notification.error( {
                    message: 'Error',
                    description: 'There is more than one file in your dropbox folder.'
                } )
                return null
            }
        }
        else {
            throw 'Error!'
        }
    } catch ( e ) {
        notification.error( {
            message: 'Error',
            description: e
        } )

    }
}

