/* Actions */
import { BudgetMainImportReceivedAction, BudgetUpdateIncomesEntryAction, BudgetUpdateExpensesEntryAction, BudgetUpdateTaxesEntryAction } from "./budget_actions"

/* Constants */
import * as ACTION_NAMES from "../action_names"

/* Models */
import { BudgetEntry, BudgetTypeEnum } from "../../models"

/* State */
import { initialState, BudgetState } from "./budget_states"

type Actions = BudgetMainImportReceivedAction | BudgetUpdateIncomesEntryAction | BudgetUpdateExpensesEntryAction | BudgetUpdateTaxesEntryAction;

function budgetReducer( state = initialState, action: Actions ) {
    let stateToModify: BudgetState = JSON.parse( JSON.stringify( state ) );

    switch ( action.type ) {
        case ACTION_NAMES.MAIN_IMPORT_RECEIVED:
            let data = JSON.parse( action.payload.budget ) as BudgetState;
            let budgetData = data ? data : state;
            return Object.assign( {}, state, budgetData );

        case ACTION_NAMES.INCOMES_UPDATE_ENTRY:
            let expense = stateToModify.budget.filter( ( item: BudgetEntry ) => {
                if ( item.type !== BudgetTypeEnum.Income )
                    return item
            } );

            return Object.assign( {}, state, {
                budget: [...expense, ...action.payload.budget],
                expensesAmount: action.payload.expensesAmount,
                incomesAmount: action.payload.incomesAmount
            } );

        case ACTION_NAMES.EXPENSES_UPDATE_ENTRY:
            let incomes = stateToModify.budget.filter( ( item: BudgetEntry ) => {
                if ( item.type !== BudgetTypeEnum.Expense )
                    return item
            } );

            return Object.assign( {}, state, {
                budget: [...incomes, ...action.payload.budget],
                expensesAmount: action.payload.expensesAmount,
                incomesAmount: action.payload.incomesAmount
            } );

        default:
            return state;
    }
};

export default budgetReducer;