import React from 'react';

//components
import { Row, Col, Spin, Tag, Card, Popconfirm } from 'antd';
import OrderItem from './order_item'

/*Models*/
import { IBKROrder, IBKROrderRequest } from '../../models'

interface Props {
    loading: boolean;
    orders: IBKROrder[];
    cancelOrder: ( orderId: number ) => void;
    editOrder: ( orderId: number, orderConfig: IBKROrderRequest ) => void;
}

interface State {
}

class IBKROrdersList extends React.Component<Props, State> {

    constructor( props: Props ) {
        super( props );
    }

    render() {

        const { orders, loading, cancelOrder, editOrder } = this.props

        const parentOrders = orders.filter( item => !item.parentId )
        const childrenOrders = orders.filter( item => !!item.parentId )

        if ( loading ) {
            return <div className="text-center"><Spin /></div>
        }

        return (
            <div>
                {
                    parentOrders.map( order => {

                        const subOrders = childrenOrders && childrenOrders.filter( item => item.parentId === order.orderId )

                        return (
                            <div key={order.orderId} className="mgb-10">
                                <OrderItem
                                    loading={false}
                                    parentOrder={order}
                                    childrenOrders={subOrders}
                                    cancelOrder={cancelOrder}
                                    editOrder={editOrder}
                                />
                            </div>
                        )

                    } )
                }
            </div>
        )


    };

}

export default IBKROrdersList;