import React from 'react';
import { MapStateToProps, connect } from 'react-redux'
import { Tabs, Input, Button, Modal, Tooltip, Dropdown, Menu, Col, Row, notification, InputNumber } from 'antd'

import {
    CheckCircleOutlined,
    PlayCircleOutlined,
    LogoutOutlined,
    SyncOutlined,
    SettingOutlined
} from '@ant-design/icons';

import Portfolio from 'src/components/ibkr/portfolio'
import OrdersList from 'src/components/ibkr/orders_list'
import OrdersGenerator from 'src/components/ibkr/orders_generator'

/*Actions*/
import * as IBKRActions from '../redux/ibkr/ibkr_actions'

/*Models*/
import { ReduxProps, IBKRTicker, IBKRPosition, IBKROrder, IBKROrderRequest, IBKRConfig } from '../models'
import { IRootState } from '../redux/store'

//utils
import { GREEN_COLOR, TARGET_COLOR } from '../utils/helpers';
import { webStorage } from '../utils/web_storage';
import WebSocketConnection from '../utils/websocket'

/*Component*/

interface Props extends ReduxProps {

    loadingSession: boolean;
    sessionId: number;

    accounts: string[];
    selectedAccount: string;

    tickerSearchLoading: boolean;
    tickerSearchResults: IBKRTicker[];

    positionsLoading: boolean;
    positions: IBKRPosition[];

    selectedStocks: IBKRTicker[];

    ordersLoading: boolean;
    orders: IBKROrder[];

    config: IBKRConfig;

}

interface State {
    activeKey: string;

    tickerInput: string;

    showConfig: boolean;
}

class IBKRConnector extends React.Component<Props, State> {
    private webSocket: WebSocketConnection;
    public static select: MapStateToProps<any, any, IRootState> = ( state: IRootState ) => {
        return {
            loadingSession: state.ibkr.loadingSession,
            sessionId: state.ibkr.sessionId,
            accounts: state.ibkr.accounts,
            selectedAccount: state.ibkr.selectedAccount,
            tickerSearchLoading: state.ibkr.tickerSearchLoading,
            tickerSearchResults: state.ibkr.tickerSearchResults,
            positionsLoading: state.ibkr.positionsLoading,
            positions: state.ibkr.positions,
            selectedStocks: state.ibkr.selectedStocks,
            ordersLoading: state.ibkr.ordersLoading,
            orders: state.ibkr.orders,
            config: state.ibkr.config
        } as Props
    }

    constructor( props: Props ) {
        super( props )

        this.state = {
            activeKey: 'trade_assistant',
            tickerInput: '',
            showConfig: false
        }
    }

    componentDidMount() {

        if ( this.props.sessionId ) {
            this.startSession()
        }

    }

    render() {

        const { config, loadingSession, sessionId, tickerSearchLoading, tickerSearchResults, positionsLoading, positions, selectedAccount, accounts, selectedStocks, orders, ordersLoading } = this.props
        const { activeKey, tickerInput, showConfig } = this.state

        if ( !config || !config.gatewayURL || showConfig ) {
            return this.getConfigUI()
        }

        return (
            <div className="rel">
                {
                    selectedAccount ? (
                        <Tooltip title={`Accounts list: ${ accounts.join( ', ' ) }`} placement="left">
                            <div className="abs text-right" style={{ right: 10, top: -5, zIndex: 5 }}>
                                <div className="small-label">Account n°</div>
                                <div className="font-90 bold colored">{selectedAccount}</div>
                            </div>
                        </Tooltip>
                    ) : null
                }

                <Tabs activeKey={activeKey} centered onChange={this.handleTabChange}>
                    <Tabs.TabPane tab="Trade Assistant" key="trade_assistant">

                        <div className="mgt-15 text-center">
                            <Input.Search
                                value={tickerInput}
                                onChange={e => { this.setState( { tickerInput: ( e.target.value || '' ).toUpperCase() } ) }}
                                placeholder="Ticker"
                                onSearch={this.handleTickerSearch}
                                style={{ width: 240 }}
                                allowClear
                                loading={tickerSearchLoading}
                                disabled={!selectedAccount}
                            />
                        </div>

                        <div className="mgt-30">
                            {
                                tickerSearchResults.filter( item => !!item.symbol ).map( item => {
                                    return (
                                        <div className='light-bg pad-5 padl-10 padr-10 mgb-10' key={item.conid}>
                                            <Row justify="space-between" align="middle">
                                                <Col>

                                                    <div className="inline-block">
                                                        <div>
                                                            <span className="bold">{item.symbol}</span>
                                                            <span className="mgl-5 mgr-5 grey-color">&bull;</span>
                                                            <span className="font-90" style={{ color: TARGET_COLOR }}>{item.description}</span>
                                                        </div>
                                                        <div className="font-80"><em>{item.companyHeader}</em></div>
                                                    </div>

                                                    {
                                                        item.sections && item.sections.length ? (
                                                            <div className="inline-block mgl-30">
                                                                <div className="font-70 bold">
                                                                    <em>{item.sections[0].secType}</em>
                                                                </div>
                                                            </div>
                                                        ) : null
                                                    }

                                                </Col>
                                                <Col>
                                                    <Button
                                                        onClick={() => this.selectStock( item )}
                                                        type="primary"
                                                        icon={<CheckCircleOutlined />}
                                                        disabled={!item.sections || item.sections.filter( ( sec: any ) => sec.secType === 'STK' ).length === 0}
                                                    >Select</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                } )
                            }
                        </div>

                        <div className="mgt-30">
                            {
                                selectedStocks.map( item => {
                                    return (
                                        <div className="mgb-10" key={item.conid}>
                                            <OrdersGenerator
                                                ticker={item}
                                                RUnit={config.RUnit}
                                                RTarget={config.RTarget}
                                                submitOrders={this.submitOrders}
                                                cancel={() => { this.deselectStock( item ) }}
                                            />
                                        </div>
                                    )
                                } )
                            }
                        </div>

                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Orders" key="orders">

                        <div className="mgb-15 text-right">
                            <Button onClick={this.loadOrders} icon={<SyncOutlined />} disabled={ordersLoading}>Reload orders</Button>
                        </div>

                        <OrdersList
                            loading={ordersLoading}
                            orders={orders}
                            cancelOrder={this.cancelOrder}
                            editOrder={this.editOrder}
                        />

                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Portfolio" key="portfolio">

                        <div className="mgb-15 text-right">
                            <Button onClick={this.loadPositions} icon={<SyncOutlined />} disabled={positionsLoading}>Reload positions</Button>
                        </div>

                        <Portfolio
                            loading={positionsLoading}
                            positions={positions}
                        />

                    </Tabs.TabPane>
                </Tabs>
                <div className="mgt-50 text-right">

                    {
                        sessionId ? (
                            <Button onClick={this.logoutSession} icon={<LogoutOutlined />} loading={loadingSession}>Stop session</Button>
                        ) : (
                            <Button onClick={this.startSession} type="primary" icon={<PlayCircleOutlined />} loading={loadingSession}>Start session</Button>
                        )
                    }

                    <span className="mgl-20"><SettingOutlined className="clickable clickable-main" onClick={() => { this.setState( { showConfig: true } ) }} /></span>

                </div>
            </div>
        );
    }

    private startSession = () => {

        const { config } = this.props

        this.props.dispatch( IBKRActions.getAccountsAndStartSession() )

        this.webSocket = new WebSocketConnection( `wss://${ config.gatewayURL.replace( 'https://', '' ) }/v1/api/ws` );

    }

    private logoutSession = () => {
        Modal.confirm( {
            title: 'Confirm',
            content: 'Are you sure you want to quit the session and logout?',
            okText: 'Yes',
            okType: 'dashed',
            onOk: () => {
                this.props.dispatch( IBKRActions.logoutSession() )
            }
        } )
    }

    private handleTabChange = ( activeKey: string ) => {

        this.setState( {
            activeKey: activeKey
        } )

    }

    private handleTickerSearch = ( value: string ) => {
        if ( value ) {
            this.props.dispatch( IBKRActions.searchTicker( value ) )
        } else {
            this.props.dispatch( IBKRActions.clearTickerSearch() )
        }
    }

    private loadPositions = () => {

        const { selectedAccount } = this.props

        if ( !selectedAccount ) {
            Modal.warning( {
                title: 'No account loaded',
                content: 'Please start a session before loading portfolio...'
            } )
        } else {
            this.props.dispatch( IBKRActions.getPositions( selectedAccount, 0 ) )
        }

    }

    private getConfigUI = () => {

        const { config } = this.props

        return (
            <div>

                <h2 className="mgb-30">Settings</h2>

                <Row justify="space-around" align="middle">
                    <Col>

                        <div className="medium-label" style={{ marginBottom: 3 }}>R Unit</div>
                        <InputNumber
                            value={config.RUnit}
                            onChange={( value: any ) => { this.handleConfigChange( { RUnit: value } ) }}
                            style={{ width: 100 }}
                            step={1}
                            min={5}
                            formatter={value => `$ ${ value }`.replace( /\B(?=(\d{3})+(?!\d))/g, ',' )}
                            parser={value => ( value as any ).replace( /\$\s?|(,*)/g, '' )}
                        />

                        <div className="medium-label mgt-15" style={{ marginBottom: 3 }}>R Target</div>
                        <InputNumber
                            value={config.RTarget}
                            onChange={( value: any ) => { this.handleConfigChange( { RTarget: value } ) }}
                            style={{ width: 100 }}
                            step={0.05}
                            min={0}
                        />
                    </Col>
                    <Col>
                        <div className="medium-label" style={{ marginBottom: 3 }}>Gateway URL</div>
                        <Input
                            value={config.gatewayURL}
                            onChange={e => { this.handleConfigChange( { gatewayURL: e.target.value } ) }}
                            style={{ width: 320 }}
                            allowClear
                        />
                    </Col>
                    <Col>
                        <div className="mgt-20 text-right">
                            <Button style={{ width: 100 }} type="primary" onClick={() => { this.setState( { showConfig: false } ) }}>OK</Button>
                        </div>
                    </Col>
                </Row>

            </div>
        )

    }

    private handleConfigChange = ( params: IBKRConfig ) => {

        const { config } = this.props

        this.props.dispatch( IBKRActions.setConfigParams( Object.assign( {}, config, params ) ) )

    }

    private selectStock = ( ticker: IBKRTicker ) => {

        const { selectedStocks } = this.props

        if ( selectedStocks.find( item => item.conid === ticker.conid ) ) {

            notification.info( {
                message: 'Stock already selected!'
            } )

        } else {

            this.props.dispatch( IBKRActions.selectStock( ticker ) )

        }

    }

    private deselectStock = ( ticker: IBKRTicker ) => {

        this.props.dispatch( IBKRActions.deselectStock( ticker ) )

    }

    private loadOrders = () => {

        const { selectedAccount } = this.props

        if ( !selectedAccount ) {
            Modal.warning( {
                title: 'No account loaded',
                content: 'Please start a session before loading orders...'
            } )
        } else {
            this.props.dispatch( IBKRActions.getOrders() )
        }

    }

    private submitOrders = ( orders: IBKROrderRequest[] ) => {
        this.props.dispatch( IBKRActions.placeOrders( orders ) )
    }

    private cancelOrder = ( orderId: number ) => {
        this.props.dispatch( IBKRActions.cancelOrder( orderId.toString() ) )
    }

    private editOrder = ( orderId: number, orderConfig: IBKROrderRequest ) => {
        this.props.dispatch( IBKRActions.editOrder( orderId.toString(), orderConfig ) )
    }

}

export default connect( IBKRConnector.select )( IBKRConnector as any );
