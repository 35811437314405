import React from 'react';
import { Row, Col, Button, Empty } from 'antd';
import { PlusOutlined, MenuOutlined, AppstoreOutlined } from '@ant-design/icons';
import moment from 'moment';

/*Components*/
import TradeView from './trade_view'
import TradeDetailsView from './trade_details_view'
import TradesTableView from './trades_table_view'

/*Models*/
import { Trade } from '../../models';
import { TARGET_COLOR } from '../../utils/helpers';

interface Props {
    trades: Trade[];
    addTradeButton: ( planned_trade: boolean ) => void;
    openEditTradeView: ( trade: Trade ) => void;
    handleRemoveTrade: ( id: string, planned_trade: boolean ) => void;
    plannedTrades: boolean;
    tableView?: boolean;
    setTableView?: ( value: boolean ) => void;
    totalTradesCount?: number;
}

interface State {
    visible: boolean;
    tradeDetails: Trade | null;
}

class TradesView extends React.Component<Props, State> {

    constructor( props: Props ) {
        super( props )

        this.state = {
            visible: false,
            tradeDetails: null
        }
    }

    render() {
        const { trades, tableView, plannedTrades, setTableView, totalTradesCount } = this.props
        const { visible, tradeDetails } = this.state

        trades.sort( ( a, b ) => moment( b.start_at ).unix() - moment( a.start_at ).unix() )

        return (
            <>
                <Row gutter={[10, 10]} justify="space-between" align="bottom">

                    <Col>
                        {
                            plannedTrades || !trades || !trades.length ? null : (
                                <div className="upper dark-grey-color" style={{ position: 'relative', top: 5 }}>
                                    <span className="mgr-5 font-90">Showing</span>
                                    <span className="mgr-5 bold" style={{ color: TARGET_COLOR }}>{trades.length === totalTradesCount ? 'all' : trades.length}</span>
                                    <span className="mgr-5 font-90">{trades.length === totalTradesCount ? 'trades' : `trade${ trades.length > 1 ? 's' : '' } out of`}</span>
                                    <span className="bold font-90">{trades.length === totalTradesCount ? `(${ totalTradesCount })` : totalTradesCount}</span>
                                </div>
                            )
                        }
                    </Col>

                    <Col>
                        <Row justify="end" gutter={10}>
                            {
                                !!setTableView ? (
                                    <Col>
                                        <Button type="dashed" icon={!tableView ? <MenuOutlined /> : <AppstoreOutlined />} onClick={() => setTableView( tableView )} />
                                    </Col>
                                ) : null
                            }
                            <Col>
                                <Button onClick={() => this.props.addTradeButton( plannedTrades )} type="dashed" >
                                    <span><PlusOutlined /> Add trade</span>
                                </Button>
                            </Col>
                        </Row>
                    </Col>

                </Row>

                <Row gutter={[20, 20]} className="mgt-5">
                    {trades && trades.length > 0 ? this.tradeView( trades ) : this.emptyView()}
                </Row>

                {tradeDetails && <TradeDetailsView visible={visible} onCloseViewDetails={this.onClose} trade={tradeDetails as Trade}></TradeDetailsView>}
            </>
        )

    }


    private tradeView = ( trades: Trade[] ) => {
        const { tableView } = this.props

        if ( !tableView ) {
            return (
                trades.map( ( trade: Trade, idx: any ) =>
                    <Col span={24} key={idx}>
                        <TradeView trade={trade} onEditTrade={this.props.openEditTradeView} onRemoveTrade={this.props.handleRemoveTrade} handleViewDetails={this.showDrawer}></TradeView>
                    </Col>
                )
            );
        } else {
            return (
                <Col span={24} key="table-view">
                    <TradesTableView trades={trades} onEditTrade={this.props.openEditTradeView} onRemoveTrade={this.props.handleRemoveTrade} handleViewDetails={this.showDrawer}></TradesTableView>
                </Col>
            );
        }

    }

    private emptyView = () => {
        return ( <div className="center-zone"><Empty /></div> );
    }

    private showDrawer = ( trade: Trade ) => {
        this.setState( {
            tradeDetails: trade,
            visible: true,
        } );
    };

    private onClose = () => {
        this.setState( {
            tradeDetails: null,
            visible: false,
        } );
    };


}

export default TradesView;