import React from 'react';
import { Row, Col, Select, Form, DatePicker, Button, Space, Tooltip, InputNumber, Input } from 'antd';
import moment from 'moment';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

/*Models*/
import { Position, TimeFrame, TradesFilters } from '../../models';
import { DATE_FORMAT } from '../../utils/helpers';

interface Props {
    filters: TradesFilters;
    setFilters: ( filters: TradesFilters | null ) => void;
}

interface State {

}

class TradesFiltersView extends React.Component<Props, State> {
    private baseState: State;
    constructor( props: Props ) {
        super( props )

        this.state = {
            filters: {}
        }
        this.baseState = this.state
    }

    render() {

        const { filters } = this.props

        const Picker: any = DatePicker

        return (
            <div className="mgt-5">
                <Row gutter={[15, 15]}>

                    <Col>
                        <Space size="small">
                            <span>Ticker</span>
                            <Input size="small" placeholder="Ticker name" defaultValue={filters ? filters.ticker : ""} onPressEnter={value => this.handleChange( 'ticker', value )} />

                        </Space>
                    </Col>

                    <Col>
                        <Space size="small">
                            <span>Start</span>
                            <Picker size="small" value={filters ? filters.start_at : null} format={DATE_FORMAT} onChange={( value: any ) => this.handleChange( 'start_at', value )} />

                            <span>End</span>
                            <Picker size="small" value={filters ? filters.end_at : null} format={DATE_FORMAT} onChange={( value: any ) => this.handleChange( 'end_at', value )} />
                        </Space>
                    </Col>

                    <Col>
                        <Space size="small">
                            <span>Position</span>
                            <Select size="small"
                                placeholder="Select"
                                allowClear
                                value={filters ? filters.position : null}
                                onChange={value => this.handleChange( 'position', value )}>
                                {Object.values( Position ).map( value =>
                                    <Select.Option
                                        key={value}
                                        value={value}>
                                        {value.toUpperCase()}
                                    </Select.Option>
                                )}
                            </Select>
                        </Space>
                    </Col>

                    <Col>
                        <Space size="small">
                            <span>Time frame</span>
                            <Select
                                style={{ minWidth: 110 }}
                                size="small"
                                placeholder="Select"
                                allowClear
                                mode="multiple"
                                value={filters && filters.time_frame ? filters.time_frame : []}
                                onChange={value => this.handleChange( 'time_frame', value )}>
                                {Object.values( TimeFrame ).map( value =>
                                    <Select.Option
                                        key={value}
                                        value={value}>
                                        {value.toUpperCase()}
                                    </Select.Option>
                                )}
                            </Select>
                        </Space>
                    </Col>

                    <Col>
                        <Space size="small">
                            <span>Min. risk</span>
                            <InputNumber size="small" min={0} value={filters ? filters.min_risk : null} placeholder="0" onChange={value => this.handleChange( 'min_risk', value )} />
                        </Space>
                    </Col>

                    <Col>
                        <Space size="small">
                            <span>Min. duration</span>
                            <InputNumber size="small" min={0} value={filters ? filters.min_duration : null} placeholder="In minutes" onChange={value => this.handleChange( 'min_duration', value )} />

                            <span>Max. duration</span>
                            <InputNumber size="small" min={0} value={filters ? filters.max_duration : null} placeholder="In minutes" onChange={value => this.handleChange( 'max_duration', value )} />
                        </Space>
                    </Col>

                    <Col>
                        <Tooltip title="Reset">
                            <Button shape="circle" size="small" icon={<CloseOutlined />} onClick={this.resetFilters} />
                        </Tooltip>
                    </Col>

                </Row>
            </div>
        )

    }

    private resetFilters = () => {
        this.props.setFilters( null )
    }

    private handleChange = ( key: string, value: any ) => {

        const updatedFilters = Object.assign( {}, this.props.filters, {
            [key]: value.target ? value.target.value : value
        } )

        this.props.setFilters( updatedFilters )

    }

}

export default TradesFiltersView;