import React, { Children } from 'react';
import { Divider, Tag, Form, InputNumber } from 'antd';
import { FormattedNumber, injectIntl } from 'react-intl'

/*Models*/
import { BudgetEntry } from '../../models/index'

/*Components*/
import TaxBracketsTable from './tax_brackets_table'
import TaxReportTable from './tax_report_table'

/*Helpers*/
import * as TAXES_HELPER from '../../utils/taxes_calculation'

interface Props {
    entries: BudgetEntry[]
}

interface State {
    shares: number;
    childCount: number;
    taxCredit: number;
}

class TaxReportView extends React.Component<Props, State> {

    constructor( props: Props ) {
        super( props )

        this.state = {
            shares: 2.5,
            childCount: 1,
            taxCredit: 0
        }
    }

    render() {
        const { entries } = this.props

        const { shares, childCount, taxCredit } = this.state

        let reference = ( ( TAXES_HELPER.getYearlyIncomeSum( entries ) - TAXES_HELPER.getYearlyTaxAllowanceSum( entries ) ) / shares );

        return (
            < >
                <TaxReportTable entries={entries}></TaxReportTable>
                <Divider dashed></Divider>
                <Form initialValues={{ shares: shares, childCount: childCount, taxCredit }} layout="inline" onValuesChange={this.handleOnChange}>
                    <Form.Item name="shares" label="Nombre de parts">
                        <InputNumber></InputNumber>
                    </Form.Item>
                    <p className="text-small" style={{ lineHeight: '32px', marginRight: 20 }}><em>Revenu de référence pour 1 part : <Tag><FormattedNumber
                        value={reference}
                        style="currency"
                        currency="EUR"
                        currencyDisplay="symbol"
                    /></Tag>
                    </em></p>
                    <Form.Item name="childCount" label="Nombre d'enfants à charge">
                        <InputNumber></InputNumber>
                    </Form.Item>
                    <Form.Item name="taxCredit" label="Crédit d'impôt">
                        <InputNumber></InputNumber>
                    </Form.Item>
                </Form>
                <Divider dashed></Divider>
                <TaxBracketsTable entries={entries} shares={shares} childCount={childCount} taxCredit={taxCredit}></TaxBracketsTable>
            </>
        );
    }

    private handleOnChange = ( value: any, allValues: any ) => {
        this.setState( {
            shares: allValues.shares,
            childCount: allValues.childCount,
            taxCredit: allValues.taxCredit
        } )
    }
}

export default TaxReportView;