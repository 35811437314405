import React from 'react';
import { Modal, Input, Form, Row, Col, Button } from 'antd';
import * as uuid from 'uuid';

/*Models*/
import { Product } from '../../models/index'

interface Props {
    visible: boolean;
    closeModal: () => void;
    addProduct: ( p: Product ) => void;
}

interface State {
}

class ProductCostAddModal extends React.Component<Props, State> {

    constructor( props: Props ) {
        super( props );
    }

    private handleOk = ( values: any ) => {
        this.props.addProduct( {
            id: uuid.v4(),
            name: values.name,
            potential_cost: 0,
            total_items: 0,
            product_items: []
        } );
    };

    private hideModal = () => {
        this.props.closeModal();
    };

    render() {

        const { visible } = this.props

        return ( <div>
            <Modal
                title="Ajouter un nouveau produit"
                visible={visible}
                footer={null}
                onCancel={this.hideModal}
            >
                <Form id="modal-create-new-product" onFinish={( values ) => this.handleOk( values )}>

                    <Form.Item label="Nom du produit" name="name">
                        <Input placeholder="Saisir le nom du produit" />
                    </Form.Item>
                    <Form.Item>
                        <Row justify="end">
                            <Col>
                                <Button onClick={this.hideModal}>Annuler</Button>
                                <Button type="primary" htmlType="submit">Créer le nouveau produit</Button>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            </Modal>

        </div> );
    };
}

export default ProductCostAddModal;