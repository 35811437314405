import React from 'react';
import { Table, Tag } from 'antd';
import * as uuid from 'uuid';
import { ColumnProps } from 'antd/lib/table'
import { FormattedNumber } from 'react-intl'

/*Models*/
import { InvestmentIteration } from '../../models/index'

interface Props {
    iterations: InvestmentIteration[]
}

interface State {
}

class InvestmentTable extends React.Component<Props, State> {

    constructor( props: Props ) {
        super( props );
    }

    render() {
        const { iterations } = this.props

        return ( <div>
            <Table size="small"
                dataSource={iterations}
                columns={this.getColumns()}
                rowClassName={() => 'editable-row'}
                rowKey={record => record.iteration} />
        </div> );
    };

    private getColumns = (): Array<ColumnProps<InvestmentIteration>> => {

        return [
            {
                title: 'Itération',
                key: 'iteration',
                dataIndex: 'iteration',
                render: ( value: string, record: InvestmentIteration ) => {
                    return record.iteration;
                }
            },
            {
                title: 'Capital',
                key: 'capital',
                dataIndex: 'capital',
                render: ( value: string, record: InvestmentIteration ) => {
                    return this.getInputFormattedNumber( record.capital );
                }
            },
            {
                title: 'Bénéfice brut',
                key: 'grossProfit',
                dataIndex: 'grossProfit',
                render: ( value: string, record: InvestmentIteration ) => {
                    return this.getInputFormattedNumber( record.grossProfit );
                }
            },
            {
                title: 'Frais',
                key: 'fees',
                dataIndex: 'fees',
                render: ( value: string, record: InvestmentIteration ) => {
                    let tagColor: string = record.fees < 0 ? "red" : "green";
                    return ( <Tag color={tagColor}>{this.getInputFormattedNumber( record.fees )}</Tag> );
                }
            },
            {
                title: 'Capital ajouté',
                key: 'addedCapital',
                dataIndex: 'addedCapitalByIteration',
                render: ( value: string, record: InvestmentIteration ) => {
                    return this.getInputFormattedNumber( record.addedCapitalByIteration )
                }
            },
            {
                title: 'Bénéfice net',
                key: 'netProfit',
                dataIndex: 'netProfit',
                render: ( value: string, record: InvestmentIteration ) => {
                    let tagColor: string = record.netProfit < 0 ? "red" : "green";
                    return ( <Tag color={tagColor}>{this.getInputFormattedNumber( record.netProfit )}</Tag> );
                }
            }
        ]
            ;
    }

    private getInputFormattedNumber( value: number ) {
        return (
            <FormattedNumber
                value={value}
                style="currency"
                currency="EUR"
                currencyDisplay="symbol"
                maximumFractionDigits={4}
            />
        );

    }


}

export default InvestmentTable;