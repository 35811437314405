import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import { ConfigProvider } from 'antd'
import { PersistGate } from 'redux-persist/integration/react'
import { IntlProvider } from 'react-intl'

import { Provider } from 'react-redux'
import { store, persistore } from './redux/store'

import App from './App';

import * as serviceWorker from './serviceWorker';

import { webStorage } from './utils/web_storage'
import { getLocaleProvider, getMessages } from './utils/language'

const language = webStorage.getLanguage()
const localeProvider = getLocaleProvider()
const messages = getMessages()

const Gate: any = PersistGate

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={localeProvider}>
      <IntlProvider locale={language} key={language} messages={messages}>
        <Provider store={store}>
          <Gate loading={null} persistor={persistore}>
            <App />
          </Gate>
        </Provider>
      </IntlProvider>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById( 'root' )
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
