import React from 'react';
import { Modal, Input, Form, Row, Col, Button, Space, Collapse } from 'antd';
import * as uuid from 'uuid';

/*Models*/
import { Product, PayloadString } from '../../models/index'

interface Props {
    visible: boolean
    closeModal: () => void;
}

interface State {

}

class DropboxAuthorizationModal extends React.Component<Props, State> {

    private handleOk = ( values: any ) => {
        let redirectUrl = encodeURIComponent( window.location.href.split( '#' )[0] );
        let url = `https://www.dropbox.com/oauth2/authorize?client_id=${ values.apikey }&response_type=token&redirect_uri=${ redirectUrl }`
        window.location.assign( url );
    };

    private hideModal = () => {
        this.props.closeModal();
    };

    private handleChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
        this.setState( { apiKey: event.target.value } )
    }

    render() {

        const { visible } = this.props


        return ( <div>
            <Modal
                title="Connect to your data"
                visible={visible}
                footer={null}
                onCancel={this.hideModal}
            >
                <Row gutter={[16, 16]}> <Col span={24}>
                    <Collapse >
                        <Collapse.Panel header="How to get my App Key ?" key="1" >
                            <p>                <ol>
                                <li>Create a new application : https://www.dropbox.com/developers/apps</li>
                                <li>Create a new app on the Dropbox platform, with the following params :</li>
                                <ul>
                                    <li>Choose API : Scoped access</li>
                                    <li>Choose the type of access you need : App folder</li>
                                    <li>Name your app : "EToolbox"</li>
                                </ul>
                                <li>Settings tab - OAuth 2 configuration:</li>
                                <ul>
                                    <li>Redirect URIs : https://tools.fmit.fr/e-toolbox/</li>
                                    <li>Choose the type of access you need : App folder</li>
                                </ul>
                                <li>Permissions tab: tick the boxes "files.content.write", "files.content.read", "file_requests.write", "file_requests.read"</li>
                                <li>Save in a protected place the generated App Key</li>
                            </ol></p>
                        </Collapse.Panel>
                    </Collapse>
                </Col>
                    <Col span={24}>
                        <Form onFinish={( values ) => this.handleOk( values )} initialValues={this.props}>

                            <Form.Item label="Dropbox APP KEY" name="apikey">
                                <Input type="text" placeholder="Enter your APP KEY" name="apiKey" />
                            </Form.Item>
                            <Form.Item>
                                <Row justify="end">
                                    <Col>
                                        <Space>
                                            <Button onClick={this.hideModal}>Cancel</Button>
                                            <Button type="primary" htmlType="submit">Connect</Button>
                                        </Space>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                    </Col></Row>
            </Modal>

        </div > );
    };
}

export default DropboxAuthorizationModal;