import moment from 'moment';

export interface ReduxProps {
    dispatch: ( action: any ) => void;
}

export enum InputType {
    Input,
    InputNumber,
    Select
}

export interface PersistRootState {
    productCost: string,
    budget: string,
    investment: string,
    trading: string
}

export interface Product {
    id: string,
    name: string,
    potential_cost: number,
    total_items: number,
    product_items: ProductDetail[]
}

export interface PayloadProductDetail {
    key: string;
    value: ProductDetail | number;
}

export interface PayloadNumber {
    key: string;
    value: number;
}

export interface PayloadString {
    key: string;
    value: string;
}

export interface ProductKey {
    key: string;
}

export interface ProductDetail {
    id: string;
    name: string,
    packaging: number,
    unit: string,
    price: number,
    unit_price: number,
    quantity_per_product: number,
    cost: number,
    comment: string
}
/*Investment*/
export interface InvestmentIteration {
    iteration: number;
    capital: number;
    fees: number;
    grossProfit: number;
    netProfit: number;
    addedCapitalByIteration: number;
}

export interface LoanIteration {
    iteration: number;
    iterationDate: moment.Moment;
    remainingCapital: number;
    interestsPart: number;
    capitalPart: number;
    monthlyPayment: number;
}


export interface LoanIterationSums {
    iteration: number;
    payment: number;
    interest: number;
    capital: number;
}

export interface LoanConfiguration {
    loanAmount: number;
    loanInterestRate: number;
    loanIterations: number;
    loanInsuranceAmount: number;
    loanFirstPaymentDate: moment.Moment | null;
}

export interface InvestmentConfiguration {
    startingCapital: number;
    interestRate: number;
    iterations: number;
    feesByIterationRate: number;
    feesByIterationFixed: number;
    addedCapitalByIteration: number;
}

export interface PayloadInvestment {
    investment: InvestmentConfiguration,
    investmentIterations: InvestmentIteration[]
}

export interface PayloadLoan {
    loan: LoanConfiguration,
    loanIterations: LoanIteration[]
}

/*Trading*/

export interface Trade {
    id: string;

    planned_trade: boolean;
    ticker: string;
    position: Position;

    start_at?: any;//ISO string date
    entry_qty: number;
    entry_price: number;
    stop_price: number;//TOP

    end_at?: any;//ISO string date
    exit_qty: number;
    exit_price: number;

    //might be used for partial lots
    entry_qty_2: number;
    entry_price_2: number;
    exit_qty_2: number;
    exit_price_2: number;

    //might be used for partial lots
    entry_qty_3: number;
    entry_price_3: number;
    exit_qty_3: number;
    exit_price_3: number;

    fees: number;

    setup?: Setup;
    time_frame?: TimeFrame;
    with_daily?: boolean;
    with_mkt?: boolean;
    comments?: string;

    bar_by_bar_after_1R?: number;
    stop_to_BE_at_1R_tgt_2R?: number;
    AON_1_R?: number;
    AON_1_5_R?: number;
    AON_2_R?: number;
    AON_3_R?: number;

    //calculated values
    qty?: number;/***   =>  entry_qty + entry_qty_2 + entry_qty_3   ***/ //TOP

    avg_entry_price?: number;/***   =>  ( ( entry_qty * entry_price ) + ( entry_qty_2 * entry_price_2 ) + ( entry_qty_3 * entry_price_3 ) ) / qty   ***///TOP
    avg_exit_price?: number;/***   =>  ( ( entry_qty * entry_price ) + ( entry_qty_2 * entry_price_2 ) + ( entry_qty_3 * entry_price_3 ) ) / qty   ***///TOP

    stop_size?: number;/***   =>  abs. value stop_price - avg_entry_price   ***/
    risk?: number;/***   =>  stop_size * qty   ***///TOP

    gross_PL?: number;/***   =>  ( avg_exit_price - avg_entry_price ) * qty  ***///TOP
    net_PL?: number;/***   =>  gross_PL - fees  ***/
    gross_R?: number;/***   =>  gross_PL / risk  ***/
    net_R?: number;/***   =>  net_PL / risk  ***/

    //calculated trade duration - base unit = seconds (even if it can be days)
    duration?: number;
}

export interface TradesFilters {
    ticker?: string;
    start_at?: moment.Moment;
    end_at?: moment.Moment;
    position?: Position;
    time_frame?: TimeFrame[];
    min_risk?: number;
    min_duration?: number;
    max_duration?: number;
}

export enum Setup {
    Buy_setup = "buy_setup",
    Short_setup = "short_setup",
    Three_BP = "3BP",
    Double_top = "double_top",
    Double_bottom = "double_bottom",
    Breakout = "breakout",
    Climactic = "climactic",
    Retest = "retest"
}

export enum Position {
    Short = "short",
    Long = "long"
}

export enum TimeFrame {
    One = "1'",
    Two = "2'",
    Five = "5'",
    Fifteen = "15'",
    Sixty = "60'",
    Daily = "daily",
    Weekly = "weekly"
}

export interface BudgetEntry {
    id: string;
    name: string;
    value: number;
    type: BudgetTypeEnum,
    yearly?: boolean;
    taxAllowance?: number;
    tax?: number;
}

export interface BudgetResult {
    entries: BudgetEntry[],
    entryType: BudgetTypeEnum,
    sumValue: number
}

export interface Budget {
    entries: BudgetEntry[],
    expensesAmount: number,
    incomesAmount: number
}

export enum BudgetTypeEnum {
    Expense = "EXPENSE",
    Income = "INCOME",
    TaxInput = "TAX_INPUT"
}

export interface JQuery {
    tooltip: any;
}
export type TradeManagementType = 'bar_by_bar_after_1R' | 'stop_to_BE_at_1R_tgt_2R' | 'AON_1_R' | 'AON_1_5_R' | 'AON_2_R' | 'AON_3_R'

export interface TaxBrackets {
    min: number,
    max: number,
    rate: number
}

export interface IBKRTicker {
    conid: number;
    companyHeader: string;
    companyName: string;
    symbol: string;
    description: string;
    restricted: string;
    fop: string;
    opt: string;
    war: string;
    sections: any[];
}

export interface IBKRPosition {
    acctId: string;
    conid: number;
    contractDesc: string;
    assetClass: string;
    position: number;
    mktPrice: number;
    mktValue: number;
    currency: string;
    avgCost: number;
    avgPrice: number;
    realizedPnl: number;
    unrealizedPnl: number;
    exchs: string;
    expiry: string;
    putOrCall: string;
    multiplier: number;
    strike: number;
    exerciseStyle: string;
    undConid: number;
    conExchMap: string[];
    baseMktValue: number;
    baseMktPrice: number;
    baseAvgCost: number;
    baseAvgPrice: number;
    baseRealizedPnl: number;
    baseUnrealizedPnl: number;
    name: string;
    lastTradingDay: string;
    group: string;
    sector: string;
    sectorGroup: string;
    ticker: string;
    undComp: string;
    undSym: string;
    fullName: string;
    pageSize: number;
    model: string;
}

export interface IBKRTrade {
    execution_id: string;
    symbol: string;
    side: string;
    order_description: string;
    trade_time: string;
    trade_time_r: number;
    size: string;
    price: string;
    submitter: string;
    exchange: string;
    comission: number;
    net_amount: number;
    account: string;
    company_name: string;
    contract_description_1: string;
    sec_type: string;
    conidex: string;
    position: string;
    clearing_id: string;
    clearing_name: string;

}

export type IBKROrderStatus = 'PendingSubmit' | 'PendingCancel' | 'PreSubmitted' | 'Submitted' | 'Cancelled' | 'Filled' | 'Inactive';

/*
    PendingSubmit - Indicates the order was sent, but confirmation has not been received that it has been received by the destination. Occurs most commonly if an exchange is closed.
    PendingCancel - Indicates that a request has been sent to cancel an order but confirmation has not been received of its cancellation.
    PreSubmitted - Indicates that a simulated order type has been accepted by the IBKR system and that this order has yet to be elected. The order is held in the IBKR system until the election criteria are met. At that time the order is transmitted to the order destination as specified.
    Submitted - Indicates that the order has been accepted at the order destination and is working.
    Cancelled - Indicates that the balance of the order has been confirmed cancelled by the IB system. This could occur unexpectedly when IB or the destination has rejected the order.
    Filled - Indicates that the order has been completely filled.
    Inactive - Indicates the order is not working, for instance if the order was invalid and triggered an error message, or if the order was to short a security and shares have not yet been located.
*/

export interface IBKROrder {
    acct?: string;//account id
    conid?: number;
    orderDesc?: string;
    description1?: string;
    ticker?: string;
    secType?: string;
    listingExchange?: string;
    remainingQuantity?: number;
    filledQuantity?: number;
    companyName?: string;
    status?: IBKROrderStatus;
    origOrderType?: string;
    orderType?: IBKROrderType;
    side?: 'SELL' | 'BUY';
    price?: number;
    auxPrice?: number | string;
    bgColor?: string;
    fgColor?: string;
    orderId?: number;
    parentId?: number;
    cashCcy?: string;
    sizeAndFills?: string;
}

export interface IBKROrderRequest {
    acctId?: string;//acctId is optional. It should be one of the accounts returned by /iserver/accounts. If not passed, the first one in the list is selected.

    conid?: number; //conid is the identifier of the security you want to trade, you can find the conid with /iserver/secdef/search.

    secType?: 'STK';

    cOID?: string;//Customer Order ID. An arbitraty string that can be used to identify the order, e.g "my-fb-order". The value must be unique for a 24h span. Please do not set this value for child orders when placing a bracket order.

    parentId?: string; //When placing bracket orders, the child parentId must be equal to the cOId (customer order id) of the parent.

    orderType?: IBKROrderType;

    listingExchange?: string;

    outsideRTH?: boolean; //set to true if the order can be executed outside regular trading hours.
    price?: number; //optional if order is MKT, for LMT, this is the limit price. For STP this is the stop price.
    auxPrice?: number | string;

    side?: 'SELL' | 'BUY';
    ticker?: string;
    tif?: 'GTC' | 'DAY'; //GTC (Good Till Cancel) or DAY. DAY orders are automatically cancelled at the end of the Day or Trading hours.

    referrer?: string; //for example QuickTrade
    quantity?: number; //usually integer, for some special cases can be float numbers

    useAdaptive?: boolean; //If true, the system will use the Adaptive Algo to submit the order https://www.interactivebrokers.com/en/index.php?f=19091
}

export interface IBKROrderModify {
    acctId: string;
    conid: number;
    orderId: number; //customer orderid
    orderType: string; //for example LMT
    outsideRTH: boolean;
    price: number;
    auxPrice: number;
    side: 'SELL' | 'BUY'
    listingExchange: string; //optional, not required
    ticker: string;
    tif: string; //for example DAY
    quantity: number; //usually integer, for some special cases can be float numbers
}

export interface IBKRConfig {
    gatewayURL?: string;
    RUnit?: number;
    RTarget?: number;
}

export type IBKROrderType = 'Market' | 'Limit' | 'Stop' | 'Stop Limit'; //orderType can be one of MKT (Market), LMT (Limit), STP (Stop) or STP_LIMIT (Stop Limit)