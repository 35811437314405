import moment from 'moment';

/* Actions */
import { ResetDataStoredAction, GetAccountsAction, GetPositionsAction, PositionsSetLoaderAction, SearchTickerAction, SearchTickerSetLoaderAction, SetSessionAction, LogoutSessionAction, SelectStockAction, DeselectStockAction, SessionSetLoaderAction, OrdersSetLoaderAction, GetOrdersAction, SetConfigParamsAction } from "./ibkr_actions"

/* Constants */
import * as ACTION_NAMES from "../action_names"

/* State */
import { initialState, IBKRState } from "./ibkr_states"

type Actions = SetConfigParamsAction | SessionSetLoaderAction | SelectStockAction | DeselectStockAction | LogoutSessionAction | SetSessionAction | GetAccountsAction | SearchTickerAction | SearchTickerSetLoaderAction | PositionsSetLoaderAction | GetPositionsAction | ResetDataStoredAction | OrdersSetLoaderAction | GetOrdersAction;

function tradingReducer( state = initialState, action: Actions ) {

    switch ( action.type ) {

        case ACTION_NAMES.RESET_DATA_STORED: {
            return initialState;
        }

        case ACTION_NAMES.IBKR_SET_SESSION_LOADER: {
            return Object.assign( {}, state, {
                loadingSession: action.payload
            } as IBKRState )
        }

        case ACTION_NAMES.IBKR_SET_SESSION_ID: {
            return Object.assign( {}, state, {
                sessionId: action.payload
            } as IBKRState )
        }

        case ACTION_NAMES.IBKR_TICKER_SEARCH_SET_LOADER: {
            return Object.assign( {}, state, {
                tickerSearchLoading: action.payload
            } as IBKRState )
        }

        case ACTION_NAMES.IBKR_TICKER_SEARCH_RECEIVED: {
            return Object.assign( {}, state, {
                tickerSearchResults: action.payload
            } as IBKRState )
        }

        case ACTION_NAMES.IBKR_POSITIONS_SET_LOADER: {
            return Object.assign( {}, state, {
                positionsLoading: action.payload
            } as IBKRState )
        }

        case ACTION_NAMES.IBKR_POSITIONS_RECEIVED: {
            return Object.assign( {}, state, {
                positions: action.payload
            } as IBKRState )
        }

        case ACTION_NAMES.IBKR_ACCOUNTS_RECEIVED: {
            return Object.assign( {}, state, {
                accounts: action.payload.accounts,
                selectedAccount: action.payload.selectedAccount
            } as IBKRState )
        }

        case ACTION_NAMES.IBKR_SELECT_STOCK: {
            return Object.assign( {}, state, {
                selectedStocks: state.selectedStocks.concat( action.payload ),
                tickerSearchResults: []
            } as IBKRState )
        }

        case ACTION_NAMES.IBKR_DESELECT_STOCK: {
            return Object.assign( {}, state, {
                selectedStocks: state.selectedStocks.filter( item => item.conid !== action.payload.conid )
            } as IBKRState )
        }

        case ACTION_NAMES.IBKR_LOGOUT_SESSION: {
            return initialState;
        }

        case ACTION_NAMES.IBKR_ORDERS_SET_LOADER: {
            return Object.assign( {}, state, {
                ordersLoading: action.payload
            } as IBKRState )
        }

        case ACTION_NAMES.IBKR_ORDERS_RECEIVED: {
            return Object.assign( {}, state, {
                orders: action.payload
            } as IBKRState )
        }

        case ACTION_NAMES.IBKR_SET_CONFIG_PARAMS: {
            return Object.assign( {}, state, {
                config: action.payload
            } as IBKRState )
        }

        default:
            return state;
    }
};

export default tradingReducer;