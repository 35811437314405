/*Utils*/
import { Action, createAction } from "../../utils/action-type-helper"

import { Dispatch } from "redux"

/*Constant*/
import * as ACTION_NAMES from "../action_names";

/*Models*/
import { PersistRootState, PayloadInvestment, PayloadLoan } from "../../models/index"

export type InvestmentMainImportReceivedAction = Action<typeof ACTION_NAMES.MAIN_IMPORT_RECEIVED, PersistRootState>;
export function mainImportReceived( payload: PersistRootState ) {
    return ( dispatch: Dispatch ) => {
        dispatch( createAction( ACTION_NAMES.MAIN_IMPORT_RECEIVED, payload ) );
    };
};

export type InvestmentResetDataStoredAction = Action<typeof ACTION_NAMES.RESET_DATA_STORED, PersistRootState>;
export function resetDataStored() {
    return createAction( ACTION_NAMES.RESET_DATA_STORED, null );
};

export type InvestmentSetIterationsAction = Action<typeof ACTION_NAMES.INVESTMENT_SET_ITERATIONS_INFORMATION, PayloadInvestment>;
export function setIterationsInformation( payload: PayloadInvestment ) {
    return createAction( ACTION_NAMES.INVESTMENT_SET_ITERATIONS_INFORMATION, payload );
};

export type InvestmentResetIterationsAction = Action<typeof ACTION_NAMES.INVESTMENT_RESET_ITERATIONS_INFORMATION, null>;
export function resetIterationsInformation() {
    return createAction( ACTION_NAMES.INVESTMENT_RESET_ITERATIONS_INFORMATION, null );
};

export type LoanSetIterationsAction = Action<typeof ACTION_NAMES.LOAN_SET_ITERATIONS_INFORMATION, PayloadLoan>;
export function setLoanIterationsInformation( payload: PayloadLoan ) {
    return createAction( ACTION_NAMES.LOAN_SET_ITERATIONS_INFORMATION, payload );
};

export type LoanResetLoanIterationsInformation = Action<typeof ACTION_NAMES.LOAN_RESET_ITERATIONS_INFORMATION, null>;
export function resetLoanIterationsInformation() {
    return createAction( ACTION_NAMES.LOAN_RESET_ITERATIONS_INFORMATION, null );
};
