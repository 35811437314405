import React from 'react';
import { MapStateToProps, connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Button, notification, Tabs } from 'antd'
import {
    PlusOutlined
} from '@ant-design/icons';
import moment from "moment";
import * as uuid from 'uuid';

/*Actions*/
import { addTrade, editTrade, removeTrade, addPlannedTrade, editPlannedTrade, removePlannedTrade, editTradingPlan } from '../redux/trading/trading_actions'

/* Models */
import { Position, ReduxProps, Trade, TradesFilters } from '../models';
import { IRootState } from '../redux/store'

/*Components*/
import TradeForm from '../components/trading/trade_form'
import TradesView from '../components/trading/trades_view'
import TradingPlanView from '../components/trading/trading_plan_view'
import TradingReport from '../components/trading/trades_report'
import TradesFiltersView from '../components/trading/trades_filters'

export interface Props extends ReduxProps {
    trades: Trade[]
    plannedTrades: Trade[]
    tradingPlan: string
}

export interface State {
    create_edit_mode: boolean;
    active_key: string;
    tableView: boolean;
    filters: TradesFilters;
}

class Trading extends React.Component<Props, State>{
    public static select: MapStateToProps<any, any, IRootState> = ( state: IRootState ) => {
        return {
            trades: state.trading.trades,
            plannedTrades: state.trading.plannedTrades,
            tradingPlan: state.trading.tradingPlan
        } as Props
    }

    public newTrade = {};

    constructor( props: Props ) {
        super( props )

        this.state = {
            create_edit_mode: false,
            active_key: "planned_trades",
            tableView: window.innerWidth > 1440,
            filters: null
        }
    }

    render() {

        const { plannedTrades, tradingPlan } = this.props
        const { create_edit_mode, active_key, tableView } = this.state

        if ( create_edit_mode ) {
            return (
                <TradeForm trade={this.newTrade as Trade} handleCancel={this.handleCancel} handleCreate={this.handleCreate} ></TradeForm>
            )
        }

        const filteredTrades = this.getFilteredTrades()

        return (
            <div>
                <Tabs activeKey={active_key} centered onChange={this.handleTabChange}>
                    <Tabs.TabPane tab="Plan" key="plan">
                        <TradingPlanView plan={tradingPlan} onChangeTradingPlan={this.onChangeTradingPlan}></TradingPlanView>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Planned Trades" key="planned_trades">
                        <TradesView
                            trades={plannedTrades}
                            plannedTrades={true}
                            openEditTradeView={this.openEditTradeView}
                            handleRemoveTrade={this.handleRemoveTrade}
                            addTradeButton={this.addTradeButton}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Trades" key="trades">
                        <>
                            <TradesFiltersView filters={this.state.filters} setFilters={this.setFilters} />
                            <TradesView
                                trades={filteredTrades}
                                plannedTrades={false}
                                tableView={tableView}
                                setTableView={this.setTableView}
                                openEditTradeView={this.openEditTradeView}
                                handleRemoveTrade={this.handleRemoveTrade}
                                addTradeButton={this.addTradeButton}
                                totalTradesCount={this.props.trades.length}
                            />
                            <div className="mgt-20">
                                <Button size="small" onClick={this.launchPrefillProcess}>Launch IBKR Prefiller</Button>
                            </div>
                        </>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Report" key="report">
                        <>
                            <TradesFiltersView filters={this.state.filters} setFilters={this.setFilters} />
                            <TradingReport
                                trades={filteredTrades}
                                totalTradesCount={this.props.trades.length}
                            />
                        </>
                    </Tabs.TabPane>
                </Tabs>
            </div>
        )
    }

    private onChangeTradingPlan = ( htmlTradingPlan: string ) => {
        this.props.dispatch( editTradingPlan( htmlTradingPlan ) )
    }

    private openEditTradeView = ( trade: Trade ) => {
        this.newTrade = trade;
        this.setState( { create_edit_mode: true } )
    }

    private addTradeButton = ( planned_Trade: boolean ) => {
        this.newTrade = {
            id: "new",
            planned_trade: planned_Trade,
            ticker: "",
            position: "long",
            start_at: moment(),
            end_at: moment(),
            entry_price: 0,
            entry_qty: 0,
            stop_price: 0,
            exit_price: 0,
            exit_qty: 0,
            fees: 0,
            entry_price_2: 0,
            entry_qty_2: 0,
            exit_price_2: 0,
            exit_qty_2: 0,
            entry_price_3: 0,
            entry_qty_3: 0,
            exit_price_3: 0,
            exit_qty_3: 0
        }
        this.setState( { create_edit_mode: true } )
    }

    private handleCancel = () => {
        this.setState( { create_edit_mode: false } )
    }

    private handleRemoveTrade = ( id: string, planned_trade: boolean ) => {
        if ( planned_trade )
            this.props.dispatch( removePlannedTrade( id ) )
        else
            this.props.dispatch( removeTrade( id ) )
    }

    private handleCreate = ( value: Trade ) => {
        let currentTrade: Trade = this.newTrade as Trade;
        if ( currentTrade.ticker !== "" ) {
            if ( currentTrade.planned_trade && !value.planned_trade ) {
                this.props.dispatch( removePlannedTrade( value.id ) )
                this.props.dispatch( addTrade( value ) )
            }
            else if ( !currentTrade.planned_trade && value.planned_trade ) {
                this.props.dispatch( removeTrade( value.id ) )
                this.props.dispatch( addPlannedTrade( value ) )
            }
            else {
                if ( value.planned_trade )
                    this.props.dispatch( editPlannedTrade( value ) )
                else
                    this.props.dispatch( editTrade( value ) )
            }
        }
        else {
            if ( value.planned_trade )
                this.props.dispatch( addPlannedTrade( value ) )
            else
                this.props.dispatch( addTrade( value ) )
        }

        this.setState( { create_edit_mode: false } )
    }

    private setTableView = ( value: boolean ) => {
        this.setState( { tableView: !value } )
    }

    private handleTabChange = ( activeKey: string ) => {
        this.setState( {
            active_key: activeKey
        } )
    }

    private setFilters = ( filters: TradesFilters ) => {
        this.setState( {
            filters
        } )
    }

    private getFilteredTrades = () => {

        const { trades } = this.props
        const { filters } = this.state

        if ( filters ) {

            return trades.filter( trade => {

                if ( filters.ticker && trade.ticker.indexOf( filters.ticker.toUpperCase() ) === -1 ) {
                    return false
                }

                if ( filters.start_at && trade.start_at && ( moment( trade.start_at ).isBefore( moment( filters.start_at ).startOf( 'day' ) ) ) ) {
                    return false
                }

                if ( filters.end_at && trade.end_at && ( moment( trade.end_at ).isAfter( moment( filters.end_at ).endOf( 'day' ) ) ) ) {
                    return false
                }

                if ( filters.position && ( filters.position !== trade.position ) ) {
                    return false
                }

                if ( filters.time_frame && filters.time_frame.length && ( filters.time_frame.indexOf( trade.time_frame ) === -1 ) ) {
                    return false
                }

                if ( filters.min_risk && ( trade.risk < filters.min_risk ) ) {
                    return false
                }

                if ( filters.min_duration && ( trade.duration < filters.min_duration * 60 ) ) {
                    return false
                }

                if ( filters.max_duration && ( trade.duration > filters.max_duration * 60 ) ) {
                    return false
                }

                return true

            } )

        } else {
            return trades
        }


    }

    private launchPrefillProcess = () => {

        const data: {
            'Comm/Fee': number;
            'Date/Time': string;
            'Quantity': number;
            'Symbol': string;
            'T. Price': number;
        }[] = ( window as any ).IBKR_PREFILL_DATA

        const RUnit = ( window as any ).IBKR_PREFILL_R_UNIT

        if ( !data || !data.length || !RUnit ) {
            notification.error( {
                message: 'Please provide IBKR data as a global variable (IBKR_PREFILL_DATA & IBKR_PREFILL_R_UNIT)'
            } )
            return
        }

        let inError = false

        let transactions: Trade[] = []

        let transactionIdx = 0

        for ( let i = 0; i < data.length; i++ ) {

            const transaction = data[i]

            if ( transactions[transactionIdx] ) {

                let trade = transactions[transactionIdx]

                console.log( trade, transaction )

                if ( transaction.Symbol !== trade.ticker ) {
                    notification.error( { message: 'PREFILLER DATA ERROR, check row ' + i } )
                    inError = true
                    break;
                } else {

                    if ( trade.entry_qty + transaction.Quantity === 0 ) {

                        trade.end_at = moment( transaction['Date/Time'].replace( ', ', 'T' ) ).add( 6, 'hours' ).toISOString()
                        trade.entry_qty = Math.abs( trade.entry_qty )
                        trade.exit_qty = Math.abs( transaction.Quantity )
                        trade.fees = Math.round( ( trade.fees + Math.abs( transaction['Comm/Fee'] ) ) * 100 ) / 100
                        trade.stop_price = this.getTheoreticalStopPrice( RUnit, trade.entry_qty, trade.position, trade.entry_price )
                        trade.exit_price = Math.round( transaction['T. Price'] * 100 ) / 100

                        transactionIdx += 1

                    } else {
                        notification.error( { message: 'PREFILLER DATA ERROR, check row ' + i } )
                        inError = true
                        break;
                    }

                }

            } else {

                let trade = {
                    id: uuid.v4(),
                    planned_trade: false,
                    ticker: transaction.Symbol,
                    position: transaction.Quantity < 0 ? Position.Short : Position.Long,
                    start_at: moment( transaction['Date/Time'].replace( ', ', 'T' ) ).add( 6, 'hours' ).toISOString(),
                    end_at: moment( transaction['Date/Time'].replace( ', ', 'T' ) ).add( 6, 'hours' ).toISOString(),
                    entry_price: Math.round( transaction['T. Price'] * 100 ) / 100,
                    entry_qty: transaction.Quantity,
                    stop_price: 0,
                    exit_price: 0,
                    exit_qty: 0,
                    fees: Math.abs( transaction['Comm/Fee'] ),
                    entry_price_2: 0,
                    entry_qty_2: 0,
                    exit_price_2: 0,
                    exit_qty_2: 0,
                    entry_price_3: 0,
                    entry_qty_3: 0,
                    exit_price_3: 0,
                    exit_qty_3: 0
                }

                transactions.push( trade )

            }

        }

        if ( !inError ) {
            transactions.forEach( trade => {
                this.props.dispatch( addTrade( trade ) )
            } )
        }

    }

    private getTheoreticalStopPrice = ( R: number, size: number, position: Position, entry: number ) => {

        const stopSize = R / size

        if ( position === Position.Short ) {
            return Math.round( ( entry + stopSize ) * 100 ) / 100
        } else {
            return Math.round( ( entry - stopSize ) * 100 ) / 100
        }

    }

}

export default injectIntl( connect( Trading.select )( Trading as any ) );
