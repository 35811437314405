import * as React from 'react'
import moment from 'moment';
import * as uuid from 'uuid';
import { Form, Input, Button, Select, Row, Col, DatePicker, TimePicker, Space, InputNumber, Switch, Divider, Collapse, Tooltip } from 'antd';
import { FormInstance } from 'antd/lib/form';
import {
    ArrowLeftOutlined
} from '@ant-design/icons';

/*Models*/
import { Trade, Setup, Position, TimeFrame } from '../../models/index'

//utils
import { DATE_TIME_FORMAT, getTradeExitTargetPrice, TARGET_COLOR } from '../../utils/helpers';

const { Option } = Select;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

export interface Props {
    trade: Trade;
    handleCancel: () => void;
    handleCreate: ( trade: Trade ) => void;
}

export interface State {
    editedTrade: Trade;
    rCalculatorValue?: number;
}

class TradeForm extends React.Component<Props, State> {
    private formRef: any = React.createRef<FormInstance>();

    constructor( props: Props ) {
        super( props )

        this.state = {
            editedTrade: this.props.trade,
            rCalculatorValue: undefined
        }
    }

    onFinish = ( values: Trade ) => {
        const { editedTrade } = this.state
        let result: Trade = { ...editedTrade, ...values };

        if ( result.id === "new" )
            result.id = uuid.v4()

        if ( result.start_at ) {
            result.start_at = moment( this.state.editedTrade.start_at ).toISOString();
        }

        if ( result.end_at ) {
            result.end_at = moment( this.state.editedTrade.end_at ).toISOString();
        }

        /*Calculated Values*/
        result.qty = this.calculateQuantity( result.entry_qty, result.entry_qty_2, result.entry_qty_3 );
        result.avg_entry_price = this.calculate_avg_price( result.entry_qty, result.entry_price, result.entry_qty_2, result.entry_price_2, result.entry_qty_3, result.entry_price_3, result.qty );
        result.avg_exit_price = this.calculate_avg_price( result.exit_qty, result.exit_price, result.exit_qty_2, result.exit_price_2, result.exit_qty_3, result.exit_price_3, result.qty );
        result.stop_size = this.calculate_stop_size( result.stop_price, result.avg_entry_price );
        result.risk = this.calculate_risk( result.stop_size, result.qty );

        result.gross_PL = this.calculate_gross_PL( result.avg_exit_price, result.avg_entry_price, result.qty, result.position );
        result.net_PL = this.calculate_net_PL( result.gross_PL, result.fees );
        result.gross_R = this.calculate_gross_R( result.gross_PL, result.risk );
        result.net_R = this.calculate_net_R( result.net_PL, result.risk );

        if ( result.start_at !== undefined && result.end_at !== undefined )
            result.duration = this.calculate_duration( result.start_at, result.end_at );

        this.props.handleCreate( result );
    };

    onReset = () => {
        this.formRef.current.resetFields();
    };

    cancel = () => {
        this.props.handleCancel();
    }

    private handleChange = ( value: any, name: string ) => {

        const { editedTrade } = this.state

        this.setState( {
            editedTrade: Object.assign( {}, editedTrade, {
                [name]: value
            } )
        } )

    }

    private calculate_duration( start_at: string, end_at: string ): number {
        return moment( end_at ).diff( moment( start_at ), "second" );
    }

    private calculateQuantity( entry_qty: number, entry_qty_2: number, entry_qty_3: number ): number {
        return entry_qty + entry_qty_2 + entry_qty_3;
    }

    private calculate_avg_price( e_qty: number, e_price: number, e_qty_2: number, e_price_2: number, e_qty_3: number, e_price_3: number, qty: number ): number {
        return ( ( e_qty * e_price ) + ( e_qty_2 * e_price_2 ) + ( e_qty_3 * e_price_3 ) ) / qty;
    }

    private calculate_stop_size( stop_price: number, avg_entry_price: number ): number {
        let value = Math.abs( stop_price - avg_entry_price ).toFixed( 2 );
        return parseFloat( value );
    }

    private calculate_risk( stop_size: number, qty: number ): number {
        return ( stop_size * qty );
    }

    private calculate_gross_PL( avg_exit_price: number, avg_entry_price: number, qty: number, position: string ) {
        let result = ( avg_exit_price - avg_entry_price ) * qty;

        if ( position === "short" )
            result = result * ( -1 );

        return parseFloat( result.toFixed( 2 ) );
    }

    private calculate_net_PL( gross_PL: number, fees: number ) {
        return ( gross_PL - fees );
    }

    private calculate_gross_R( gross_PL: number, risk: number ) {
        return ( gross_PL / risk );
    }

    private calculate_net_R( net_PL: number, risk: number ) {
        return ( net_PL / risk );
    }

    render() {
        const { editedTrade } = this.state

        const Picker: any = DatePicker

        return (
            <div>

                <Row className="mgb-10" justify="space-between">
                    <h2>Trade</h2>
                    <Button type="text" icon={<ArrowLeftOutlined />} onClick={this.cancel} />
                </Row>

                <Form layout="vertical" onFinish={this.onFinish} initialValues={editedTrade}>

                    <Row gutter={40}>
                        <Col>
                            <Form.Item name="ticker" label="Ticker" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item name="position" label="Position" >
                                <Select
                                    placeholder="Select"
                                    allowClear>
                                    {Object.values( Position ).map( value =>
                                        <Option
                                            key={value}
                                            value={value}>
                                            {value.toUpperCase()}
                                        </Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item name="planned_trade" label="Planned trade">
                                <Switch checked={editedTrade.planned_trade} onChange={checked => this.handleChange( checked, 'planned_trade' )} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={20}>
                        <Col xs={24} md={12}>
                            <Divider>Entry</Divider>
                            <Form.Item name="start_at" label="Date & time">
                                <Space>
                                    <Picker defaultValue={moment( editedTrade.start_at )} showTime={true} format={DATE_TIME_FORMAT} onChange={( value: any ) => this.handleChange( value, 'start_at' )} />
                                </Space>
                            </Form.Item>
                            <Row gutter={40}>
                                <Col>
                                    <Form.Item name="entry_qty" label="Entry quantity">
                                        <InputNumber />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item name="entry_price" label="Entry price">
                                        <InputNumber />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item name="stop_price" label="Stop price">
                                <InputNumber />
                            </Form.Item>
                        </Col>

                        {
                            editedTrade.planned_trade ? null : (
                                <Col xs={24} md={12}>
                                    <Divider>Exit</Divider>
                                    <Form.Item name="end_at" label="Date & time" >
                                        <Space>
                                            <Picker defaultValue={moment( editedTrade.end_at )} showTime={true} format={DATE_TIME_FORMAT} onChange={( value: any ) => this.handleChange( value, 'end_at' )} />
                                        </Space>
                                    </Form.Item>
                                    <Row gutter={40}>
                                        <Col>
                                            <Form.Item name="exit_qty" label="Exit quantity" >
                                                <InputNumber />
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Form.Item name="exit_price" label="Exit price">
                                                <InputNumber />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Form.Item name="fees" label="Fees">
                                        <InputNumber />
                                    </Form.Item>
                                </Col>
                            )
                        }

                    </Row>

                    {
                        editedTrade.planned_trade ? null : (
                            <Collapse bordered={false} className="mgt-10 mgb-20">
                                <Collapse.Panel header="Partial lots" key="1">
                                    <Row gutter={20}>
                                        <Col span={12}>
                                            <Divider>Entry #2</Divider>
                                            <Row gutter={40}>
                                                <Col>
                                                    <Form.Item name="entry_qty_2" label="Entry quantity #2">
                                                        <InputNumber />
                                                    </Form.Item>
                                                </Col>
                                                <Col>
                                                    <Form.Item name="entry_price_2" label="Entry price #2">
                                                        <InputNumber />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col span={12}>
                                            <Divider>Entry #3</Divider>
                                            <Row gutter={40}>
                                                <Col>
                                                    <Form.Item name="entry_qty_3" label="Entry quantity #3">
                                                        <InputNumber />
                                                    </Form.Item>
                                                </Col>
                                                <Col>
                                                    <Form.Item name="entry_price_3" label="Entry price #3">
                                                        <InputNumber />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row gutter={20}>
                                        <Col span={12}>
                                            <Divider>Exit #2</Divider>
                                            <Row gutter={40}>
                                                <Col>
                                                    <Form.Item name="exit_qty_2" label="Exit quantity #2">
                                                        <InputNumber />
                                                    </Form.Item>
                                                </Col>
                                                <Col>
                                                    <Form.Item name="exit_price_2" label="Exit price #2">
                                                        <InputNumber />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col span={12}>
                                            <Divider>Exit 3</Divider>
                                            <Row gutter={40}>
                                                <Col>
                                                    <Form.Item name="exit_qty_3" label="Exit quantity #3">
                                                        <InputNumber />
                                                    </Form.Item>
                                                </Col>
                                                <Col>
                                                    <Form.Item name="exit_price_3" label="Exit price #3">
                                                        <InputNumber />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Collapse.Panel>
                            </Collapse>
                        )
                    }

                    <Divider>Setup</Divider>

                    <Row gutter={40}>

                        <Col xs={12} md={6}>
                            <Form.Item name="setup" label="Setup type">
                                <Select
                                    placeholder="Select"
                                    allowClear>
                                    {Object.values( Setup ).map( value =>
                                        <Option
                                            key={value}
                                            value={value}>
                                            {value}
                                        </Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={12} md={6}>
                            <Form.Item name="time_frame" label="Time frame">
                                <Select
                                    placeholder="Select"
                                    allowClear>
                                    {Object.values( TimeFrame ).map( value =>
                                        <Option
                                            key={value}
                                            value={value}>
                                            {value}
                                        </Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col>
                            <Form.Item name="with_daily" label="With daily">
                                <Switch checked={editedTrade.with_daily} onChange={checked => this.handleChange( checked, 'with_daily' )} />
                            </Form.Item>
                        </Col>

                        <Col>
                            <Form.Item name="with_mkt" label="With MKT" >
                                <Switch checked={editedTrade.with_mkt} onChange={checked => this.handleChange( checked, 'with_mkt' )} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item name="comments" label="Comments" >
                                <Input.TextArea rows={3} />
                            </Form.Item>
                        </Col>

                    </Row>

                    {
                        !editedTrade.planned_trade && (
                            <>
                                <Divider>Trade management tracking</Divider>

                                <Row gutter={40}>
                                    <Col>
                                        <Form.Item name="bar_by_bar_after_1R" label="Bar by bar after 1R">
                                            <InputNumber />
                                        </Form.Item>

                                    </Col>
                                    <Col>
                                        <Form.Item name="stop_to_BE_at_1R_tgt_2R" label="Stop to BE @1R (2R TGT)">
                                            <InputNumber />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Tooltip title={getTradeExitTargetPrice( editedTrade, 1 )}>
                                            <Form.Item name="AON_1_R" label="AON 1R">
                                                <InputNumber />
                                            </Form.Item>
                                        </Tooltip>
                                    </Col>
                                    <Col>
                                        <Tooltip title={getTradeExitTargetPrice( editedTrade, 1.5 )}>
                                            <Form.Item name="AON_1_5_R" label="AON 1.5R">
                                                <InputNumber />
                                            </Form.Item>
                                        </Tooltip>
                                    </Col>
                                    <Col>
                                        <Tooltip title={getTradeExitTargetPrice( editedTrade, 2 )}>
                                            <Form.Item name="AON_2_R" label="AON 2R">
                                                <InputNumber />
                                            </Form.Item>
                                        </Tooltip>
                                    </Col>
                                    <Col>
                                        <Tooltip title={getTradeExitTargetPrice( editedTrade, 3 )}>
                                            <Form.Item name="AON_3_R" label="AON 3R">
                                                <InputNumber />
                                            </Form.Item>
                                        </Tooltip>
                                    </Col>
                                    <Col>
                                        {this.getRCalculator()}
                                    </Col>
                                </Row>

                            </>
                        )
                    }

                    <Row justify="end" className="mgt-20">
                        <Form.Item>
                            <Space>
                                <Button type="primary" htmlType="submit">Enregistrer</Button>
                                <Button htmlType="button" onClick={this.cancel}>Annuler</Button>
                            </Space>
                        </Form.Item>
                    </Row>
                </Form>

            </div>
        );
    }

    private getRCalculator = () => {

        const { trade } = this.props

        if ( trade && trade.avg_entry_price && trade.stop_size ) {
            return (
                <div className="light-bg" style={{ padding: '8px 20px', marginTop: 16 }}>
                    <label className="mgr-15">R Calculator</label>
                    <InputNumber value={this.state.rCalculatorValue} onChange={value => this.setState( { rCalculatorValue: value as number } )} placeholder="Exit price" formatter={value => `${ value } $`} />
                    <strong className="mgl-15" style={{ color: TARGET_COLOR }}>{this.state.rCalculatorValue ? ( ( this.state.rCalculatorValue - trade.avg_entry_price ) / trade.stop_size * ( trade.position === Position.Short ? -1 : 1 ) ).toFixed( 2 ) : '-'}</strong>
                </div>
            )
        }

        return null
    }
}

export default TradeForm;
