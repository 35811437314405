
export const MAIN_IMPORT_RECEIVED = "MAIN_IMPORT_RECEIVED";
export const RESET_DATA_STORED = "RESET_DATA_STORED";

export const PRODUCT_COST_ADD_PRODUCT = "PRODUCT_COST_ADD_PRODUCT";
export const PRODUCT_COST_EDIT_PRODUCT_NAME = "PRODUCT_COST_EDIT_PRODUCT_NAME";
export const PRODUCT_COST_DELETE_PRODUCT = "PRODUCT_COST_DELETE_PRODUCT";
export const PRODUCT_COST_ADD_PRODUCT_ITEM = "PRODUCT_COST_ADD_PRODUCT_ITEM";
export const PRODUCT_COST_EDIT_PRODUCT_ITEM = "PRODUCT_COST_EDIT_PRODUCT_ITEM";
export const PRODUCT_COST_DELETE_PRODUCT_ITEM = "PRODUCT_COST_DELETE_PRODUCT_ITEM";
export const PRODUCT_COST_SET_POTENTIAL_PRICE = "PRODUCT_COST_SET_POTENTIAL_PRICE";
export const PRODUCT_COST_SET_TOTAL_ITEMS = "PRODUCT_COST_SET_TOTAL_ITEMS";

export const INCOMES_UPDATE_ENTRY = "INCOMES_UPDATE_ENTRY";
export const EXPENSES_UPDATE_ENTRY = "EXPENSES_UPDATE_ENTRY";
export const TAXES_UPDATE_ENTRY = "TAXES_UPDATE_ENTRY";
export const BUDGET_DELETE_ENTRY = "BUDGET_DELETE_ENTRY";
export const BUDGET_ADD_ENTRY = "BUDGET_ADD_ENTRY";

export const TRADING_ADD_TRADE = "TRADING_ADD_TRADE";
export const TRADING_ADD_PLANNED_TRADE = "TRADING_ADD_PLANNED_TRADE";
export const TRADING_EDIT_TRADE = "TRADING_EDIT_TRADE";
export const TRADING_EDIT_PLANNED_TRADE = "TRADING_EDIT_PLANNED_TRADE";
export const TRADING_EDIT_TRADING_PLAN = "TRADING_EDIT_TRADING_PLAN";
export const TRADING_REMOVE_TRADE = "TRADING_REMOVE_TRADE";
export const TRADING_REMOVE_PLANNED_TRADE = "TRADING_REMOVE_PLANNED_TRADE";
export const TRADING_STORE_TICKER_LOGO = "TRADING_STORE_TICKER_LOGO";

export const INVESTMENT_SET_ITERATIONS_INFORMATION = "INVESTMENT_SET_ITERATIONS_INFORMATION";
export const INVESTMENT_RESET_ITERATIONS_INFORMATION = "INVESTMENT_RESET_ITERATIONS_INFORMATION";
export const LOAN_SET_ITERATIONS_INFORMATION = "LOAN_SET_ITERATIONS_INFORMATION";
export const LOAN_RESET_ITERATIONS_INFORMATION = "LOAN_RESET_ITERATIONS_INFORMATION";

export const IBKR_SET_CONFIG_PARAMS = "IBKR_SET_CONFIG_PARAMS";
export const IBKR_SET_SESSION_LOADER = "IBKR_SET_SESSION_LOADER";
export const IBKR_SET_SESSION_ID = "IBKR_SET_SESSION_ID";
export const IBKR_LOGOUT_SESSION = "IBKR_LOGOUT_SESSION";
export const IBKR_ACCOUNTS_RECEIVED = "IBKR_ACCOUNTS_RECEIVED";
export const IBKR_TICKER_SEARCH_SET_LOADER = "IBKR_TICKER_SEARCH_SET_LOADER";
export const IBKR_TICKER_SEARCH_RECEIVED = "IBKR_TICKER_SEARCH_RECEIVED";
export const IBKR_POSITIONS_SET_LOADER = "IBKR_POSITIONS_SET_LOADER";
export const IBKR_POSITIONS_RECEIVED = "IBKR_POSITIONS_RECEIVED";
export const IBKR_SELECT_STOCK = "IBKR_SELECT_STOCK";
export const IBKR_DESELECT_STOCK = "IBKR_DESELECT_STOCK";
export const IBKR_ORDERS_SET_LOADER = "IBKR_ORDERS_SET_LOADER";
export const IBKR_ORDERS_RECEIVED = "IBKR_ORDERS_RECEIVED";