import { BudgetEntry } from "../../models"

export const initialState: TaxesState = {
    taxes: [],
    taxesAmount: 0
};

export interface TaxesState {
    taxes: BudgetEntry[];
    taxesAmount: number
}

