import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer, PersistConfig } from 'redux-persist'
import localStorage from 'redux-persist/lib/storage' // defaults to localStorage for web

/*Stores Reducers*/
import productCostReducer from "./product_cost/product_cost_reducers"
import budgetReducer from "./budget/budget_reducers"
import tradingReducer from "./trading/trading_reducers"
import investmentReducer from "./investment/investment_reducers"
import taxesReducer from "./taxes/taxes_reducers"
import ibkrReducer from "./ibkr/ibkr_reducers"

/*Models*/
import { ProductsState } from "./product_cost/product_cost_states"
import { BudgetState } from "./budget/budget_states"
import { InvestmentState } from "./investment/investment_states"
import { TradingState } from "./trading/trading_states"
import { TaxesState } from "./taxes/taxes_states"
import { IBKRState } from "./ibkr/ibkr_states"

const persistConfig: PersistConfig<any, any, any, any> = {
    key: 'root',
    storage: localStorage,
    whitelist: ['productCost', 'budget', 'investment', 'trading', 'taxes', 'ibkr']
}

export interface IRootState {
    productCost: ProductsState,
    budget: BudgetState,
    investment: InvestmentState,
    trading: TradingState,
    taxes: TaxesState
    ibkr: IBKRState;
}

const persistedReducer = persistReducer( persistConfig, combineReducers( {
    productCost: productCostReducer,
    budget: budgetReducer,
    investment: investmentReducer,
    trading: tradingReducer,
    taxes: taxesReducer,
    ibkr: ibkrReducer
} ) )

const store = createStore<IRootState, any, any, any>( persistedReducer, applyMiddleware( thunk ) );

const persistore = persistStore( store );

export { store, persistore };