import React from 'react';
import { InputNumber, Row, Col, Input, Checkbox, Button, Form, Divider, Space, Slider, Tooltip } from 'antd';
import * as uuid from 'uuid';
import {
    MinusCircleOutlined, PlusOutlined
} from '@ant-design/icons';
import { FormListFieldData } from 'antd/lib/form/FormList';

/*Models*/
import { BudgetEntry, BudgetTypeEnum } from '../../models/index'

interface Props {
    entries: BudgetEntry[];
    entryType: BudgetTypeEnum;
    save: ( entries: BudgetEntry[] ) => void
}

interface State {
    entries: BudgetEntry[]
}

class EntryEditor extends React.Component<Props, State> {
    constructor( props: Props ) {
        super( props )

        this.state = this.props.entries.length > 0 ? {
            entries: this.props.entries
        } : {
            entries: [{
                id: uuid.v4(),
                name: "",
                value: 0,
                type: this.props.entryType,
                yearly: false,
                taxAllowance: 10,
                tax: 0
            }]
        }
    }

    private onChange = ( value: any, allValues: any ) => {

        let modifiedData = [...allValues.entries]
        //Manage remove line and add new line
        let removed = modifiedData.filter( ( item: BudgetEntry, index: number ) => {
            if ( item.id !== undefined ) {
                return item
            }
        } )

        this.props.save( removed )
    }

    render() {

        const { entries } = this.state
        const { entryType } = this.props

        return (
            <>
                <Form size="small" layout="inline" initialValues={{ entries }} onValuesChange={this.onChange} >

                    <Form.List name="entries">
                        {( fields, { add, remove } ) => (
                            <>
                                {fields.map( ( field ) =>
                                    <Space key={field.key} direction='vertical' className='entry-layout' >
                                        <Row gutter={[8, 8]}>
                                            <Col xs={12} sm={12} md={12} lg={entryType !== BudgetTypeEnum.TaxInput ? 10 : 7}>
                                                <Tooltip title="Type/nom du revenu : par exemple, salaire, revenu foncier, ..." placement="bottom">
                                                    <Form.Item name={[field.name, 'name']} fieldKey={[field.fieldKey, 'name']} rules={[{ required: true, message: 'Le nom est requis' }]}><Input placeholder="Salaire" /></Form.Item>
                                                </Tooltip>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={entryType !== BudgetTypeEnum.TaxInput ? 9 : 6}>
                                                <Tooltip title="Montant par mois par défaut, si annuel, cocher la case 'Annuel'." placement="bottom">
                                                    <Form.Item name={[field.name, 'value']} fieldKey={[field.fieldKey, 'value']} rules={[{ required: true, message: 'Le montant est requis' }]}><InputNumber min={0} /></Form.Item>
                                                </Tooltip>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={entryType !== BudgetTypeEnum.TaxInput ? 3 : 3}>  <Form.Item name={[field.name, 'yearly']} fieldKey={[field.fieldKey, 'yearly']} valuePropName="checked"><Checkbox >Annuel</Checkbox></Form.Item></Col>
                                            {
                                                entryType !== BudgetTypeEnum.TaxInput ? null :
                                                    ( <><Col xs={12} sm={12} md={12} lg={3}>  <Form.Item name={[field.name, 'tax']} fieldKey={[field.fieldKey, 'tax']} extra="% Charges" valuePropName="value">
                                                        <Slider
                                                            min={0}
                                                            max={100}
                                                            step={1}
                                                        /></Form.Item></Col>
                                                        <Col xs={12} sm={12} md={12} lg={3}>  <Form.Item name={[field.name, 'taxAllowance']} fieldKey={[field.fieldKey, 'taxAllowance']} extra={`% Abattement`} valuePropName="value">
                                                            <Slider
                                                                min={0}
                                                                max={100}
                                                                step={1}
                                                            /></Form.Item></Col>
                                                    </>
                                                    )}
                                            <Col xs={12} sm={12} md={12} lg={entryType !== BudgetTypeEnum.TaxInput ? 2 : 2}>  {fields.length > 1 ? ( <MinusCircleOutlined onClick={() => remove( field.name )} /> ) : null}</Col>
                                        </Row>
                                        <Divider className="mgt-5 mgb-10" />
                                    </Space>
                                )}


                                < Form.Item className="entry-layout">
                                    <Button
                                        className="entry-layout"
                                        type="dashed"

                                        onClick={() => add( {
                                            id: uuid.v4(),
                                            name: "",
                                            value: 0,
                                            type: this.props.entryType,
                                            yearly: false,
                                            taxAllowance: 10,
                                            tax: 0
                                        } )}
                                        icon={<PlusOutlined />}
                                    >
                                        Ajouter
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>

                </Form>

            </>
        );
    }


}

export default EntryEditor;