/*Utils*/
import { Action, createAction } from "../../utils/action-type-helper"

import { Dispatch } from "redux"

/*Constant*/
import * as ACTION_NAMES from "../action_names";

/*Models*/
import { PersistRootState, PayloadProductDetail, PayloadNumber, PayloadString, ProductKey, Product } from "../../models/index"

export type ProductCostMainImportReceivedAction = Action<typeof ACTION_NAMES.MAIN_IMPORT_RECEIVED, PersistRootState>;
export function mainImportReceived( payload: PersistRootState ) {
    return ( dispatch: Dispatch ) => {
        dispatch( createAction( ACTION_NAMES.MAIN_IMPORT_RECEIVED, payload ) );
    };
};

export type ProductCostResetDataStoredAction = Action<typeof ACTION_NAMES.RESET_DATA_STORED, PersistRootState>;
export function resetDataStored() {
    return createAction( ACTION_NAMES.RESET_DATA_STORED, null );
};

export type ProductCostEditProductAction = Action<typeof ACTION_NAMES.PRODUCT_COST_EDIT_PRODUCT_NAME, PayloadString>;
export function editProductName( payload: PayloadString ) {
    return createAction( ACTION_NAMES.PRODUCT_COST_EDIT_PRODUCT_NAME, payload );
};

export type ProductCostAddProductAction = Action<typeof ACTION_NAMES.PRODUCT_COST_ADD_PRODUCT, Product>;
export function addProduct( payload: Product ) {
    return createAction( ACTION_NAMES.PRODUCT_COST_ADD_PRODUCT, payload );
};

export type ProductCostDeleteProductAction = Action<typeof ACTION_NAMES.PRODUCT_COST_DELETE_PRODUCT, ProductKey>;
export function deleteProduct( payload: ProductKey ) {
    return createAction( ACTION_NAMES.PRODUCT_COST_DELETE_PRODUCT, payload );
};

export type ProductCostAddProductItemAction = Action<typeof ACTION_NAMES.PRODUCT_COST_ADD_PRODUCT_ITEM, PayloadProductDetail>;
export function addProductItem( payload: PayloadProductDetail ): ProductCostAddProductItemAction {
    return createAction( ACTION_NAMES.PRODUCT_COST_ADD_PRODUCT_ITEM, payload );
};

export type ProductCostEditProductItemAction = Action<typeof ACTION_NAMES.PRODUCT_COST_EDIT_PRODUCT_ITEM, PayloadProductDetail>;
export function editProductItem( payload: PayloadProductDetail ): ProductCostEditProductItemAction {
    return createAction( ACTION_NAMES.PRODUCT_COST_EDIT_PRODUCT_ITEM, payload );
};

export type ProductCostDeleteProductItemAction = Action<typeof ACTION_NAMES.PRODUCT_COST_DELETE_PRODUCT_ITEM, PayloadProductDetail>;
export function deleteProductItem( payload: PayloadProductDetail ): ProductCostDeleteProductItemAction {
    return createAction( ACTION_NAMES.PRODUCT_COST_DELETE_PRODUCT_ITEM, payload );
};

export type ProductCostSetPotentialPriceAction = Action<typeof ACTION_NAMES.PRODUCT_COST_SET_POTENTIAL_PRICE, PayloadNumber>;
export function setProductPotentialPrice( payload: PayloadNumber ): ProductCostSetPotentialPriceAction {
    return createAction( ACTION_NAMES.PRODUCT_COST_SET_POTENTIAL_PRICE, payload );
};

export type ProductCostSetTotalItemsAction = Action<typeof ACTION_NAMES.PRODUCT_COST_SET_TOTAL_ITEMS, PayloadNumber>;
export function setProductTotalItems( payload: PayloadNumber ): ProductCostSetTotalItemsAction {
    return createAction( ACTION_NAMES.PRODUCT_COST_SET_TOTAL_ITEMS, payload );
};
