/* Actions */
import {
    ProductCostAddProductAction,
    ProductCostEditProductAction,
    ProductCostDeleteProductAction,
    ProductCostAddProductItemAction,
    ProductCostEditProductItemAction,
    ProductCostMainImportReceivedAction,
    ProductCostResetDataStoredAction,
    ProductCostSetPotentialPriceAction,
    ProductCostDeleteProductItemAction,
    ProductCostSetTotalItemsAction,
    addProductItem
} from "./product_cost_actions"
import { DefaultType } from '../../utils/action-type-helper'

/* Constants */
import * as ACTION_NAMES from "../action_names"

/* Models */
import { ProductDetail, Product } from "../../models"

/* State */
import { initialState, ProductsState } from "./product_cost_states"

/*Storage action*/
import { webStorage } from "../../utils/web_storage"

type Actions = ProductCostAddProductAction | ProductCostEditProductAction | ProductCostResetDataStoredAction | ProductCostDeleteProductAction | ProductCostAddProductItemAction | ProductCostMainImportReceivedAction | ProductCostEditProductItemAction | ProductCostSetPotentialPriceAction | ProductCostDeleteProductItemAction | ProductCostSetTotalItemsAction;

function productCostReducer( state = initialState, action: Actions ) {
    let stateToModify: Product[] = JSON.parse( JSON.stringify( state.products ) );
    let indexProductToModify = null;

    switch ( action.type ) {
        case ACTION_NAMES.MAIN_IMPORT_RECEIVED:
            let data = JSON.parse( action.payload.productCost ) as ProductsState;
            let productCostData = data ? data : state;
            return Object.assign( {}, state, productCostData );

        case ACTION_NAMES.RESET_DATA_STORED:
            webStorage.clearWebStorage();
            return initialState;

        case ACTION_NAMES.PRODUCT_COST_ADD_PRODUCT:

            stateToModify.push( action.payload );

            return Object.assign( {}, state, {
                products: stateToModify
            } );

        case ACTION_NAMES.PRODUCT_COST_EDIT_PRODUCT_NAME:
            indexProductToModify = stateToModify.findIndex( ( product: Product ) => product.id === action.payload.key );

            if ( indexProductToModify !== -1 )
                stateToModify[indexProductToModify].name = action.payload.value;

            return Object.assign( {}, state, {
                products: stateToModify
            } );

        case ACTION_NAMES.PRODUCT_COST_DELETE_PRODUCT:
            indexProductToModify = stateToModify.findIndex( ( product: Product ) => product.id === action.payload.key );

            if ( indexProductToModify !== -1 ) {
                stateToModify.splice( indexProductToModify, 1 );
            }

            return Object.assign( {}, state, {
                products: stateToModify
            } );

        case ACTION_NAMES.PRODUCT_COST_EDIT_PRODUCT_ITEM:
            indexProductToModify = stateToModify.findIndex( ( product: Product ) => product.id === action.payload.key );

            if ( indexProductToModify !== -1 ) {
                let indexToUpdate = stateToModify[indexProductToModify].product_items.findIndex( ( product: ProductDetail ) => product.id === ( action.payload.value as ProductDetail ).id );
                if ( indexToUpdate !== -1 )
                    stateToModify[indexProductToModify].product_items[indexToUpdate] = action.payload.value as ProductDetail;
            }

            return Object.assign( {}, state, {
                products: stateToModify
            } );


        case ACTION_NAMES.PRODUCT_COST_DELETE_PRODUCT_ITEM:
            indexProductToModify = stateToModify.findIndex( ( product: Product ) => product.id === action.payload.key );

            if ( indexProductToModify !== -1 ) {
                let indexToRemove = stateToModify[indexProductToModify].product_items.findIndex( ( product: ProductDetail ) => product.id === ( action.payload.value as ProductDetail ).id );
                if ( indexToRemove !== -1 )
                    stateToModify[indexProductToModify].product_items.splice( indexToRemove, 1 );
            }

            return Object.assign( {}, state, {
                products: stateToModify
            } );

        case ACTION_NAMES.PRODUCT_COST_ADD_PRODUCT_ITEM:
            indexProductToModify = stateToModify.findIndex( ( product: Product ) => product.id === action.payload.key );

            if ( indexProductToModify !== -1 )
                stateToModify[indexProductToModify].product_items.push( action.payload.value as ProductDetail );

            return Object.assign( {}, state, {
                products: stateToModify
            } );

        case ACTION_NAMES.PRODUCT_COST_SET_POTENTIAL_PRICE:
            indexProductToModify = stateToModify.findIndex( ( product: Product ) => product.id === action.payload.key );

            if ( indexProductToModify !== -1 )
                stateToModify[indexProductToModify].potential_cost = action.payload.value as number;

            return Object.assign( {}, state, {
                products: stateToModify
            } );

        case ACTION_NAMES.PRODUCT_COST_SET_TOTAL_ITEMS:
            indexProductToModify = stateToModify.findIndex( ( product: Product ) => product.id === action.payload.key );

            if ( indexProductToModify !== -1 )
                stateToModify[indexProductToModify].total_items = action.payload.value as number;

            return Object.assign( {}, state, {
                products: stateToModify
            } );

        default:
            return state;
    }
};

export default productCostReducer;