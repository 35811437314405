import { Trade } from "../../models"

export const initialState: TradingState = {
    trades: [],
    plannedTrades: [],
    tradingPlan: "",
    tickerLogos: {}
};

export interface TradingState {
    trades: Trade[];
    plannedTrades: Trade[];
    tradingPlan: string;

    tickerLogos: {
        [ticker: string]: string;//logo url
    }
}

