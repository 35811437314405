import React from 'react';
import { Row, Col, Button, Divider, Empty } from 'antd';
import { EditOutlined, CheckOutlined } from '@ant-design/icons';

interface Props {
    plan: string
    onChangeTradingPlan: ( tradingPlan: string ) => void;
}

interface State {
    editorValue: string;
    visibleEditor: boolean;
}

class TradingPlanView extends React.Component<Props, State> {

    constructor( props: Props ) {
        super( props );
        this.state = {
            editorValue: props.plan || '',
            visibleEditor: false
        }
    }

    private onValidateTradingPlan = () => {
        this.props.onChangeTradingPlan( this.state.editorValue )
        this.setState( { visibleEditor: false } )
    }

    render() {
        const { editorValue, visibleEditor } = this.state;

        if ( visibleEditor ) {
            return (
                <div>
                    <Divider>Édition</Divider>
                    <textarea
                        style={{ width: '100%' }}
                        rows={10}
                        value={editorValue}
                        onChange={e => this.setState( { editorValue: e.target.value } )}
                    />
                    <Row className="mgt-20" gutter={[10, 10]} justify="center">
                        <Col>
                            <Button onClick={this.onValidateTradingPlan} type="dashed">
                                <CheckOutlined /> Valider les modifications
                         </Button>
                        </Col>
                    </Row>
                </div>
            );
        } else {
            return (
                <div>
                    {this.getPlanDisplay()}
                    <Row className="mgt-20" gutter={[10, 10]} justify="center">
                        <Col>
                            <Button onClick={() => this.setState( { visibleEditor: true } )} type="dashed"><EditOutlined /> Éditer</Button>
                        </Col>
                    </Row>
                </div>
            );
        }

    }

    private getPlanDisplay = () => {

        const { editorValue } = this.state

        if ( editorValue ) {

            if ( editorValue.indexOf( '<svg' ) !== -1 ) {
                return (
                    <img style={{ margin: 'auto', display: 'block' }} src={`data:image/svg+xml;utf8,${ encodeURIComponent( editorValue ) }`} alt="" />
                )
            } else {
                return (
                    <div>
                        {editorValue}
                    </div>
                )
            }

        }

        return <Empty></Empty>

    }

}

export default TradingPlanView;