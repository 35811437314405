import React from 'react';
import { Space, Form, InputNumber, Button } from 'antd';
import * as uuid from 'uuid';

/*Models*/
import { InvestmentConfiguration, InvestmentIteration } from '../../models/index'

interface Props {
    investment: InvestmentConfiguration
    setInvestment: ( investment: InvestmentConfiguration, investmentIteration: InvestmentIteration[] ) => void;
    resetInvestment: () => void;
}

interface State {
    editedInvestment: InvestmentConfiguration
}

class InvestmentForm extends React.Component<Props, State> {

    constructor( props: Props ) {
        super( props );

        this.state = {
            editedInvestment: this.props.investment
        }
    }


    render() {
        const { editedInvestment } = this.state

        return ( <div>
            <Form layout="inline" initialValues={editedInvestment} onFinish={this.calculateInvestment}>
                <Form.Item label="Capital de départ" name="startingCapital">
                    <InputNumber />
                </Form.Item>
                <Form.Item label="Taux d'intérêt (%)" name="interestRate">
                    <InputNumber />
                </Form.Item>
                <Form.Item label="Itérations" name="iterations">
                    <InputNumber />
                </Form.Item>
                <Form.Item label="Frais par itération (%)" name="feesByIterationRate">
                    <InputNumber />
                </Form.Item>
                <Form.Item label="Frais par itération (fixes)" name="feesByIterationFixed">
                    <InputNumber />
                </Form.Item>
                <Form.Item label="Apport par itération" name="addedCapitalByIteration">
                    <InputNumber />
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button type="primary" htmlType="submit">Génerer</Button>
                        <Button htmlType="button" onClick={this.props.resetInvestment}>Réinitialiser</Button>
                    </Space>
                </Form.Item>
            </Form>
        </div> );
    };

    private calculateInvestment = ( values: InvestmentConfiguration ) => {
        let result: InvestmentIteration[] = [this.getInvestmentIteration( values.startingCapital, 1, values.feesByIterationRate, values.feesByIterationFixed, values.interestRate, values.addedCapitalByIteration )]

        for ( let i = 1; i < values.iterations; i++ ) {
            result.push( this.getInvestmentIteration( result[i - 1].capital + result[i - 1].netProfit + result[i - 1].addedCapitalByIteration, i + 1, values.feesByIterationRate, values.feesByIterationFixed, values.interestRate, values.addedCapitalByIteration ) )
        }

        this.props.setInvestment( values, result )
    }

    private getInvestmentIteration = ( capital: number, iteration: number, feesRate: number, feesFixed: number, interestRate: number, addedCapitalByIteration: number ): InvestmentIteration => {

        let feesAmount = ( capital * ( feesRate / 100 ) ) + feesFixed
        let grossProfit = capital * ( interestRate / 100 )

        return {
            capital,
            iteration,
            fees: feesAmount,
            grossProfit,
            netProfit: grossProfit - feesAmount,
            addedCapitalByIteration
        }
    }
}

export default InvestmentForm;