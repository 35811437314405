import React from 'react';

/*Utils*/
import { Action, createAction } from "../../utils/action-type-helper"
import { ENDPOINTS, handleError, post, get, del, showSuccess, put } from '../../utils/rest_api'

import { Dispatch } from "redux"
import { ThunkAction } from 'redux-thunk'

/*Constant*/
import * as ACTION_NAMES from "../action_names";

/*Models*/
import { IBKRPosition, IBKRTicker, PersistRootState, Trade, IBKROrder, IBKROrderRequest, IBKRConfig } from "../../models/index"

//utils
import { IRootState } from "../store";
import { webStorage } from "src/utils/web_storage";
import { Modal, notification } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';

export type ResetDataStoredAction = Action<typeof ACTION_NAMES.RESET_DATA_STORED, null>;

export type SearchTickerSetLoaderAction = Action<typeof ACTION_NAMES.IBKR_TICKER_SEARCH_SET_LOADER, boolean>;

function setSearchTickerLoader( loading: boolean ): SearchTickerSetLoaderAction {
    return {
        type: ACTION_NAMES.IBKR_TICKER_SEARCH_SET_LOADER,
        payload: loading
    }
}

export function clearTickerSearch(): SearchTickerAction {
    return {
        type: ACTION_NAMES.IBKR_TICKER_SEARCH_RECEIVED,
        payload: []
    }
}

export type SearchTickerAction = Action<typeof ACTION_NAMES.IBKR_TICKER_SEARCH_RECEIVED, IBKRTicker[]>;

export function searchTicker( ticker: string ): ThunkAction<void, IRootState, any, any> {
    return async ( dispatch: Dispatch<SearchTickerSetLoaderAction | SearchTickerAction> ) => {

        try {

            dispatch( setSearchTickerLoader( true ) )

            const bodyParams = {
                symbol: ticker
            }

            const response = await post<IBKRTicker[]>( ENDPOINTS.IBKR_SEARCH_TICKER(), bodyParams )

            if ( response && response.status === 200 && response.data ) {

                dispatch( {
                    type: ACTION_NAMES.IBKR_TICKER_SEARCH_RECEIVED,
                    payload: response.data
                } )

            } else {
                throw null
            }

        }
        catch ( error ) {
            handleError( error )
        } finally {
            dispatch( setSearchTickerLoader( false ) )
        }

    }
}

export type PositionsSetLoaderAction = Action<typeof ACTION_NAMES.IBKR_POSITIONS_SET_LOADER, boolean>;

function setPositionsLoader( loading: boolean ): PositionsSetLoaderAction {
    return {
        type: ACTION_NAMES.IBKR_POSITIONS_SET_LOADER,
        payload: loading
    }
}

export type GetPositionsAction = Action<typeof ACTION_NAMES.IBKR_POSITIONS_RECEIVED, IBKRPosition[]>;

export function getPositions( accountId: string, pageId: number ): ThunkAction<void, IRootState, any, any> {
    return async ( dispatch: Dispatch<PositionsSetLoaderAction | GetPositionsAction> ) => {

        try {

            dispatch( setPositionsLoader( true ) )

            const response = await get<IBKRPosition[]>( ENDPOINTS.IBKR_GET_PORTFOLIO_POSITIONS( accountId, pageId.toString() ) )

            if ( response && response.status === 200 && response.data ) {

                dispatch( {
                    type: ACTION_NAMES.IBKR_POSITIONS_RECEIVED,
                    payload: response.data
                } )

            } else {
                throw null
            }

        }
        catch ( error ) {
            handleError( error )
        } finally {
            dispatch( setPositionsLoader( false ) )
        }

    }
}

export function pingSession(): ThunkAction<void, IRootState, any, any> {
    return async () => {

        try {

            const response = await get<any>( ENDPOINTS.IBKR_SSO_VALIDATE() )

            if ( response && response.status === 200 ) {
                return
            } else {
                throw null
            }

        }
        catch ( error ) {
            handleError( error )
        }

    }
}

export type LogoutSessionAction = Action<typeof ACTION_NAMES.IBKR_LOGOUT_SESSION, null>;

export function logoutSession(): ThunkAction<void, IRootState, any, any> {
    return async ( dispatch: Dispatch<LogoutSessionAction | any>, getState: () => IRootState ) => {

        try {

            const response = await post<{ status: boolean }>( ENDPOINTS.IBKR_LOGOUT() )

            if ( response && response.status === 200 && response.data.status === true ) {

                const sessionId = getState().ibkr.sessionId

                window.clearTimeout( sessionId )

                dispatch( {
                    type: ACTION_NAMES.IBKR_LOGOUT_SESSION,
                    payload: null
                } )

            } else {
                throw null
            }

        }
        catch ( error ) {

            if ( error && error.response && error.response.status === 401 ) {

                dispatch( stopSession() )

            } else {
                handleError( error )
            }

        }

    }
}

export type GetAccountsAction = Action<typeof ACTION_NAMES.IBKR_ACCOUNTS_RECEIVED, { accounts: string[]; selectedAccount: string }>;
export type SetSessionAction = Action<typeof ACTION_NAMES.IBKR_SET_SESSION_ID, number>;

export function getAccountsAndStartSession(): ThunkAction<void, IRootState, any, any> {
    return async ( dispatch: Dispatch<GetAccountsAction | SetSessionAction | SessionSetLoaderAction | any>, getState: () => IRootState ) => {

        try {

            dispatch( setSessionLoader( true ) )

            const previousSessionId = getState().ibkr.sessionId

            if ( previousSessionId ) {
                window.clearTimeout( previousSessionId )
                dispatch( {
                    type: ACTION_NAMES.IBKR_SET_SESSION_ID,
                    payload: null
                } )
            }

            const response = await get<{ accounts: string[]; selectedAccount: string }>( ENDPOINTS.IBKR_GET_ACCOUNTS() )

            if ( response && response.status === 200 && response.data ) {

                dispatch( {
                    type: ACTION_NAMES.IBKR_ACCOUNTS_RECEIVED,
                    payload: response.data
                } )

                const sessionId = window.setInterval( () => {
                    dispatch( pingSession() )
                }, 60 * 1000 )

                dispatch( {
                    type: ACTION_NAMES.IBKR_SET_SESSION_ID,
                    payload: sessionId
                } )

            } else {
                throw null
            }

        }
        catch ( error ) {
            handleError( error )
        } finally {
            dispatch( setSessionLoader( false ) )
        }

    }
}

export type SessionSetLoaderAction = Action<typeof ACTION_NAMES.IBKR_SET_SESSION_LOADER, boolean>;

function setSessionLoader( loading: boolean ): SessionSetLoaderAction {
    return {
        type: ACTION_NAMES.IBKR_SET_SESSION_LOADER,
        payload: loading
    }
}

export function stopSession(): ThunkAction<void, IRootState, any, any> {
    return async ( dispatch: Dispatch<SetSessionAction>, getState: () => IRootState ) => {

        try {

            const sessionId = getState().ibkr.sessionId

            window.clearTimeout( sessionId )

            dispatch( {
                type: ACTION_NAMES.IBKR_SET_SESSION_ID,
                payload: null
            } )

        }
        catch ( error ) {
            handleError( error )
        }

    }
}

export type SelectStockAction = Action<typeof ACTION_NAMES.IBKR_SELECT_STOCK, IBKRTicker>;

export function selectStock( stock: IBKRTicker ): ThunkAction<void, IRootState, any, any> {
    return async ( dispatch: Dispatch<SelectStockAction> ) => {

        try {

            dispatch( {
                type: ACTION_NAMES.IBKR_SELECT_STOCK,
                payload: stock
            } )

        }
        catch ( error ) {

        }

    }
}

export type DeselectStockAction = Action<typeof ACTION_NAMES.IBKR_DESELECT_STOCK, IBKRTicker>;

export function deselectStock( stock: IBKRTicker ): DeselectStockAction {
    return {
        type: ACTION_NAMES.IBKR_DESELECT_STOCK,
        payload: stock
    }
}

export type OrdersSetLoaderAction = Action<typeof ACTION_NAMES.IBKR_ORDERS_SET_LOADER, boolean>;

function setOrdersLoader( loading: boolean ): OrdersSetLoaderAction {
    return {
        type: ACTION_NAMES.IBKR_ORDERS_SET_LOADER,
        payload: loading
    }
}

export type GetOrdersAction = Action<typeof ACTION_NAMES.IBKR_ORDERS_RECEIVED, IBKROrder[]>;

export function getOrders(): ThunkAction<void, IRootState, any, any> {
    return async ( dispatch: Dispatch<OrdersSetLoaderAction | GetOrdersAction> ) => {

        try {

            dispatch( setOrdersLoader( true ) )

            const response = await get<{ orders: IBKROrder[] }>( ENDPOINTS.IBKR_GET_ORDERS() )

            if ( response && response.status === 200 && response.data ) {

                dispatch( {
                    type: ACTION_NAMES.IBKR_ORDERS_RECEIVED,
                    payload: response.data.orders.filter( item => item.status !== 'Cancelled' && item.status !== 'Inactive' )
                } )

            } else {
                throw null
            }

        }
        catch ( error ) {
            handleError( error )
        } finally {
            dispatch( setOrdersLoader( false ) )
        }

    }
}



export function placeOrders( orders: IBKROrderRequest[] ): ThunkAction<void, IRootState, any, any> {
    return async ( dispatch: Dispatch<any>, getState: () => IRootState ) => {

        try {

            const accountId = getState().ibkr.selectedAccount

            const response = await post<{ id: string, message: string[] }[]>( ENDPOINTS.IBKR_PLACE_ORDERS( accountId ), {
                orders
            } )

            if ( response && response.status === 200 && response.data ) {

                if ( response.data.length === 1 ) {

                    const res = response.data[0]

                    if ( res.message ) {

                        Modal.confirm( {
                            title: 'Confirm?',
                            content: res.message.join( '\n\n' ),
                            icon: <ExclamationCircleOutlined />,
                            onOk() {
                                dispatch( placeOrderReply( res.id, true ) )
                            }
                        } )

                    } else {

                        notification.info( {
                            message: 'Check console'
                        } )

                        console.log( response.data )
                    }

                } else {

                    notification.info( {
                        message: 'Check console'
                    } )

                    console.log( response.data )

                }

            } else {
                throw null
            }

        }
        catch ( error ) {

            handleError( error )

        }

    }
}

export function placeOrderReply( id: string, confirmed: boolean ): ThunkAction<void, IRootState, any, any> {
    return async ( dispatch: Dispatch<any>, getState: () => IRootState ) => {

        try {

            const response = await post<{ id: string, message: string[], order_id?: string }[]>( ENDPOINTS.IBKR_PLACE_ORDER_REPLY( id ), {
                confirmed
            } )

            if ( response && response.status === 200 ) {

                if ( response.data && response.data.length > 0 ) {

                    const res = response.data[0]

                    if ( res.order_id ) {

                        showSuccess( 'Orders placed!' )

                        getOrders()

                    } else if ( res.id && res.message ) {

                        Modal.confirm( {
                            title: 'Confirm?',
                            content: res.message.join( '\n\n' ),
                            icon: <ExclamationCircleOutlined />,
                            onOk() {
                                dispatch( placeOrderReply( res.id, true ) )
                            }
                        } )

                    } else {
                        notification.info( {
                            message: 'Check console'
                        } )

                        console.log( response.data )
                    }

                } else {

                    notification.info( {
                        message: 'Check console'
                    } )

                    console.log( response.data )

                }

            } else {
                throw null
            }

        }
        catch ( error ) {

            if ( error && error.response && error.response.status === 400 ) {

                notification.info( {
                    message: 'Orders cancelled!'
                } )

            } else {
                handleError( error )
            }

        }

    }
}

export function cancelOrder( orderId: string ): ThunkAction<void, IRootState, any, any> {
    return async ( dispatch: Dispatch<any>, getState: () => IRootState ) => {

        try {

            const accountId = getState().ibkr.selectedAccount

            const response = await del<{ order_id: string, msg: string, conid: number, account: string }>( ENDPOINTS.IBKR_CANCEL_ORDER( accountId, orderId ) )

            if ( response && response.status === 200 ) {

                notification.info( {
                    message: response.data && response.data.msg ? response.data.msg : 'Done!'
                } )

                dispatch( getOrders() )

            } else {
                throw null
            }

        }
        catch ( error ) {

            handleError( error )

        }

    }
}

export type SetConfigParamsAction = Action<typeof ACTION_NAMES.IBKR_SET_CONFIG_PARAMS, IBKRConfig>;

export function setConfigParams( config: IBKRConfig ): SetConfigParamsAction {

    webStorage.storeIBKRGateway( config.gatewayURL )

    return {
        type: ACTION_NAMES.IBKR_SET_CONFIG_PARAMS,
        payload: config
    }
}

export function editOrder( orderId: string, orderConfig: IBKROrderRequest ): ThunkAction<void, IRootState, any, any> {
    return async ( dispatch: Dispatch<any>, getState: () => IRootState ) => {

        try {

            const accountId = getState().ibkr.selectedAccount

            const response = await put<{ id: string, message: string[], order_id?: string }[]>( ENDPOINTS.IBKR_MODIFY_ORDER( accountId, orderId ), orderConfig )

            if ( response && response.status === 200 ) {

                if ( response.data.length === 1 ) {

                    const res = response.data[0]

                    if ( res.order_id ) {

                        showSuccess( 'Order updated!' )
                        getOrders()

                    } else if ( res.message ) {
                        Modal.confirm( {
                            title: 'Confirm?',
                            content: res.message.join( '\n\n' ),
                            icon: <ExclamationCircleOutlined />,
                            onOk() {
                                dispatch( placeOrderReply( res.id, true ) )
                            }
                        } )
                    } else {

                        notification.info( {
                            message: 'Check console'
                        } )

                        console.log( response.data )

                    }

                } else {

                    notification.info( {
                        message: 'Check console'
                    } )

                    console.log( response.data )

                }

            } else {
                throw null
            }

        }
        catch ( error ) {

            handleError( error )

        }

    }
}