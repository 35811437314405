import React from 'react';
import { injectIntl } from 'react-intl'
import App_ToolBar from './App_ToolBar';
import AppRouter from './AppRouter';
import { MapStateToProps, connect } from 'react-redux'
import { HashRouter, Route, Switch, } from 'react-router-dom';

import './App.css';
import 'antd/dist/antd.css'
import './ant_overrides.css';

import Home from './containers/Home'
import ProductCost from './containers/ProductCost'
import Budget from './containers/Budget'
import Investment from './containers/Investment'
import Taxes from './containers/Taxes'
import Trading from './containers/Trading'
import IBKRConnector from './containers/IBKRConnector'
import XLSManager from './containers/XLSManager'
import { Layout } from "antd"

/*Actions*/
import { resetDataStored, mainImportReceived } from './redux/product_cost/product_cost_actions'
import { dataManagementSaveFile, dataManagementLoadFile } from './utils/data_management_actions'

/*Models*/
import { ReduxProps } from './models/index'

/*Version*/
var packageJson = require( '../package.json' )
const UI_VERSION = packageJson.version

const { Header, Content, Sider, Footer } = Layout;

export interface Props extends ReduxProps {
}

export interface State {
  collapsed: boolean;
  visibleModalApiKey: boolean;
}

class App extends React.Component<Props, State> {

  public static select: MapStateToProps<any, any, any> = ( state: any ) => {
    return {
    } as Props
  }

  constructor( props: Props ) {
    super( props )

    this.state = {
      collapsed: false,
      visibleModalApiKey: false,
    };

  }

  private onCollapse = ( collapsed: boolean ) => {
    this.setState( { collapsed } );
  };

  private resetAllDataStored = () => {
    this.props.dispatch( resetDataStored() );
  }

  private saveFile = () => {
    this.props.dispatch( dataManagementSaveFile() );
  }

  private loadFile = () => {
    this.props.dispatch( dataManagementLoadFile() )
  }

  toggle = () => {
    this.setState( {
      collapsed: !this.state.collapsed,
    } );
  };

  render() {
    const { collapsed, visibleModalApiKey } = this.state;
    return (
      <HashRouter>
        <Layout style={{ minHeight: '100vh' }}>
          <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse} breakpoint="sm"
          >
            <div className="app-logo" > <svg style={{ fontSize: '48px', color: "#ffffff" }} viewBox="0 0 1024 1024" width="1em" height="1em" fill="currentColor">
              <path
                d="M99.096 315.634s-82.58-64.032-82.58-132.13c0-66.064 33.032-165.162 148.646-148.646 83.37 11.91 99.096 165.162 99.096 165.162l-165.162 115.614zM924.906 315.634s82.58-64.032 82.58-132.13c0-66.064-33.032-165.162-148.646-148.646-83.37 11.91-99.096 165.162-99.096 165.162l165.162 115.614z"
                fill="#6B676E"
                p-id="1143"
              />
              <path
                d="M1024 561.548c0 264.526-229.23 429.42-512.002 429.42S0 826.076 0 561.548 283.96 66.064 512.002 66.064 1024 297.022 1024 561.548z"
                fill="#FFEBD2"
                p-id="1144"
              />
              <path
                d="M330.324 842.126c0 82.096 81.34 148.646 181.678 148.646s181.678-66.55 181.678-148.646H330.324z"
                fill="#E9D7C3"
                p-id="1145"
              />
              <path
                d="M644.13 611.098C594.582 528.516 561.55 512 512.002 512c-49.548 0-82.58 16.516-132.13 99.096-42.488 70.814-78.73 211.264-49.548 247.742 66.064 82.58 165.162 33.032 181.678 33.032 16.516 0 115.614 49.548 181.678-33.032 29.18-36.476-7.064-176.93-49.55-247.74z"
                fill="#FFFFFF"
                p-id="1146"
              />
              <path
                d="M611.098 495.484c0-45.608 36.974-82.58 82.58-82.58 49.548 0 198.194 99.098 198.194 165.162s-79.934 144.904-148.646 99.096c-49.548-33.032-132.128-148.646-132.128-181.678zM412.904 495.484c0-45.608-36.974-82.58-82.58-82.58-49.548 0-198.194 99.098-198.194 165.162s79.934 144.904 148.646 99.096c49.548-33.032 132.128-148.646 132.128-181.678z"
                fill="#6B676E"
                p-id="1147"
              />
              <path
                d="M512.002 726.622c-30.06 0-115.614 5.668-115.614 33.032 0 49.638 105.484 85.24 115.614 82.58 10.128 2.66 115.614-32.944 115.614-82.58-0.002-27.366-85.556-33.032-115.614-33.032z"
                fill="#464655"
                p-id="1148"
              />
              <path
                d="M330.324 495.484m-33.032 0a33.032 33.032 0 1 0 66.064 0 33.032 33.032 0 1 0-66.064 0Z"
                fill="#464655"
                p-id="1149"
              />
              <path
                d="M693.678 495.484m-33.032 0a33.032 33.032 0 1 0 66.064 0 33.032 33.032 0 1 0-66.064 0Z"
                fill="#464655"
                p-id="1150"
              />
            </svg></div>

            <AppRouter></AppRouter>

          </Sider>
          <Layout className="site-layout">
            {
              !!localStorage.getItem( 'admin' ) ? ( <Header className="site-layout-background" style={{ padding: 0 }}>

                <App_ToolBar
                  collapsed={collapsed}
                  toggle={this.toggle}
                  onResetDataStored={this.resetAllDataStored}
                  saveFile={this.saveFile}
                  loadFile={this.loadFile}
                />
              </Header> ) : null
            }
            <Content
              className="site-layout-background"
              style={{
                margin: '24px 16px',
                padding: 24,
                minHeight: 280,
              }}
            >

              <Switch>
                <Route exact path="/" component={Home as any} />
                <Route path="/budget" component={Budget} />
                <Route path="/investment" component={Investment} />
                <Route path="/taxes" component={Taxes} />
                <Route path="/product-cost" component={ProductCost} />
                {!!localStorage.getItem( 'admin' ) ? <Route path="/trading" component={Trading} /> : null}
                {!!localStorage.getItem( 'admin' ) ? <Route path="/ibkr" component={IBKRConnector} /> : null}
                <Route path="/parser" component={XLSManager} />
              </Switch>

            </Content>
            <Footer className="layout-footer">Version - {UI_VERSION}</Footer>
          </Layout>
        </Layout>
      </HashRouter>
    );
  }
}

export default injectIntl( connect( App.select )( App as any ) );
