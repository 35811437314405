/*Models*/
import { BudgetEntry, TaxBrackets } from '../models'


export function getYearlyIncomeSum( taxesInput: BudgetEntry[] ) {
    return taxesInput.reduce( ( acc, entry ) => {
        const multiplier = entry.yearly ? 1 : 12
        acc += entry.value * multiplier
        return acc
    }, 0 )
}

export function getYearlyTaxSum( taxesInput: BudgetEntry[] ) {
    return taxesInput.reduce( ( acc, entry ) => {
        const multiplier = entry.yearly ? 1 : 12
        acc += entry.value * multiplier * ( entry.tax / 100 )
        return acc
    }, 0 )
}

export function getYearlyTaxAllowanceSum( taxesInput: BudgetEntry[] ) {
    return taxesInput.reduce( ( acc, entry ) => {
        const multiplier = entry.yearly ? 1 : 12
        acc += entry.value * multiplier * ( entry.taxAllowance / 100 )
        return acc
    }, 0 )
}

export function getBracketTaxAmount( bracket: TaxBrackets, yearlyIncome: number ) {
    if ( bracket.max <= yearlyIncome ) {
        return ( bracket.max - bracket.min ) * bracket.rate / 100
    }
    else if ( bracket.min >= yearlyIncome ) {
        return 0
    }
    else {
        return ( yearlyIncome - bracket.min ) * bracket.rate / 100
    }
}
