/* Actions */
import { TaxesMainImportReceivedAction, TaxesUpdateEntryAction } from "./taxes_actions"

/* Constants */
import * as ACTION_NAMES from "../action_names"

/* State */
import { initialState, TaxesState } from "./taxes_states"


type Actions = TaxesMainImportReceivedAction | TaxesUpdateEntryAction;

function taxesReducer( state = initialState, action: Actions ) {
    let stateToModify: TaxesState = JSON.parse( JSON.stringify( state ) );

    switch ( action.type ) {
        case ACTION_NAMES.MAIN_IMPORT_RECEIVED:
            let data = JSON.parse( action.payload.budget ) as TaxesState;
            let budgetData = data ? data : state;
            return Object.assign( {}, state, budgetData );

        case ACTION_NAMES.TAXES_UPDATE_ENTRY:
            return Object.assign( {}, state, {
                taxes: action.payload.taxes,
                taxesAmount: action.payload.taxesAmount
            } );

        default:
            return state;
    }
};

export default taxesReducer;