import React from 'react';
import { Empty, Statistic, Row, Col } from 'antd';
import { FormattedNumber } from 'react-intl';

import moment from 'moment';
import classNames from 'classnames';

/*Models*/
import { Trade, TradeManagementType } from '../../models';

//utils
import { GREEN_COLOR, RED_COLOR, WARNING_COLOR, TARGET_COLOR } from '../../utils/helpers';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';


interface Props {
    trades: Trade[],
    totalTradesCount: number;
}

interface State {

}

class TradeView extends React.Component<Props, State> {

    constructor( props: Props ) {
        super( props );
        this.state = {

        }
    }

    render() {

        const { trades, totalTradesCount } = this.props

        const winningTrades = this.getWinningTrades()
        const losingTrades = this.getLosingTrades()

        const avgWinner = winningTrades.length ? this.getTradesSumFor( winningTrades, 'gross_PL' ) / winningTrades.length : 0
        const avgLoser = losingTrades.length ? this.getTradesSumFor( losingTrades, 'gross_PL' ) / losingTrades.length : 0

        const sums = this.getSumObject()

        if ( !trades || trades.length === 0 ) {
            return (
                <div style={{ minHeight: 200 }} className="center-zone"><Empty /></div>
            )
        }

        const sums_bar = this.getSumsForManagement( 'bar_by_bar_after_1R' )
        const sums_stop_BE = this.getSumsForManagement( 'stop_to_BE_at_1R_tgt_2R' )
        const sums_AON_1 = this.getSumsForManagement( 'AON_1_R' )
        const sums_AON_1_5 = this.getSumsForManagement( 'AON_1_5_R' )
        const sums_AON_2 = this.getSumsForManagement( 'AON_2_R' )
        const sums_AON_3 = this.getSumsForManagement( 'AON_3_R' )

        return sums.risk ? (
            <div>

                <div className="mgt-30 light-bg pad-30">

                    <p className="text-center upper mgb-30">Indicators</p>

                    <Row justify="space-around" gutter={[20, 20]}>

                        <Col>
                            <div className="pad-20 white-bg">
                                <Statistic
                                    title="Trades"
                                    value={trades.length}
                                />
                                <div className="font-90 mgt-5">
                                    <span className="grey-color mgr-5">/</span>
                                    <span className="bold">{totalTradesCount}</span>
                                </div>
                            </div>
                        </Col>

                        <Col>
                            <div className="pad-20 white-bg">
                                <Statistic
                                    title="Winners"
                                    value={winningTrades.length}
                                    valueStyle={{
                                        color: GREEN_COLOR
                                    }}
                                />
                                {
                                    winningTrades.length ? (
                                        <div className="font-90 mgt-5">
                                            <span className="grey-color mgr-5">Max.</span>
                                            <span className="green-color bold">{Math.max( ...winningTrades.map( trade => trade.gross_PL ) )}&nbsp;$</span>
                                        </div>
                                    ) : null
                                }
                            </div>
                        </Col>

                        <Col>
                            <div className="pad-20 white-bg">
                                <Statistic
                                    title="Losers"
                                    value={losingTrades.length}
                                    valueStyle={{
                                        color: RED_COLOR
                                    }}
                                />
                                {
                                    losingTrades.length ? (
                                        <div className="font-90 mgt-5">
                                            <span className="grey-color mgr-5">Max.</span>
                                            <span className="red-color bold">{Math.min( ...losingTrades.map( trade => trade.gross_PL ) )}&nbsp;$</span>
                                        </div>
                                    ) : null
                                }
                            </div>
                        </Col>

                        {
                            avgLoser ? (
                                <Col>
                                    <div className="pad-20 white-bg">
                                        <Statistic
                                            title="Win/Loss Ratio"
                                            value={( avgWinner / -avgLoser )}
                                            precision={2}
                                        />
                                        <div className="font-90 mgt-5">
                                            <span className="grey-color mgr-5">AVG P/L</span>
                                            <span className="green-color bold">{this.getFixedValueDisplay( avgWinner )}&nbsp;$</span>
                                            <span className="grey-color mgl-5 mgr-5">/</span>
                                            <span className="red-color bold">{this.getFixedValueDisplay( avgLoser )}&nbsp;$</span>
                                        </div>
                                    </div>
                                </Col>
                            ) : null
                        }

                        <Col>
                            <div className="pad-20 white-bg">
                                <Statistic
                                    title="Gross P/L"
                                    value={sums.gross_PL}
                                    precision={2}
                                    suffix="$"
                                    valueStyle={{
                                        color: sums.gross_PL >= 0 ? GREEN_COLOR : RED_COLOR
                                    }}
                                />
                                <div className="font-90 mgt-5">
                                    <span className="grey-color mgr-5">Fees</span>
                                    <span className="orange-color bold">{this.getFixedValueDisplay( sums.fees )}&nbsp;$</span>
                                </div>
                            </div>
                        </Col>

                        <Col>
                            <div className="pad-20 white-bg">
                                <Statistic
                                    title="Net P/L"
                                    value={sums.net_PL}
                                    precision={2}
                                    suffix="$"
                                    valueStyle={{
                                        color: sums.net_PL >= 0 ? GREEN_COLOR : RED_COLOR
                                    }}
                                />
                                <div className="font-90 mgt-5">
                                    <span className="grey-color mgr-5">Net R</span>
                                    <span className="bold">{( sums.net_PL / sums.risk ).toFixed( 2 )}</span>
                                </div>
                            </div>
                        </Col>

                    </Row>

                </div>


                <div className="mgt-30 light-bg pad-30">

                    <p className="text-center upper mgb-30">Trade management</p>

                    <Row gutter={[20, 20]} justify="center" align="middle">

                        <Col xs={12} md={8}>
                            <div className="pad-20 white-bg">
                                <Statistic
                                    title="R (realized gross)"
                                    value={sums.gross_R || 0}
                                    precision={2}
                                    valueStyle={{
                                        color: TARGET_COLOR
                                    }}
                                />

                                <div className="font-90 mgt-10">
                                    <span className="grey-color mgr-5">Batting AVG</span>
                                    <span className="bold">{( ( winningTrades.length / trades.length ) * 100 ).toFixed( 1 )}&nbsp;%</span>
                                </div>
                                <div className="font-90 mgt-5">
                                    <span className="grey-color mgr-5">Win/Loss Ratio</span>
                                    <span className="bold">{( avgWinner / -avgLoser ).toFixed( 2 )}</span>
                                </div>

                                <div className="font-90 mgt-10">
                                    <span className="grey-color mgr-5">Net P/L</span>
                                    <span className="bold" style={{
                                        color: sums.net_PL >= 0 ? GREEN_COLOR : RED_COLOR
                                    }}>{( sums.net_PL ).toFixed( 2 )}&nbsp;$</span>
                                </div>
                                <div className="font-90 mgt-5">
                                    <span className="grey-color mgr-5">Net R</span>
                                    <span className="bold">{( sums.net_PL / sums.risk ).toFixed( 2 )}</span>
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} md={8}>
                            <div className="pad-20 white-bg">
                                <Statistic
                                    title="AVG Risk"
                                    value={sums.risk}
                                    precision={2}
                                    suffix="$"
                                />
                            </div>
                        </Col>

                    </Row>

                    <Row gutter={[20, 20]} justify="center">

                        <Col xs={12} md={8}>
                            <div className="pad-20 white-bg">
                                <Statistic
                                    title="R (bar by bar after 1R)"
                                    value={sums.bar_by_bar_after_1R || 0}
                                    precision={2}
                                    valueStyle={sums.bar_by_bar_after_1R ? {
                                        color: this.getTradeManagementColorIndicator( sums.bar_by_bar_after_1R, sums )
                                    } : null}
                                    prefix={sums.bar_by_bar_after_1R > sums.gross_R ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                                />

                                <div className="font-90 mgt-10">
                                    <span className="grey-color mgr-5">Batting AVG</span>
                                    <span className="bold">{sums_bar.battingAvg.toFixed( 1 )}&nbsp;%</span>
                                </div>
                                <div className="font-90 mgt-5">
                                    <span className="grey-color mgr-5">Win/Loss Ratio</span>
                                    <span className="bold">{( sums_bar.winLossRatio ).toFixed( 2 )}</span>
                                </div>

                                <div className="font-90 mgt-10">
                                    <span className="grey-color mgr-5">Net P/L</span>
                                    <span className="bold" style={{
                                        color: sums_bar.net_PL >= 0 ? GREEN_COLOR : RED_COLOR
                                    }}>{( sums_bar.net_PL ).toFixed( 2 )}&nbsp;$</span>
                                </div>
                                <div className="font-90 mgt-5">
                                    <span className="grey-color mgr-5">Net R</span>
                                    <span className="bold">{( sums_bar.net_PL / sums.risk ).toFixed( 2 )}</span>
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} md={8}>
                            <div className="pad-20 white-bg">
                                <Statistic
                                    title="R (2R stop to BE @1R)"
                                    value={sums.stop_to_BE_at_1R_tgt_2R || 0}
                                    precision={2}
                                    valueStyle={sums.stop_to_BE_at_1R_tgt_2R ? {
                                        color: this.getTradeManagementColorIndicator( sums.stop_to_BE_at_1R_tgt_2R, sums )
                                    } : null}
                                    prefix={sums.stop_to_BE_at_1R_tgt_2R > sums.gross_R ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                                />

                                <div className="font-90 mgt-10">
                                    <span className="grey-color mgr-5">Batting AVG</span>
                                    <span className="bold">{sums_stop_BE.battingAvg.toFixed( 1 )}&nbsp;%</span>
                                </div>
                                <div className="font-90 mgt-5">
                                    <span className="grey-color mgr-5">Win/Loss Ratio</span>
                                    <span className="bold">{( sums_stop_BE.winLossRatio ).toFixed( 2 )}</span>
                                </div>

                                <div className="font-90 mgt-10">
                                    <span className="grey-color mgr-5">Net P/L</span>
                                    <span className="bold" style={{
                                        color: sums_stop_BE.net_PL >= 0 ? GREEN_COLOR : RED_COLOR
                                    }}>{( sums_stop_BE.net_PL ).toFixed( 2 )}&nbsp;$</span>
                                </div>
                                <div className="font-90 mgt-5">
                                    <span className="grey-color mgr-5">Net R</span>
                                    <span className="bold">{( sums_stop_BE.net_PL / sums.risk ).toFixed( 2 )}</span>
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} md={8}>
                            <div className="pad-20 white-bg">
                                <Statistic
                                    title="R (AON 1R)"
                                    value={sums.AON_1_R || 0}
                                    precision={2}
                                    valueStyle={sums.AON_1_R ? {
                                        color: this.getTradeManagementColorIndicator( sums.AON_1_R, sums )
                                    } : null}
                                    prefix={sums.AON_1_R > sums.gross_R ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                                />

                                <div className="font-90 mgt-10">
                                    <span className="grey-color mgr-5">Batting AVG</span>
                                    <span className="bold">{sums_AON_1.battingAvg.toFixed( 1 )}&nbsp;%</span>
                                </div>
                                <div className="font-90 mgt-5">
                                    <span className="grey-color mgr-5">Win/Loss Ratio</span>
                                    <span className="bold">{( sums_AON_1.winLossRatio ).toFixed( 2 )}</span>
                                </div>

                                <div className="font-90 mgt-10">
                                    <span className="grey-color mgr-5">Net P/L</span>
                                    <span className="bold" style={{
                                        color: sums_AON_1.net_PL >= 0 ? GREEN_COLOR : RED_COLOR
                                    }}>{( sums_AON_1.net_PL ).toFixed( 2 )}&nbsp;$</span>
                                </div>
                                <div className="font-90 mgt-5">
                                    <span className="grey-color mgr-5">Net R</span>
                                    <span className="bold">{( sums_AON_1.net_PL / sums.risk ).toFixed( 2 )}</span>
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} md={8}>
                            <div className="pad-20 white-bg">
                                <Statistic
                                    title="R (AON 1.5R)"
                                    value={sums.AON_1_5_R || 0}
                                    precision={2}
                                    valueStyle={sums.AON_1_5_R ? {
                                        color: this.getTradeManagementColorIndicator( sums.AON_1_5_R, sums )
                                    } : null}
                                    prefix={sums.AON_1_5_R > sums.gross_R ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                                />

                                <div className="font-90 mgt-10">
                                    <span className="grey-color mgr-5">Batting AVG</span>
                                    <span className="bold">{sums_AON_1_5.battingAvg.toFixed( 1 )}&nbsp;%</span>
                                </div>
                                <div className="font-90 mgt-5">
                                    <span className="grey-color mgr-5">Win/Loss Ratio</span>
                                    <span className="bold">{( sums_AON_1_5.winLossRatio ).toFixed( 2 )}</span>
                                </div>

                                <div className="font-90 mgt-10">
                                    <span className="grey-color mgr-5">Net P/L</span>
                                    <span className="bold" style={{
                                        color: sums_AON_1_5.net_PL >= 0 ? GREEN_COLOR : RED_COLOR
                                    }}>{( sums_AON_1_5.net_PL ).toFixed( 2 )}&nbsp;$</span>
                                </div>
                                <div className="font-90 mgt-5">
                                    <span className="grey-color mgr-5">Net R</span>
                                    <span className="bold">{( sums_AON_1_5.net_PL / sums.risk ).toFixed( 2 )}</span>
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} md={8}>
                            <div className="pad-20 white-bg">
                                <Statistic
                                    title="R (AON 2R)"
                                    value={sums.AON_2_R || 0}
                                    precision={2}
                                    valueStyle={sums.AON_2_R ? {
                                        color: this.getTradeManagementColorIndicator( sums.AON_2_R, sums )
                                    } : null}
                                    prefix={sums.AON_2_R > sums.gross_R ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                                />

                                <div className="font-90 mgt-10">
                                    <span className="grey-color mgr-5">Batting AVG</span>
                                    <span className="bold">{sums_AON_2.battingAvg.toFixed( 1 )}&nbsp;%</span>
                                </div>
                                <div className="font-90 mgt-5">
                                    <span className="grey-color mgr-5">Win/Loss Ratio</span>
                                    <span className="bold">{( sums_AON_2.winLossRatio ).toFixed( 2 )}</span>
                                </div>

                                <div className="font-90 mgt-10">
                                    <span className="grey-color mgr-5">Net P/L</span>
                                    <span className="bold" style={{
                                        color: sums_AON_2.net_PL >= 0 ? GREEN_COLOR : RED_COLOR
                                    }}>{( sums_AON_2.net_PL ).toFixed( 2 )}&nbsp;$</span>
                                </div>
                                <div className="font-90 mgt-5">
                                    <span className="grey-color mgr-5">Net R</span>
                                    <span className="bold">{( sums_AON_2.net_PL / sums.risk ).toFixed( 2 )}</span>
                                </div>
                            </div>
                        </Col>

                        <Col xs={12} md={8}>
                            <div className="pad-20 white-bg">
                                <Statistic
                                    title="R (AON 3R)"
                                    value={sums.AON_3_R || 0}
                                    precision={2}
                                    valueStyle={sums.AON_3_R ? {
                                        color: this.getTradeManagementColorIndicator( sums.AON_3_R, sums )
                                    } : null}
                                    prefix={sums.AON_3_R > sums.gross_R ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                                />

                                <div className="font-90 mgt-10">
                                    <span className="grey-color mgr-5">Batting AVG</span>
                                    <span className="bold">{sums_AON_3.battingAvg.toFixed( 1 )}&nbsp;%</span>
                                </div>
                                <div className="font-90 mgt-5">
                                    <span className="grey-color mgr-5">Win/Loss Ratio</span>
                                    <span className="bold">{( sums_AON_3.winLossRatio ).toFixed( 2 )}</span>
                                </div>

                                <div className="font-90 mgt-10">
                                    <span className="grey-color mgr-5">Net P/L</span>
                                    <span className="bold" style={{
                                        color: sums_AON_3.net_PL >= 0 ? GREEN_COLOR : RED_COLOR
                                    }}>{( sums_AON_3.net_PL ).toFixed( 2 )}&nbsp;$</span>
                                </div>
                                <div className="font-90 mgt-5">
                                    <span className="grey-color mgr-5">Net R</span>
                                    <span className="bold">{( sums_AON_3.net_PL / sums.risk ).toFixed( 2 )}</span>
                                </div>
                            </div>
                        </Col>

                    </Row>


                </div>

            </div>
        ) : <div style={{ minHeight: 200 }} className="center-zone"><Empty /></div>
    }

    private getWinningTrades = () => {
        return this.props.trades.filter( trade => trade.gross_R >= 0 )
    }

    private getLosingTrades = () => {
        return this.props.trades.filter( trade => trade.gross_R < 0 )
    }

    private getTradesSumFor = ( trades: Trade[], key: string ) => {

        return trades.reduce( ( acc, trade ) => {
            acc += trade[key]
            return acc
        }, 0 )

    }

    private getSumsForManagement = ( managementKey: TradeManagementType ) => {

        let sums = this.props.trades.reduce( ( acc, trade ) => {

            const tradeR = trade[managementKey] || 0

            acc.net_PL += ( ( trade.risk * tradeR ) - trade.fees )

            if ( trade[managementKey] >= 0 ) {
                acc.winners += 1
                acc.profits += ( trade.risk * tradeR )
            }

            if ( trade[managementKey] < 0 ) {
                acc.losers += 1
                acc.losses += ( trade.risk * tradeR )
            }

            return acc

        }, {
            net_PL: 0,
            winners: 0,
            losers: 0,
            profits: 0,
            losses: 0,
            avgWinner: 0,
            avgLoser: 0,
            winLossRatio: 0,
            battingAvg: 0
        } )

        sums.avgWinner = sums.winners ? ( sums.profits / sums.winners ) : 0
        sums.avgLoser = sums.losers ? ( sums.losses / sums.losers ) : 0
        sums.winLossRatio = sums.avgLoser ? ( sums.avgWinner / -sums.avgLoser ) : 0
        sums.battingAvg = ( sums.winners / this.props.trades.length ) * 100

        return sums

    }


    private getSumObject = () => {

        const { trades } = this.props

        return {
            ticker: null,
            start_at: null,
            risk: this.getTradesSumFor( trades, 'risk' ) / trades.length,
            fees: this.getTradesSumFor( trades, 'fees' ),
            gross_PL: this.getTradesSumFor( trades, 'gross_PL' ),
            net_PL: this.getTradesSumFor( trades, 'net_PL' ),
            gross_R: this.getTradesSumFor( trades, 'gross_R' ),
            net_R: null,
            bar_by_bar_after_1R: this.getTradesSumFor( trades, 'bar_by_bar_after_1R' ),
            stop_to_BE_at_1R_tgt_2R: this.getTradesSumFor( trades, 'stop_to_BE_at_1R_tgt_2R' ),
            AON_1_R: this.getTradesSumFor( trades, 'AON_1_R' ),
            AON_1_5_R: this.getTradesSumFor( trades, 'AON_1_5_R' ),
            AON_2_R: this.getTradesSumFor( trades, 'AON_2_R' ),
            AON_3_R: this.getTradesSumFor( trades, 'AON_3_R' ),
        } as Trade

    }

    private getFixedValueDisplay = ( value: number ) => {

        if ( value === null || value === undefined ) {
            return '-'
        }

        return value.toFixed( 2 )
    }

    private getTradeManagementColorIndicator = ( value: number, sums: Trade ) => {

        if ( value !== null && value !== undefined && sums.gross_R !== undefined && sums.gross_R !== null ) {

            const significantDifference = Math.abs( sums.gross_R * 0.1 )

            if ( value > ( sums.gross_R + significantDifference ) ) {

                if ( value > 0 ) {
                    return GREEN_COLOR
                }

                return WARNING_COLOR
            }

            if ( value < sums.gross_R - significantDifference ) {
                return RED_COLOR
            }

        }

        return null

    }

}

export default TradeView;