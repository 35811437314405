import React from 'react';
import { Statistic, Row, Col, Form, InputNumber } from 'antd';
import { Product } from '../../models';

interface Props {
    dataKey: string;
    productDetails: Product;
    costs: number;
    setProductTotalItems: ( dataKey: string, value: number ) => void;
}

interface State {
    discount: number;
}

class ProductCostGlobal extends React.Component<Props, State> {

    constructor( props: Props ) {
        super( props );

        this.state = {
            discount: 0
        }
    }

    private getSalesRevenue = () => {
        if ( this.state.discount !== 0 ) {
            let discount_value = this.props.productDetails.potential_cost * this.props.productDetails.total_items * ( this.state.discount / 100 )
            return ( this.props.productDetails.potential_cost * this.props.productDetails.total_items ) - discount_value;
        }

        return this.props.productDetails.potential_cost * this.props.productDetails.total_items;
    }

    private getMargin(): number {
        return this.getSalesRevenue() - this.getTotalCosts();
    }

    private getTotalCosts(): number {
        return this.props.costs * this.props.productDetails.total_items;
    }

    private handleTotalItemsChange = ( key: string, value: number ) => {
        this.props.setProductTotalItems( key, value );
    }

    private setDiscount = ( value: number ) => {
        this.setState( { discount: value } );
    }

    render() {

        const { productDetails } = this.props
        const { discount } = this.state

        return ( <div>
            <Form layout="vertical">
                <Form.Item label="Nombre total de produits disponibles" >
                    <InputNumber min={0} placeholder="Nombre total de produits disponibles" value={productDetails.total_items as number} onChange={value => this.handleTotalItemsChange( this.props.dataKey, value as number )} />
                </Form.Item>
                <Form.Item label="Saisir une réduction à appliquer (%)" >
                    <InputNumber min={0} placeholder="Réduction" value={discount as number} onChange={value => this.setDiscount( value as number )} />
                </Form.Item>
            </Form>

            <Row justify="space-around">
                <Col><Statistic title="Chiffre d'affaire" value={this.getSalesRevenue()} precision={2} suffix="€" /></Col>
                <Col><Statistic title="Coût" value={this.getTotalCosts()} precision={2} suffix="€" /></Col>
                <Col><Statistic title="Marge" value={this.getMargin()} precision={2} suffix="€" /></Col>
            </Row>

        </div> );
    };
}

export default ProductCostGlobal;