import React from 'react';
import { Tag, Table, Divider, Tooltip } from 'antd';
import {
    EditOutlined,
    CloseOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { ColumnProps } from 'antd/lib/table'
import { FormattedNumber } from 'react-intl'

/*Models*/
import { Trade, Position } from '../../models';

//utils
import { GREEN_COLOR, RED_COLOR, TARGET_COLOR, DATE_TIME_FORMAT } from '../../utils/helpers';

interface Props {
    trades: Trade[];
    onEditTrade: ( trade: Trade ) => void;
    onRemoveTrade: ( id: string, planned_trade: boolean ) => void;
    handleViewDetails: ( trade: Trade ) => void;
}

interface State {
    visible: boolean
}

class TradesTableView extends React.Component<Props, State> {

    constructor( props: Props ) {
        super( props );
        this.state = {
            visible: false
        }
    }

    editTrade = ( trade: Trade ) => {
        this.props.onEditTrade( trade );
    }

    removeTrade = ( trade: Trade ) => {
        this.props.onRemoveTrade( trade.id, trade.planned_trade );
    }

    showDrawer = ( trade: Trade ) => {
        this.props.handleViewDetails( trade );
    }

    getPositionTag = ( trade: Trade ) => {
        if ( trade.position ) {
            if ( trade.position === Position.Long )
                return <Tag color="cyan">LONG</Tag>;
            else
                return <Tag color="orange">SHORT</Tag>;
        }
        else
            return;
    }

    render() {

        const { trades } = this.props

        return ( <>
            <Table size="small"
                dataSource={trades}
                columns={this.getColumns()}
                rowClassName={() => 'editable-row'}
                rowKey={record => record.id}
                onRow={( record, rowIndex ) => {
                    return {
                        onDoubleClick: event => { this.showDrawer( record ) },
                    };
                }}
                pagination={{
                    pageSize: 100
                }}
            />
        </> );
    };

    private getFormattedInput = ( value: number | undefined ) => {
        return value !== null && value !== undefined ? (
            <FormattedNumber
                value={value}
                style="currency"
                currency="USD"
                currencyDisplay="narrowSymbol"
            />
        ) : null
    }

    private getColumns = (): Array<ColumnProps<Trade>> => {

        let columns: Array<ColumnProps<Trade>> = [];

        columns = [
            {
                title: 'Ticker',
                key: 'ticker',
                dataIndex: 'ticker',
                ellipsis: true,
                render: ( value: string, record: Trade ) => {
                    return (
                        <strong>{record.ticker}</strong>
                    )
                }
            },
            {
                title: 'Position',
                key: 'position',
                dataIndex: 'position',
                ellipsis: true,
                render: ( value: string, record: Trade ) => {
                    return this.getPositionTag( record )
                }
            },
            {
                title: 'TF',
                key: 'time_frame',
                dataIndex: 'time_frame',
                ellipsis: true,
                render: ( value: string, record: Trade ) => {
                    return record.time_frame
                },
                className: "upper font-90"
            },
            {
                title: 'Start at',
                key: 'start_at',
                dataIndex: 'start_at',
                ellipsis: true,
                render: ( value: string, record: Trade ) => {
                    return <div className="font-70" style={{ marginTop: 3 }}>{moment( record.start_at ).format( DATE_TIME_FORMAT )}</div>
                },
                width: 110
            },
            {
                title: 'Duration',
                key: 'duration',
                dataIndex: 'duration',
                ellipsis: true,
                render: ( value: string, record: Trade ) => {
                    return <div className="font-80">{moment.duration( record.duration, 's' ).humanize()}</div>
                }
            },
            {
                title: 'Quantity',
                key: 'qty',
                dataIndex: 'qty',
                ellipsis: true,
                render: ( value: string, record: Trade ) => {
                    return record.qty;
                }
            },
            {
                title: 'Entry',
                key: 'avg_entry_price',
                dataIndex: 'avg_entry_price',
                ellipsis: true,
                render: ( value: string, record: Trade ) => {
                    return this.getFormattedInput( record.avg_entry_price )
                }
            },
            {
                title: 'Exit',
                key: 'avg_exit_price',
                dataIndex: 'avg_exit_price',
                ellipsis: true,
                render: ( value: string, record: Trade ) => {
                    return this.getFormattedInput( record.avg_exit_price )
                }
            },
            {
                title: 'Risk',
                key: 'risk',
                dataIndex: 'risk',
                ellipsis: true,
                render: ( value: string, record: Trade ) => {
                    return this.getFormattedInput( record.risk )
                }
            },
            {
                title: 'Net P/L',
                key: 'net_PL',
                dataIndex: 'net_PL',
                ellipsis: true,
                render: ( value: string, record: Trade ) => {
                    return (
                        <div title={`Net R: ${ this.getFixedValueDisplay( record.net_R ) }`} style={{ color: record.net_PL && record.net_PL < 0 ? RED_COLOR : GREEN_COLOR }}>
                            {this.getFormattedInput( record.net_PL )}
                        </div>
                    )
                }
            },
            {
                title: 'Gross R',
                key: 'gross_R',
                dataIndex: 'gross_R',
                ellipsis: true,
                render: ( value: string, record: Trade ) => {
                    return (
                        <div className="bold" style={{ color: record.gross_R && record.gross_R < 0 ? RED_COLOR : GREEN_COLOR }}>
                            {this.getFixedValueDisplay( record.gross_R )}
                        </div>
                    )
                }
            },
            {
                title: 'Bar by bar',
                key: 'bar_by_bar_after_1R',
                dataIndex: 'bar_by_bar_after_1R',
                ellipsis: true,
                render: ( value: string, record: Trade ) => {
                    return (
                        <div style={record.bar_by_bar_after_1R > record.gross_R ? { color: TARGET_COLOR, fontWeight: 'bold' } : null}>
                            {this.getFixedValueDisplay( record.bar_by_bar_after_1R )}
                        </div>
                    )
                }
            },
            {
                title: 'Stop to BE',
                key: 'stop_to_BE_at_1R_tgt_2R',
                dataIndex: 'stop_to_BE_at_1R_tgt_2R',
                ellipsis: true,
                render: ( value: string, record: Trade ) => {
                    return (
                        <div style={record.stop_to_BE_at_1R_tgt_2R > record.gross_R ? { color: TARGET_COLOR, fontWeight: 'bold' } : null}>
                            {this.getFixedValueDisplay( record.stop_to_BE_at_1R_tgt_2R )}
                        </div>
                    )
                }
            },
            {
                title: 'AON 1R',
                key: 'AON_1_R',
                dataIndex: 'AON_1_R',
                ellipsis: true,
                render: ( value: string, record: Trade ) => {
                    return (
                        <div style={record.AON_1_R > record.gross_R ? { color: TARGET_COLOR, fontWeight: 'bold' } : null}>
                            {this.getFixedValueDisplay( record.AON_1_R )}
                        </div>
                    )
                }
            },
            {
                title: 'AON 1.5R',
                key: 'AON_1_5_R',
                dataIndex: 'AON_1_5_R',
                ellipsis: true,
                render: ( value: string, record: Trade ) => {
                    return (
                        <div style={record.AON_1_5_R > record.gross_R ? { color: TARGET_COLOR, fontWeight: 'bold' } : null}>
                            {this.getFixedValueDisplay( record.AON_1_5_R )}
                        </div>
                    )
                }
            },
            {
                title: 'AON 2R',
                key: 'AON_2_R',
                dataIndex: 'AON_2_R',
                ellipsis: true,
                render: ( value: string, record: Trade ) => {
                    return (
                        <div style={record.AON_2_R > record.gross_R ? { color: TARGET_COLOR, fontWeight: 'bold' } : null}>
                            {this.getFixedValueDisplay( record.AON_2_R )}
                        </div>
                    )
                }
            },
            {
                title: 'AON 3R',
                key: 'AON_3_R',
                dataIndex: 'AON_3_R',
                ellipsis: true,
                render: ( value: string, record: Trade ) => {
                    return (
                        <div style={record.AON_3_R > record.gross_R ? { color: TARGET_COLOR, fontWeight: 'bold' } : null}>
                            {this.getFixedValueDisplay( record.AON_3_R )}
                        </div>
                    )
                }
            }
        ]

        columns.push( {
            title: 'Actions',
            key: 'actions',
            dataIndex: 'actions',
            ellipsis: true,
            render: ( value: string, record: Trade ) => {
                return (
                    <>
                        <EditOutlined key="edittrade" onClick={() => this.editTrade( record )} />
                        <Divider type="vertical"></Divider>
                        <CloseOutlined key="removetrade" onClick={() => this.removeTrade( record )} />
                    </>
                )
            }
        } )

        return columns

    }

    private getFixedValueDisplay = ( value: number ) => {

        if ( value === null || value === undefined ) {
            return '-'
        }

        return value.toFixed( 2 )
    }

}

export default TradesTableView;