import React from 'react';
import { Table, Divider, Tag, Form, InputNumber } from 'antd';
import { ColumnProps } from 'antd/lib/table'
import { FormattedNumber, injectIntl } from 'react-intl'
import {
    ArrowRightOutlined
} from '@ant-design/icons';

/*Models*/
import { BudgetEntry, TaxBrackets } from '../../models/index'

/*Helpers*/
import * as TAXES_HELPER from '../../utils/taxes_calculation'

interface Props {
    entries: BudgetEntry[],
    shares: number
    childCount: number;
    taxCredit: number;
}

interface State {

}

const TAX_BRACKETS: TaxBrackets[] = [
    {
        min: 0,
        max: 11294,
        rate: 0
    },
    {
        min: 11295,
        max: 28797,
        rate: 11
    },
    {
        min: 28798,
        max: 82341,
        rate: 30
    },
    {
        min: 82342,
        max: 177106,
        rate: 41
    },
    {
        min: 177107,
        max: Infinity,
        rate: 45
    }
]

const PER_CHILD_REBATE = 1759

class TaxBracketsTable extends React.Component<Props, State> {

    render() {
        const { entries, shares, childCount, taxCredit } = this.props

        let childShares = 0

        if ( childCount > 0 ) {
            for ( let index = 1; index <= childCount; index++ ) {
                if ( index <= 2 ) {
                    childShares += 0.5
                } else {
                    childShares += 1
                }
            }
        }

        const theoreticalYearlyIncome = ( TAXES_HELPER.getYearlyIncomeSum( entries ) - TAXES_HELPER.getYearlyTaxAllowanceSum( entries ) ) / shares

        const baseHouseholdYearlyIncome = ( TAXES_HELPER.getYearlyIncomeSum( entries ) - TAXES_HELPER.getYearlyTaxAllowanceSum( entries ) ) / ( shares - childShares )

        const theoreticalTotalTaxes = TAX_BRACKETS.reduce( ( acc, bracket ) => {
            acc += TAXES_HELPER.getBracketTaxAmount( bracket, theoreticalYearlyIncome )
            return acc
        }, 0 )

        const baseHouseholdTotalTaxes = TAX_BRACKETS.reduce( ( acc, bracket ) => {
            acc += TAXES_HELPER.getBracketTaxAmount( bracket, baseHouseholdYearlyIncome )
            return acc
        }, 0 )

        let totalTaxesDiff = ( baseHouseholdTotalTaxes * ( shares - childShares ) ) - ( theoreticalTotalTaxes * shares )
        let taxRebate = 0

        if ( childCount > 0 ) {

            taxRebate = Math.min( totalTaxesDiff, ( childCount * PER_CHILD_REBATE ) )

        }

        return (
            < >
                <Table
                    dataSource={TAX_BRACKETS}
                    columns={this.getColumnsTax( baseHouseholdYearlyIncome )}
                    pagination={false}
                    summary={() => {
                        let avg_rate = ( baseHouseholdTotalTaxes * 100 / baseHouseholdYearlyIncome ).toFixed( 1 )

                        return (
                            <>
                                <Table.Summary.Row className="summary-row-background">
                                    <Table.Summary.Cell index={0}>Somme</Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}>
                                        <p className="text-small"><em>Pour 1 part</em></p>
                                        <p className="text-small"><em>Taux moyen <Tag color="orange">{avg_rate} %</Tag></em></p>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}>
                                        <FormattedNumber
                                            value={baseHouseholdTotalTaxes}
                                            style="currency"
                                            currency="EUR"
                                            currencyDisplay="symbol"
                                        />
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                                <Table.Summary.Row className="summary-row-background">
                                    <Table.Summary.Cell index={0}>Somme</Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}>
                                        <span className="text-small"><em>Pour le foyer</em></span>
                                        {taxCredit ? <p className="text-small"><em>Crédit d'impôt </em><Tag color="lime"><FormattedNumber
                                            value={taxCredit}
                                            style="currency"
                                            currency="EUR"
                                            currencyDisplay="symbol"
                                        /></Tag></p> : null}
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}>
                                        {childCount > 0 ? ( <p className="text-small"><em>Théorique </em><Tag color="magenta"><FormattedNumber
                                            value={Math.round( theoreticalTotalTaxes * shares ) - ( taxCredit || 0 )}
                                            style="currency"
                                            currency="EUR"
                                            currencyDisplay="symbol"
                                        /></Tag></p> ) : null}
                                        <Tag color="orange"> <FormattedNumber
                                            value={Math.round( baseHouseholdTotalTaxes * ( shares - childShares ) ) - ( taxCredit || 0 )}
                                            style="currency"
                                            currency="EUR"
                                            currencyDisplay="symbol"
                                        /></Tag>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                                {
                                    childCount > 0 ? (
                                        <>
                                            <Table.Summary.Row className="summary-row-background">
                                                <Table.Summary.Cell index={0}>Abbattement quotien familial</Table.Summary.Cell>
                                                <Table.Summary.Cell index={1}>
                                                    <span className="text-small"><em>{childCount} enfant à charge | {childShares} part{childShares > 1 ? 's' : ''}</em></span>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={2}>
                                                    <Tag color="lime"> <FormattedNumber
                                                        value={Math.round( taxRebate )}
                                                        style="currency"
                                                        currency="EUR"
                                                        currencyDisplay="symbol"
                                                    /></Tag>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                            <Table.Summary.Row className="summary-row-background">
                                                <Table.Summary.Cell index={0}>Impôt à payer</Table.Summary.Cell>
                                                <Table.Summary.Cell index={1}>

                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={2}>
                                                    <Tag color="orange"> <FormattedNumber
                                                        value={Math.round( ( baseHouseholdTotalTaxes * ( shares - childShares ) ) - taxRebate - ( taxCredit || 0 ) )}
                                                        style="currency"
                                                        currency="EUR"
                                                        currencyDisplay="symbol"
                                                    /></Tag>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        </>
                                    ) : null
                                }
                            </>
                        );
                    }}></Table>
            </>
        );
    }

    private getColumnsTax = ( yearlyIncome: number ): Array<ColumnProps<TaxBrackets>> => {
        return [
            {
                title: 'Tranche d\'imposition',
                key: 'range_tax',
                dataIndex: 'range_tax',
                render: ( value: string, record: TaxBrackets ) => {
                    return ( <> <FormattedNumber
                        value={record.min}
                        style="currency"
                        currency="EUR"
                        currencyDisplay="symbol"
                    />
                        <ArrowRightOutlined className="mgr-5 mgl-5" />
                        <FormattedNumber
                            value={record.max}
                            style="currency"
                            currency="EUR"
                            currencyDisplay="symbol"
                        />
                    </> );
                }
            },
            {
                title: 'Taux',
                key: 'rate',
                dataIndex: 'rate',
                render: ( value: string, record: TaxBrackets ) => {
                    return `${ record.rate }% `;
                }
            },
            {
                title: 'Montant',
                key: 'amount',
                dataIndex: 'amount',
                render: ( value: string, record: TaxBrackets ) => {
                    return ( <FormattedNumber
                        value={TAXES_HELPER.getBracketTaxAmount( record, yearlyIncome )}
                        style="currency"
                        currency="EUR"
                        currencyDisplay="symbol"
                    /> );
                }
            },
        ]
    }



}

export default TaxBracketsTable;