//modules
import axios, { AxiosRequestConfig } from 'axios'
import { notification } from 'antd'
import { webStorage } from './web_storage'

function IBRK_BASE_URL() { return `${ webStorage.getIBKRGateway() || '' }/v1/api` }

export const ENDPOINTS = {

    IBKR_SSO_VALIDATE: () => IBRK_BASE_URL() + '/sso/validate',
    IBKR_LOGOUT: () => IBRK_BASE_URL() + '/logout',
    IBKR_GET_ACCOUNTS: () => IBRK_BASE_URL() + '/iserver/accounts',
    IBKR_SEARCH_TICKER: () => IBRK_BASE_URL() + '/iserver/secdef/search',
    IBKR_GET_PORTFOLIO_POSITIONS: ( accountId: string, pageId: string ) => IBRK_BASE_URL() + `/portfolio/${ encodeURIComponent( accountId ) }/positions/${ encodeURIComponent( pageId ) }`,
    IBKR_GET_ORDERS: () => IBRK_BASE_URL() + '/iserver/account/orders',
    IBKR_PLACE_ORDER: ( accountId: string ) => IBRK_BASE_URL() + `/iserver/account/${ encodeURIComponent( accountId ) }/order`,
    IBKR_PLACE_ORDERS: ( accountId: string ) => IBRK_BASE_URL() + `/iserver/account/${ encodeURIComponent( accountId ) }/orders`,
    IBKR_PLACE_ORDER_REPLY: ( replyId: string ) => IBRK_BASE_URL() + `/iserver/reply/${ encodeURIComponent( replyId ) }`,
    IBKR_MODIFY_ORDER: ( accountId: string, orderId: string ) => IBRK_BASE_URL() + `/iserver/account/${ encodeURIComponent( accountId ) }/order/${ encodeURIComponent( orderId ) }`,
    IBKR_CANCEL_ORDER: ( accountId: string, orderId: string ) => IBRK_BASE_URL() + `/iserver/account/${ encodeURIComponent( accountId ) }/order/${ encodeURIComponent( orderId ) }`,
    IBKR_GET_TRADES: () => IBRK_BASE_URL() + '/iserver/account/trades',
}


//45s timeout on sent requests
const UI_REQUESTS_TIMEOUT = 45 * 1000

export async function get<T>( url: string, queryParams?: any, timeout?: number, responseType?: 'arraybuffer' | 'document' | 'json' | 'text' | 'stream' | 'blob', customHeaders?: any ) {

    let config: AxiosRequestConfig = {
        params: queryParams,
        timeout: timeout ? timeout : UI_REQUESTS_TIMEOUT
    }

    if ( responseType ) {
        config.responseType = responseType
    }

    if ( customHeaders ) {
        config.headers = customHeaders
    }

    return axios.get<T>( url, config )
}

export async function getFile( url: string, queryParams?: any ) {

    let config: AxiosRequestConfig = {
        responseType: 'blob',
        params: queryParams,
        timeout: UI_REQUESTS_TIMEOUT
    }

    return axios.get( url, config )
}

export async function post<T>( url: string, bodyParams?: any, queryParams?: any, timeout?: number ) {

    let config: AxiosRequestConfig = {
        params: queryParams,
        timeout: timeout ? timeout : UI_REQUESTS_TIMEOUT
    }

    return axios.post<T>( url, bodyParams, config )
}

export async function put<T>( url: string, bodyParams?: any, queryParams?: any, timeout?: number ) {

    let config: AxiosRequestConfig = {
        params: queryParams,
        timeout: timeout ? timeout : UI_REQUESTS_TIMEOUT
    }

    return axios.put<T>( url, bodyParams, config )
}

export async function del<T>( url: string, queryParams?: any ) {

    let config: AxiosRequestConfig = {
        params: queryParams,
        timeout: UI_REQUESTS_TIMEOUT
    }

    return axios.delete<T>( url, config )
}

export function handleError( error: any, message?: string, left?: boolean ) {

    if ( error && error.response ) {

        console.warn( 'CATCHED ERROR:', JSON.parse( JSON.stringify( error ) ) )

        const errorType = error.response.statusText ? error.response.statusText : 'Une erreur est survenue'
        const errorStatus = error.response.status ? error.response.status : ''
        const statusDisplay = errorStatus ? ' - HTTP ' + errorStatus : ''

        let errMsg = null

        if ( error.response.data && error.response.data.message ) {
            errMsg = error.response.data.message
        }


        if ( error.response.status !== 503 ) {
            notification.error( {
                message: message ? 'Oups !' : `${ 'Une erreur est survenue' } (${ errorType }${ statusDisplay })`,
                description: message || errMsg,
                placement: left ? 'topLeft' : null
            } )
        }

    } else {

        if ( !error || error.toString().indexOf( 'Network Error' ) === -1 || error.message ) {
            notification.error( {
                message: 'Une erreur est survenue',
                description: error && error.message ? error.message : ( message || error || 'Une erreur est survenue' ).toString(),
                placement: left ? 'topLeft' : null
            } )
        }

    }
}

export function showSuccess( message: string, duration?: number ) {

    notification.success( {
        message: 'Info',
        description: message,
        duration
    } )
}

export function later( delay: number ) {
    return new Promise( function ( resolve ) {
        setTimeout( resolve, delay )
    } )
}

export async function retryCall( call: () => void, n: number, delay: number, retryFailErrorMessage: string, errorCallback?: ( error: any ) => void ): Promise<any> {

    try {

        return await call()

    } catch ( err ) {

        if ( errorCallback ) {
            errorCallback( err )
        }

        if ( n === 1 || ( err && err.stopRetry ) ) {
            throw retryFailErrorMessage
        }

        await later( delay )

        return await retryCall( call, n - 1, delay, retryFailErrorMessage, errorCallback )
    }
}