import React from 'react';
import { PageHeader, Space, Statistic } from 'antd';
import * as uuid from 'uuid';
import {
    DownloadOutlined, UploadOutlined
} from '@ant-design/icons';

/*Utils*/
import { GREEN_COLOR, RED_COLOR } from '../../utils/helpers';

/*Models*/
import { BudgetEntry, BudgetTypeEnum, BudgetResult } from '../../models/index'

/*Components*/
import EntryEditor from './entry_editor'

interface Props {
    entryType: BudgetTypeEnum;
    entries: BudgetEntry[];
    sumValue: number;
    saveEntries: ( entries: BudgetResult ) => void;
}

interface State {
    sumValue: number;
}

class EntryLayout extends React.Component<Props, State> {

    constructor( props: Props ) {
        super( props );

        this.state = {
            sumValue: props.sumValue
        }
    }

    private saveEntries = ( entries: BudgetEntry[] ) => {
        const { entryType } = this.props
        let result = this.getSum( entries )
        this.setState( {
            sumValue: result
        } )

        this.props.saveEntries( {
            entries: entries,
            entryType: entryType,
            sumValue: result
        } )
    }

    private getSum = ( entries: BudgetEntry[] ) => {
        const { entryType } = this.props

        let data = entries.filter( entry => entry.type === entryType )
        return data.reduce( ( acc, entry ) => {

            let value = entry.value

            acc = acc + ( entry.yearly ? value / 12 : value )
            return acc
        }, 0 )
    }

    render() {

        const { entries, entryType } = this.props
        const { sumValue } = this.state

        const data = entries.filter( entry => entry.type === entryType )

        return (
            < >
                <Space direction="vertical" className="entry-layout">
                    <div className="page-header-entry-layout">
                        <PageHeader
                            title={this.isTaxInputOrIncome( entryType ) ? "Revenus" : "Dépenses"}
                            extra={[
                                <Statistic
                                    title={this.isTaxInputOrIncome( entryType ) ? "Revenus" : "Dépenses"}
                                    value={sumValue}
                                    precision={2}
                                    valueStyle={{ color: this.isTaxInputOrIncome( entryType ) ? GREEN_COLOR : RED_COLOR }}
                                    prefix={this.isTaxInputOrIncome( entryType ) ? <DownloadOutlined /> : <UploadOutlined />}
                                    suffix="€"
                                />
                            ]}>
                        </PageHeader>
                    </div>

                    <EntryEditor entries={data} entryType={entryType} save={this.saveEntries}></EntryEditor>
                </Space>
            </>
        );
    }

    private isTaxInputOrIncome = ( type: BudgetTypeEnum ) => {
        return type === BudgetTypeEnum.Income || type === BudgetTypeEnum.TaxInput
    }

}

export default EntryLayout;