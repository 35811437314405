import React from 'react';
import { Row, Col, Tag, Statistic, Divider, Descriptions, Drawer } from 'antd';
import moment from 'moment';
import { FormattedNumber } from 'react-intl'
import {
    PlusCircleTwoTone,
    MinusCircleTwoTone
} from '@ant-design/icons';

/*Models*/
import { Trade, Position } from '../../models';

//utils
import { DATE_FORMAT, DATE_TIME_FORMAT, getTradeExitTargetPrice, GREEN_COLOR, RED_COLOR, TARGET_COLOR } from '../../utils/helpers';

interface Props {
    trade: Trade,
    visible: boolean,
    onCloseViewDetails: () => void;
}

interface State {
}

class TradeDetailsView extends React.Component<Props, State> {

    constructor( props: Props ) {
        super( props );
    }

    getPositionTag( trade: Trade ) {
        if ( trade.position ) {
            if ( trade.position === Position.Long )
                return <Tag color="cyan">LONG</Tag>;
            else
                return <Tag color="orange">SHORT</Tag>;
        }
        else
            return;
    }

    getBooleanTag( value: boolean ) {
        if ( value )
            return <Tag color="green">YES</Tag>;
        else
            return <Tag color="volcano">NO</Tag>;
    }

    render() {

        const { trade, visible } = this.props

        return ( <div>
            <Drawer
                width={window.innerWidth < 992 ? '80%' : '50%'}
                placement="right"
                closable={false}
                onClose={this.props.onCloseViewDetails}
                visible={visible}>

                <Descriptions title="Trade Info" bordered className="mgb-20" size="small">
                    <Descriptions.Item label="Ticker"><strong>{trade.ticker}</strong></Descriptions.Item>
                    <Descriptions.Item label="Position" span={2}>{this.getPositionTag( trade )}</Descriptions.Item>
                    {
                        trade.planned_trade ? (
                            <Descriptions.Item label="Start" >{moment( trade.start_at ).format( DATE_FORMAT )}</Descriptions.Item>
                        ) : (
                                <>
                                    <Descriptions.Item label="Start" >{moment( trade.start_at ).format( DATE_TIME_FORMAT )}</Descriptions.Item>
                                    <Descriptions.Item label="End" span={2}>{moment( trade.end_at ).format( DATE_TIME_FORMAT )}</Descriptions.Item>
                                    <Descriptions.Item label="Duration" span={3}>{moment.duration( trade.duration, 's' ).humanize()}</Descriptions.Item>
                                </>
                            )
                    }
                </Descriptions>

                <Divider plain />

                <div className="ant-descriptions-title mgb-20">Details</div>

                <Row justify="start" gutter={[30, 15]}>
                    <Col xs={8} lg={6}><Statistic title="Quantity" value={trade.qty} /></Col>
                    <Col xs={8} lg={6}><Statistic title="Entry price" value={trade.avg_entry_price} precision={2} suffix="$" /></Col>
                    {trade.planned_trade ? null : <Col xs={8} lg={6}><Statistic title="Exit price" value={trade.avg_exit_price} precision={2} suffix="$" /></Col>}
                </Row>

                <Row justify="start" gutter={[30, 15]}>
                    <Col xs={8} lg={6}><Statistic title="Stop price" value={trade.stop_price} precision={2} suffix="$" /></Col>
                    <Col xs={8} lg={6}><Statistic title="Stop size" value={trade.stop_size} precision={2} suffix="$" /></Col>
                    <Col xs={8} lg={6}><Statistic title="Risk" value={trade.risk} precision={2} suffix="$" /></Col>
                    {
                        trade.planned_trade ? <Col xs={8} lg={6}><Statistic title="Target" value={getTradeExitTargetPrice( trade, 1.5 )} precision={2} valueStyle={{ color: TARGET_COLOR, fontWeight: 'bold' }} suffix="$" /></Col> : null
                    }
                </Row>

                {
                    trade.planned_trade ? null : (
                        <Row justify="start" gutter={[30, 15]}>
                            <Col xs={8} lg={6}><Statistic title="Gross P/L" value={trade.gross_PL} precision={2} suffix="$" valueStyle={{ color: trade.gross_PL && trade.gross_PL < 0 ? RED_COLOR : GREEN_COLOR }} /></Col>
                            <Col xs={8} lg={6}><Statistic title="Net P/L" value={trade.net_PL} precision={2} suffix="$" valueStyle={{ fontWeight: 'bold', color: trade.net_PL && trade.net_PL < 0 ? RED_COLOR : GREEN_COLOR }} /></Col>
                            <Col xs={8} lg={6}><Statistic title="Gross R" value={trade.gross_R} precision={2} valueStyle={{ color: trade.gross_R && trade.gross_R < 0 ? RED_COLOR : GREEN_COLOR }} /></Col>
                            <Col xs={8} lg={6}><Statistic title="Net R" value={trade.net_R} precision={2} valueStyle={{ fontWeight: 'bold', color: trade.net_R && trade.net_R < 0 ? RED_COLOR : GREEN_COLOR }} /></Col>
                        </Row>
                    )
                }

                <Divider plain />

                <Descriptions title="Setup" bordered className="mgb-20" size="small">
                    {trade.planned_trade ? null : <Descriptions.Item label="Fees" span={3}><FormattedNumber value={trade.fees} style="currency" currency="USD" currencyDisplay="narrowSymbol" /></Descriptions.Item>}
                    <Descriptions.Item label="Setup">{trade.setup}</Descriptions.Item>
                    <Descriptions.Item label="Time frame" span={2}>{trade.time_frame}</Descriptions.Item>
                    <Descriptions.Item label="With daily trend ?" >{this.getBooleanTag( trade.with_daily ? trade.with_daily : false )}</Descriptions.Item>
                    <Descriptions.Item label="With market trend ?" span={2}>{this.getBooleanTag( trade.with_mkt ? trade.with_mkt : false )}</Descriptions.Item>
                    <Descriptions.Item label="Comments" span={3} className="italic">{trade.comments}</Descriptions.Item>
                </Descriptions>

                {
                    trade.planned_trade ? null : (
                        <Descriptions title="Trade management tracking" bordered className="mgb-20" column={2} size="small">
                            <Descriptions.Item label="Bar by bar after 1R">
                                {this.getTradeManagementValueDisplay( trade.bar_by_bar_after_1R )}
                            </Descriptions.Item>
                            <Descriptions.Item label="Stop to BE @1R (2R TGT)">
                                {this.getTradeManagementValueDisplay( trade.stop_to_BE_at_1R_tgt_2R )}
                            </Descriptions.Item>
                            <Descriptions.Item label="AON 1R">
                                {this.getTradeManagementValueDisplay( trade.AON_1_R )}
                            </Descriptions.Item>
                            <Descriptions.Item label="AON 1.5R" >
                                {this.getTradeManagementValueDisplay( trade.AON_1_5_R )}
                            </Descriptions.Item>
                            <Descriptions.Item label="AON 2R">
                                {this.getTradeManagementValueDisplay( trade.AON_2_R )}
                            </Descriptions.Item>
                            <Descriptions.Item label="AON 3R" >
                                {this.getTradeManagementValueDisplay( trade.AON_3_R )}
                            </Descriptions.Item>
                        </Descriptions>
                    )
                }

                {
                    trade.planned_trade ? null : (
                        <Descriptions title="Entry details" bordered className="mgb-20" size="small">
                            <Descriptions.Item label="Quantity">{trade.entry_qty}</Descriptions.Item>
                            <Descriptions.Item label="Price" span={2}><FormattedNumber value={trade.entry_price} style="currency" currency="USD" currencyDisplay="narrowSymbol" /></Descriptions.Item>
                            {trade.entry_qty_2 > 0 && <Descriptions.Item label="Quantity 2" >{trade.entry_qty_2}</Descriptions.Item>}
                            {trade.entry_price_2 > 0 && <Descriptions.Item label="Price 2" span={2}><FormattedNumber value={trade.entry_price_2} style="currency" currency="USD" currencyDisplay="narrowSymbol" /></Descriptions.Item>}
                            {trade.entry_qty_3 > 0 && <Descriptions.Item label="Quantity 3" >{trade.entry_qty_3}</Descriptions.Item>}
                            {trade.entry_price_3 > 0 && <Descriptions.Item label="Price 3" span={2}><FormattedNumber value={trade.entry_price_3} style="currency" currency="USD" currencyDisplay="narrowSymbol" /></Descriptions.Item>}
                        </Descriptions>
                    )
                }

                {
                    trade.planned_trade ? null : (
                        <>
                            <Descriptions title="Exit details" bordered className="mgb-20" size="small">
                                <Descriptions.Item label="Quantity">{trade.exit_qty}</Descriptions.Item>
                                <Descriptions.Item label="Price" span={2}><FormattedNumber value={trade.exit_price} style="currency" currency="USD" currencyDisplay="narrowSymbol" /></Descriptions.Item>
                                {trade.exit_qty_2 > 0 && <Descriptions.Item label="Quantity 2" >{trade.exit_qty_2}</Descriptions.Item>}
                                {trade.exit_price_2 > 0 && <Descriptions.Item label="Price 2" span={2}><FormattedNumber value={trade.exit_price_2} style="currency" currency="USD" currencyDisplay="narrowSymbol" /></Descriptions.Item>}
                                {trade.exit_qty_3 > 0 && <Descriptions.Item label="Quantity 3" >{trade.exit_qty_3}</Descriptions.Item>}
                                {trade.exit_price_3 > 0 && <Descriptions.Item label="Price 3" span={2}><FormattedNumber value={trade.exit_price_3} style="currency" currency="USD" currencyDisplay="narrowSymbol" /></Descriptions.Item>}
                            </Descriptions>
                        </>
                    )
                }

            </Drawer>
        </div > );
    };

    private getTradeManagementValueDisplay = ( managementValue: number | undefined ) => {

        const { trade } = this.props

        if ( managementValue !== null && managementValue !== undefined ) {

            return (
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline' }}>
                    {
                        trade.gross_R !== undefined ? ( trade.gross_R < managementValue ? <PlusCircleTwoTone twoToneColor={GREEN_COLOR} style={{ marginRight: 12, fontSize: 16 }} /> : <MinusCircleTwoTone twoToneColor={RED_COLOR} style={{ marginRight: 12, fontSize: 16 }} /> ) : null
                    }
                    <Statistic value={managementValue} precision={2} />
                </div>
            )

        }

        return '-'

    }
}

export default TradeDetailsView;