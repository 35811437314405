import { Position, Trade } from "../models";

export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';

export const TARGET_COLOR = '#13c2c2';
export const GREEN_COLOR = '#52c41a';
export const RED_COLOR = '#fa541c';
export const WARNING_COLOR = '#f39c12';

export function getTradeExitTargetPrice( trade: Trade, target_R: number ) {

    if ( trade.avg_entry_price && trade.stop_size ) {

        if ( trade.position === Position.Long ) {
            return trade.avg_entry_price + ( target_R * ( trade.stop_size as number ) )
        } else {
            return trade.avg_entry_price - ( target_R * ( trade.stop_size as number ) )
        }
    }

    return 0

}

export function getQueryParams( searchString: string ): any {
    if ( !searchString ) {
        return {}
    }

    let queryStringParams = searchString.substr( 1 ).split( '&' )

    let queryParams = queryStringParams.reduce( ( acc, queryStrParam ) => {
        let keyValue = queryStrParam.split( '=' )

        if ( keyValue.length === 2 ) {
            ( acc as any )[keyValue[0]] = decodeURIComponent( keyValue[1] )
        }

        return acc
    }, {} )

    return queryParams
}

//Code from Dropbox SDK
export function parseQueryString( hashValue: string ) {
    const ret = Object.create( null );

    if ( typeof hashValue !== 'string' ) {
        return ret;
    }

    hashValue = hashValue.trim().replace( /^(\?|#|&)/, '' );

    if ( !hashValue ) {
        return ret;
    }

    hashValue.split( '&' ).forEach( ( param ) => {
        const parts = param.replace( /\+/g, ' ' ).split( '=' );

        let key = parts.shift();
        let val = parts.length > 0 ? parts.join( '=' ) : undefined;

        key = decodeURIComponent( key );

        val = val === undefined ? null : decodeURIComponent( val );

        if ( ret[key] === undefined ) {
            ret[key] = val;
        } else if ( Array.isArray( ret[key] ) ) {
            ret[key].push( val );
        } else {
            ret[key] = [ret[key], val];
        }
    } );

    return ret;
}


export function saveDataAsJSONFile( data: any, fileName: string ): void {
    let json = JSON.stringify( data, undefined, '\t' )

    let blob = new Blob( [json], {
        type: 'application/json'
    } )

    triggerDataDownload( blob, fileName + '_' + ( new Date().toISOString().substr( 0, 19 ) ) + '.json' )
}

export function downloadDataFile( base64DataString: string, contentType: string, fileName: string ): void {
    const b64String = 'data:' + contentType + ';base64,' + base64DataString
    triggerDataDownload( b64String, fileName, true )
}

function triggerDataDownload( data: Blob | string, fileName: string, dataUrl?: boolean ): void {
    let url = dataUrl ? data as string : URL.createObjectURL( data as any )

    //create a download link
    let link = document.createElement( 'a' )
    link.href = url
    link['download'] = fileName

    //trigger the download
    link.click()

    URL.revokeObjectURL( url )
}

export function saveDataAsCSVFile( data: any, fileName: string ): void {

    let blob = new Blob( [data], {
        type: 'text/csv'
    } )

    triggerDataDownload( blob, fileName + '_' + ( new Date().toISOString().substr( 0, 19 ) ) + '.csv' )
}