import React from 'react';
import { InputNumber, Button, Row, Col, Tag } from 'antd';
import * as uuid from 'uuid';
import {
    CloseCircleOutlined
} from '@ant-design/icons';
import Num from 'big.js'

import classNames from 'classnames'

/*Models*/
import { IBKRTicker, IBKROrderRequest } from '../../models/index'
import { GREEN_COLOR, RED_COLOR } from 'src/utils/helpers';

interface Props {
    ticker: IBKRTicker;
    RUnit: number;
    RTarget: number;
    submitOrders: ( orderConfig: IBKROrderRequest[] ) => void;
    cancel: () => void;
}

interface State {
    entry: number;
    stop: number;
    status: 'OK' | string;
    side: 'LONG' | 'SHORT';
    parentOrder: IBKROrderRequest;
    childrenOrders: IBKROrderRequest[];
}

class OrdersGenerator extends React.Component<Props, State> {

    constructor( props: Props ) {
        super( props );

        this.state = {
            entry: null,
            stop: null,
            side: null,
            status: null,
            parentOrder: null,
            childrenOrders: []
        }
    }


    render() {

        const { ticker } = this.props
        const { entry, stop } = this.state

        return (
            <div className="rel bordered pad-10">

                <div className="light-bg pad-10 mgb-10">
                    <Row justify="space-between">
                        <Col>
                            <div>
                                <span className="font-120 bold">{ticker.symbol}</span>
                                <span className="font-80 mgl-10 colored">{ticker.description}</span>
                            </div>
                            <div className="font-80"><em>{ticker.companyHeader}</em></div>
                        </Col>
                        <Col>
                            <span className="mgl-30"><CloseCircleOutlined onClick={this.props.cancel} className="clickable clickable-main" style={{ fontSize: 20 }} /></span>
                        </Col>
                    </Row>
                </div>

                <Row justify="space-around" align="middle">
                    <Col className="text-center">
                        <div className="medium-label" style={{ marginBottom: 3 }}>Entry</div>
                        <InputNumber
                            name="entry"
                            precision={2} step={0.01} min={0}
                            size="large"
                            onChange={( value: any ) => { this.setState( { entry: value }, this.prepareTrade ) }}
                            style={{ width: 120 }}
                        />
                    </Col>
                    <Col className="text-center">
                        <div className="inline-block text-center">
                            <div className="medium-label" style={{ marginBottom: 3 }}>Stop Loss</div>
                            <InputNumber
                                name="stop"
                                precision={2} step={0.01} min={0}
                                size="large"
                                onChange={( value: any ) => { this.setState( { stop: value }, this.prepareTrade ) }}
                                style={{ width: 120 }}
                            />
                        </div>
                        {
                            !!entry && !!stop ? (
                                <div className="inline-block text-center mgl-20 ">
                                    <div className="small-label" style={{ marginBottom: 3 }}>Stop Size</div>
                                    <span>${Num( entry ).minus( stop ).abs().round( 2 ).toNumber().toFixed( 2 )}</span>
                                </div>
                            ) : null
                        }
                    </Col>
                    <Col className="text-center">
                        <div className="medium-label" style={{ marginBottom: 3 }}>Position</div>
                        <Tag onClick={() => this.setTradeSide( 'LONG' )} color="cyan" className="upper bold clickable" style={{ padding: 9, margin: 0, width: 100, fontSize: 16 }}>LONG</Tag>
                        <span style={{ margin: '0 3px' }} />
                        <Tag onClick={() => this.setTradeSide( 'SHORT' )} color="orange" className="upper bold clickable" style={{ padding: 9, margin: 0, width: 100, fontSize: 16 }}>SHORT</Tag>
                    </Col>
                </Row>

                {this.getOrderRequestDisplay()}

            </div>
        )
    }

    private setTradeSide = ( side: 'LONG' | 'SHORT' ) => {

        this.setState( {
            side
        }, this.prepareTrade )

    }

    private prepareTrade = () => {

        const { entry, stop, side } = this.state

        if ( !side ) {
            return this.setState( {
                status: null
            } )
        }

        if ( !entry || !stop || !side ) {

            return this.setState( {
                status: 'Missing parameters'
            } )

        } else {

            if ( side === 'LONG' && ( stop > entry ) ) {
                return this.setState( {
                    status: 'Error: stop > entry'
                } )
            }

            if ( side === 'SHORT' && ( stop < entry ) ) {
                return this.setState( {
                    status: 'Error: stop < entry'
                } )
            }

            return this.setUpOrders()

        }

    }

    private setUpOrders = () => {

        const { RUnit, RTarget, ticker } = this.props
        const { entry, stop, side } = this.state

        const inSide = side === 'LONG' ? 'BUY' : 'SELL'
        const outSide = side === 'LONG' ? 'SELL' : 'BUY'

        const entryStpPrice = Num( entry )

        const stopPrice = Num( stop )

        if ( inSide !== outSide ) {

            const stopSize = entryStpPrice.minus( stopPrice ).abs()

            //10% slippage allowed (based on stop loss size)
            const slippage = stopSize.div( 10 )

            const extendedStopSize = stopSize.plus( slippage ).round( 2 )

            const shares = Num( RUnit ).div( extendedStopSize ).round( 0, 0 )

            if ( shares.toNumber() === 0 ) {
                return this.setState( {
                    status: 'Error: Stop size + slippage > Risk'
                } )
            }

            const entryLimitPrice = inSide === 'BUY' ? entryStpPrice.plus( slippage ) : entryStpPrice.minus( slippage )

            const points = Num( RTarget ).times( stopSize )

            const targetPrice = inSide === 'BUY' ? entryStpPrice.plus( points ) : entryStpPrice.minus( points )

            const parentOrderId = uuid.v4()

            let parentOrder: IBKROrderRequest = {
                conid: ticker.conid,
                orderType: 'Stop Limit',
                price: entryLimitPrice.round( 2 ).toNumber(),
                auxPrice: entryStpPrice.round( 2 ).toNumber(),
                quantity: shares.toNumber(),
                secType: 'STK',
                side: inSide,
                ticker: ticker.symbol,
                tif: 'DAY',
                useAdaptive: false,
                cOID: parentOrderId
            }

            let childrenOrders: IBKROrderRequest[] = [
                {
                    conid: ticker.conid,
                    parentId: parentOrderId,
                    orderType: 'Limit',
                    price: targetPrice.round( 2 ).toNumber(),
                    quantity: shares.toNumber(),
                    secType: 'STK',
                    side: outSide,
                    ticker: ticker.symbol,
                    tif: 'DAY',
                    useAdaptive: false,
                },
                {
                    conid: ticker.conid,
                    parentId: parentOrderId,
                    orderType: 'Stop',
                    price: stopPrice.round( 2 ).toNumber(),
                    quantity: shares.toNumber(),
                    secType: 'STK',
                    side: outSide,
                    ticker: ticker.symbol,
                    tif: 'DAY',
                    useAdaptive: false,
                }
            ]

            return this.setState( {
                parentOrder,
                childrenOrders,
                status: 'OK'
            } )

        }

        return this.setState( {
            status: 'Error: order setup issue'
        } )

    }

    private getOrderRequestDisplay = () => {

        const { status, parentOrder, childrenOrders } = this.state

        if ( !status ) {
            return null
        }

        if ( status === 'OK' ) {
            return (
                <div className="text-center">

                    <div className="mgt-20 mgb-10 bordered inline-block text-left" style={{ padding: '20px 40px' }}>

                        <div>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Tag className="mgr-10 upper text-center" style={{ width: 60 }} color={parentOrder.side === 'BUY' ? 'cyan' : 'orange'}>{parentOrder.side}</Tag>
                                <span className="mgr-10 bold font-110">{parentOrder.quantity}</span>
                                <span className="mgr-10 font-110">{parentOrder.ticker}</span>
                                <span className="mgr-10 upper font-90"><em>{parentOrder.orderType}</em></span>
                                <span className="mgr-5 bold colored font-110">${Num( parentOrder.auxPrice ).round( 2 ).toNumber().toFixed( 2 )}</span>
                                <span className="mgr-5">→</span>
                                <span className="mgr-10">${Num( parentOrder.price ).round( 2 ).toNumber()}</span>
                            </div>

                            <div style={{ borderLeft: '1px dashed #ccc', paddingLeft: 8, paddingTop: 15, paddingBottom: 2, marginLeft: 15 }}>

                                {
                                    childrenOrders.map( ( order, idx ) => {
                                        return (
                                            <div key={idx} style={{ display: 'flex', alignItems: 'center' }} className={classNames( {
                                                'mgb-5': idx < childrenOrders.length - 1
                                            } )}>
                                                <Tag className="mgr-10 upper text-center" color={order.side === 'BUY' ? 'cyan' : 'orange'}>{order.side}</Tag>
                                                <span className="mgr-10 bold font-110">{order.quantity}</span>
                                                <span className="mgr-10 font-110">{order.ticker}</span>
                                                <span className="mgr-10 upper font-90"><em>{order.orderType}</em></span>
                                                <span className="mgr-10 bold colored font-110" style={{ color: order.orderType === 'Stop' ? RED_COLOR : GREEN_COLOR }}>${Num( order.price ).round( 2 ).toNumber().toFixed( 2 )}</span>
                                            </div>
                                        )
                                    } )
                                }

                            </div>

                        </div>

                        <div className="text-right" style={{ marginTop: 10 }}>
                            <Button onClick={this.submitOrders}>Submit</Button>
                        </div>

                    </div>
                </div>
            )
        }

        return (
            <div className="mgt-20 mgb-10 pad-10 bordered text-center red-color bold">
                {status}
            </div>
        )

    }

    private submitOrders = () => {

        this.props.submitOrders( [this.state.parentOrder, ...this.state.childrenOrders] )

    }

}

export default OrdersGenerator;