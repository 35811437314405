import React, { useState } from 'react';
import { Table, Button, Popconfirm, Typography, Input, InputNumber, Row, Col, Select, Tag, Space, Divider, PageHeader } from 'antd';
import {
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table'
import * as uuid from 'uuid';
import { FormattedNumber } from 'react-intl'

/*Models*/
import { ProductDetail, Product, PayloadString, InputType } from '../../models/index'

/*Components*/
import ProductCostGlobal from './product_cost_gobal'
import ProductCostEditModal from './product_cost_edit_modal'

const { Option } = Select;
const { Text } = Typography;
const { Summary } = Table;

export interface Props {
    dataKey: string;
    productDetails: Product;
    editProduct: ( value: PayloadString ) => void;
    addProductItem: ( dataKey: string, product: ProductDetail ) => void;
    editProductItem: ( dataKey: string, product: ProductDetail ) => void;
    setProductPotentialPrice: ( dataKey: string, value: number ) => void;
    setProductTotalItems: ( dataKey: string, value: number ) => void;
    deleteProductItem: ( dataKey: string, product: ProductDetail ) => void;
}

export interface State {
    editedProduct: ProductDetail | null;
    visible: boolean;
}

interface ColumnPropsEditable<T> extends ColumnProps<T> {
    editable?: boolean;
}

class ProductCostTable extends React.Component<Props, State> {

    constructor( props: Props ) {
        super( props );
        this.state = {
            visible: false,
            editedProduct: null
        }

    }

    private isEditing = ( record: ProductDetail ) => this.state.editedProduct && record.id === this.state.editedProduct.id;

    private getInput = ( name: string, inputType: InputType, initialValue: string | number, editedValue: string | number | null, record: ProductDetail ): React.ReactNode => {
        const editable = this.isEditing( record );

        if ( editable ) {
            switch ( inputType ) {
                case InputType.Input:
                    return (
                        <Input type="text" name={name} value={editedValue as string} onChange={event => this.handleProductChange( event.target.value, name )} />
                    );

                case InputType.InputNumber:
                    return (
                        <InputNumber name={name} min={0} defaultValue={1} value={editedValue as number} onChange={value => this.handleProductChange( value, name )} />
                    );

                case InputType.Select:
                    return (
                        <Select value={editedValue as string} onChange={value => this.handleProductChange( value, name )}>
                            <Option value="u">Unité</Option>
                            <Option value="gr">Gramme</Option>
                            <Option value="ml">Millilitre</Option>
                            <Option value="m">Mètre</Option>
                        </Select>
                    );

            }
        } else {
            return ( initialValue );
        }
    }

    private getColumns = (): Array<ColumnPropsEditable<ProductDetail>> => {

        const { editedProduct } = this.state

        return [
            {
                title: 'Nom',
                key: 'name',
                dataIndex: 'name',
                editable: true,
                render: ( value: string, record: ProductDetail ) => {
                    return this.getInput( "name", InputType.Input, record.name, editedProduct?.name as string, record );
                }
            },
            {
                title: 'Conditionnement',
                key: 'packaging',
                dataIndex: 'packaging',
                editable: true,
                render: ( value: string, record: ProductDetail ) => {
                    return this.getInput( "packaging", InputType.InputNumber, record.packaging, editedProduct?.packaging as number, record );
                }
            },
            {
                title: 'Unité',
                key: 'unit',
                dataIndex: 'unit',
                editable: true,
                render: ( value: string, record: ProductDetail ) => {
                    return this.getInput( "unit", InputType.Select, record.unit, editedProduct?.unit as string, record );
                }
            },
            {
                title: 'Prix',
                key: 'price',
                dataIndex: 'price',
                editable: true,
                render: ( value: string, record: ProductDetail ) => {
                    return this.getInput( "price", InputType.InputNumber, record.price, editedProduct?.price as number, record );
                }
            },
            {
                title: 'Prix à l\'unité',
                key: 'unit_price',
                dataIndex: 'unit_price',
                render: ( value: string, record: ProductDetail ) => {
                    return ( <FormattedNumber
                        value={editedProduct && editedProduct.id === record.id ? this.getUnitPrice( editedProduct.price, editedProduct.packaging ) : this.getUnitPrice( record.price, record.packaging )}
                        style="currency"
                        currency="EUR"
                        currencyDisplay="symbol"
                        maximumFractionDigits={4}
                    /> );
                }
            },
            {
                title: 'Quantité par produit',
                key: 'quantity_per_product',
                dataIndex: 'quantity_per_product',
                editable: true,
                render: ( value: string, record: ProductDetail ) => {
                    return this.getInput( "quantity_per_product", InputType.InputNumber, record.quantity_per_product, editedProduct?.quantity_per_product as number, record );
                }
            },
            {
                title: 'Coût',
                key: 'cost',
                render: ( value: string, record: ProductDetail ) => {
                    return ( <Tag color="cyan"><FormattedNumber
                        value={editedProduct && editedProduct.id === record.id ? this.getCost( editedProduct.quantity_per_product, editedProduct.price, editedProduct.packaging ) : this.getCost( record.quantity_per_product, record.price, record.packaging )}
                        style="currency"
                        currency="EUR"
                        currencyDisplay="symbol"
                        maximumFractionDigits={4}
                    /></Tag> );
                }
            },
            {
                title: 'Commentaire',
                key: 'comment',
                dataIndex: 'comment',
                editable: true,
                render: ( value: string, record: ProductDetail ) => {
                    return this.getInput( "comment", InputType.Input, record.comment, editedProduct?.comment as string, record );
                }
            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: 'action',
                render: ( _, record: ProductDetail ) => {

                    const editable = this.isEditing( record );
                    return editable ? (
                        <span>
                            <a
                                onClick={() => this.save()}
                                style={{
                                    marginRight: 8,
                                }}
                            >
                                Sauver
                        </a>
                            <Popconfirm title="Voulez-vous annuler ?" onConfirm={this.cancel}>
                                <a>Annuler</a>
                            </Popconfirm>
                        </span>
                    ) : (
                            <div>
                                <Space>
                                    <Button shape="circle" onClick={() => this.edit( record )} disabled={!!editedProduct} icon={<EditOutlined />} />
                                    <Popconfirm title="Souhaitez-vous supprimer la ligne ?" onConfirm={() => this.deleteItem( record )}>
                                        <Button shape="circle" disabled={!!editedProduct} icon={<DeleteOutlined />} />
                                    </Popconfirm>
                                </Space>
                            </div>
                        );
                },
            }
        ]
            ;
    }

    private deleteItem = ( record: ProductDetail ) => {
        this.props.deleteProductItem( this.props.dataKey, record );
    }

    private handleProductChange = ( value: any, name: string ) => {

        const { editedProduct } = this.state

        this.setState( {
            editedProduct: Object.assign( {}, editedProduct, {
                [name]: value
            } )
        } )

    }

    private edit = ( record: ProductDetail ) => {
        this.setState( { editedProduct: record } );
    }

    private cancel = () => {
        this.setState( { editedProduct: null } );
    }

    private save = async () => {

        if ( this.state.editedProduct?.id === 'new' ) {
            this.props.addProductItem( this.props.dataKey, Object.assign( {}, this.state.editedProduct, { id: uuid.v4() } ) );
        } else {
            this.props.editProductItem( this.props.dataKey, Object.assign( {}, this.state.editedProduct ) );
        }

        this.setState( { editedProduct: null } );
    }

    private handleAdd = () => {

        let newProduct: ProductDetail = {
            id: 'new',
            name: '',
            packaging: 0,
            unit: 'u',
            price: 0,
            unit_price: 0,
            quantity_per_product: 0,
            cost: 0,
            comment: ''
        };

        this.edit( newProduct )
    }

    private handleEditProduct = ( value: PayloadString ) => {
        this.props.editProduct( value );
        this.hideModal();
    }

    private hideModal = () => {
        this.setState( { visible: false } );
    }

    private handlePotentialPriceChange = ( key: string, value: number ) => {
        this.props.setProductPotentialPrice( key, value as number );
    }

    private handleTotalItemsChange = ( key: string, value: number ) => {
        this.props.setProductTotalItems( key, value as number );
    }

    private getCost( quantity_per_product: number, price: number, packaging: number ): number {
        return quantity_per_product * ( this.getUnitPrice( price, packaging ) );
    }

    private getUnitPrice( price: number, packaging: number ): number {
        return price / packaging;
    }

    private getCosts(): number {
        let totalCost = 0;
        this.props.productDetails.product_items.forEach( ( { quantity_per_product, price, packaging } ) => {
            totalCost += this.getCost( quantity_per_product, price, packaging );
        } );
        return totalCost;
    }

    private handleProductNameUpdate = () => {
        this.setState( { visible: true } );
    }

    render() {

        const { editedProduct, visible } = this.state
        const { productDetails, dataKey } = this.props

        let data = editedProduct && editedProduct.id === 'new' ? productDetails.product_items.concat( editedProduct ) : productDetails.product_items

        return ( <div>
            <PageHeader title={productDetails.name}
                extra={[
                    <Button type="dashed" onClick={this.handleProductNameUpdate}>Renommer le produit</Button>
                ]}></PageHeader>

            <ProductCostEditModal productName={productDetails.name} productId={productDetails.id} visible={visible} editProduct={this.handleEditProduct} closeModal={this.hideModal}></ProductCostEditModal>

            <Divider>Détails</Divider>

            <Row justify="end">
                <Col>
                    <Button disabled={!!this.state.editedProduct} onClick={this.handleAdd} type="dashed" style={{ marginBottom: 16 }}>
                        Ajouter une nouvelle ligne
                    </Button>
                </Col>
            </Row>
            <Table size="small"
                dataSource={data}
                columns={this.getColumns()}
                rowClassName={() => 'editable-row'}
                rowKey={record => record.id}
                summary={pageData => {
                    let totalCost = this.getCosts();
                    return (
                        <>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>Total coût de revient (€)</Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>
                                    <Tag color="cyan"><FormattedNumber
                                        value={totalCost}
                                        style="currency"
                                        currency="EUR"
                                        currencyDisplay="symbol"
                                        maximumFractionDigits={2}
                                    /> </Tag>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>Prix Minimum (€)</Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>
                                    <Tag color="cyan"><FormattedNumber
                                        value={totalCost * 2}
                                        style="currency"
                                        currency="EUR"
                                        currencyDisplay="symbol"
                                        maximumFractionDigits={2}
                                    /> </Tag>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>Prix potentiel (€)</Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>
                                    <InputNumber min={0} placeholder="Saisir le nom du produit" value={productDetails.potential_cost as number} onChange={value => this.handlePotentialPriceChange( this.props.dataKey, value as number )} />
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>Marge brut (€)</Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>
                                    <Tag color="cyan"><FormattedNumber
                                        value={productDetails.potential_cost - totalCost}
                                        style="currency"
                                        currency="EUR"
                                        currencyDisplay="symbol"
                                        maximumFractionDigits={2}
                                    /> </Tag>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>Marge brut %</Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>
                                    <Tag color="cyan"><FormattedNumber
                                        value={( ( productDetails.potential_cost - totalCost ) / productDetails.potential_cost ) * 100}
                                        style="unit"
                                        unit="percent"
                                        unitDisplay="narrow"
                                        maximumFractionDigits={1}
                                    /> </Tag>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>

                        </>
                    );
                }}
            />

            <Divider>Global</Divider>

            <ProductCostGlobal dataKey={this.props.dataKey}
                productDetails={this.props.productDetails}
                costs={this.getCosts()}
                setProductTotalItems={this.handleTotalItemsChange}></ProductCostGlobal>

        </div>
        );
    }

}

export default ProductCostTable;
