import { InvestmentIteration, LoanIteration, LoanIterationSums, InvestmentConfiguration, LoanConfiguration } from "../../models"

export const initialState: InvestmentState = {
    investment: {
        startingCapital: 0,
        interestRate: 0,
        iterations: 0,
        feesByIterationRate: 0,
        feesByIterationFixed: 0,
        addedCapitalByIteration: 0
    },
    investmentTable: [],

    loan: {
        loanAmount: 0,
        loanInterestRate: 0,
        loanIterations: 0,
        loanInsuranceAmount: 0,
        loanFirstPaymentDate: null,
    },
    loanTable: [],

    loanIterationSums: {
        iteration: 0,
        payment: 0,
        interest: 0,
        capital: 0
    }
};

export interface InvestmentState {
    investment: InvestmentConfiguration;
    investmentTable: InvestmentIteration[];

    loan: LoanConfiguration;
    loanTable: LoanIteration[];
    loanIterationSums: LoanIterationSums;
}

