import React from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux'
import { Menu } from "antd"
import { connect, MapStateToProps } from 'react-redux'
import {
    HomeOutlined,
    ShopOutlined,
    DollarOutlined,
    WalletOutlined,
    RiseOutlined,
    BarChartOutlined,
    SlidersOutlined,
    TableOutlined
} from '@ant-design/icons';
import Cookies from 'js-cookie';
import { dataManagementLoadFile } from './utils/data_management_actions'

/*Helper*/
import { getQueryParams } from './utils/helpers'
import { ReduxProps } from './models';

/* Models */
import { IRootState } from './redux/store'

const { SubMenu } = Menu;

export interface Props extends RouteComponentProps, ReduxProps {

}

export interface State {
    value: number;
    pathMap: Array<string>;
    visibleModalApiKey: boolean;
}

class AppRouter extends React.Component<Props, State> {

    constructor( props: any ) {
        super( props )

        this.state = {
            value: 0,
            visibleModalApiKey: false,
            pathMap: [
                '/',
                '/product-cost',
                '/budget',
                '/taxes',
                '/investment',
                '/trading',
                '/ibkr',
                '/parser'
            ]
        };
    }

    componentDidMount() {
        this.checkToken()
    }

    public componentWillReceiveProps( newProps: any ) {
        const { pathname } = newProps.location;
        const { pathMap } = this.state;

        const value = pathMap.indexOf( pathname );

        if ( value > -1 ) {
            this.setState( {
                value
            } );
        }
    }

    private checkToken() {
        let hash = window.location.hash;
        if ( hash ) {
            let params = getQueryParams( hash )
            if ( params["/access_token"] ) {
                Cookies.set( "access_token", params["/access_token"] )
                window.location.assign( window.location.href.split( '#' )[0] )
            }
        }
    }

    render() {
        const { visibleModalApiKey, pathMap } = this.state;
        const currentLocation = this.props.location.pathname;


        return ( <>
            <Menu theme="dark" selectedKeys={[currentLocation]} mode="inline">
                <Menu.Item key={pathMap[0]} icon={<HomeOutlined />}>
                    <Link to={pathMap[0]}>Accueil</Link>
                </Menu.Item>
                <Menu.Item key={pathMap[2]} icon={<DollarOutlined />}>
                    <Link to={pathMap[2]}>Budget</Link>
                </Menu.Item>
                <Menu.Item key={pathMap[3]} icon={<WalletOutlined />} >
                    <Link to={pathMap[3]}>Impôts et Charges</Link>
                </Menu.Item>
                <Menu.Item key={pathMap[4]} icon={<RiseOutlined />}>
                    <Link to={pathMap[4]}>Investissement</Link>
                </Menu.Item>
                {/*
                <Menu.Item key={pathMap[5]} icon={<BarChartOutlined />}>
                    <Link to={pathMap[5]}>Trading</Link>
                </Menu.Item>
                <Menu.Item key={pathMap[6]} icon={<SlidersOutlined />}>
                    <Link to={pathMap[6]}>IBKR Connector</Link>
                </Menu.Item>
                */}
                <Menu.Item key={pathMap[1]} icon={<ShopOutlined />}>
                    <Link to={pathMap[1]}>Coût de revient</Link>
                </Menu.Item>
                <Menu.Item key={pathMap[7]} icon={<TableOutlined />}>
                    <Link to={pathMap[7]}>Parser</Link>
                </Menu.Item>
            </Menu>
        </>
        );
    }

}

export default connect()( withRouter( AppRouter as any ) ) as any;
