import React from 'react';
import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table'
import { FormattedNumber } from 'react-intl'

/*Helpers*/
import * as TAXES_HELPER from '../../utils/taxes_calculation'

/*Models*/
import { BudgetEntry } from '../../models/index'

interface Props {
    entries: BudgetEntry[]
}

interface State {

}

class TaxReportTable extends React.Component<Props, State> {

    render() {
        const { entries } = this.props

        return (
            < >
                <Table
                    columns={this.getColumns()}
                    dataSource={entries}
                    pagination={false}
                    summary={( taxesInput ) => {
                        let input = JSON.parse(JSON.stringify(taxesInput))
                        let yearlyIncomeSum = TAXES_HELPER.getYearlyIncomeSum( input )
                        let yearlyTaxSum = Math.round( TAXES_HELPER.getYearlyTaxSum( input ) )
                        let yearlyTaxAllowanceSum = TAXES_HELPER.getYearlyTaxAllowanceSum( input )
                        let yearlyReferences = TAXES_HELPER.getYearlyIncomeSum( input ) - TAXES_HELPER.getYearlyTaxAllowanceSum( input )

                        return (
                            <>
                                <Table.Summary.Row className="summary-row-background">
                                    <Table.Summary.Cell index={0}>Somme</Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}>
                                        <FormattedNumber
                                            value={yearlyIncomeSum}
                                            style="currency"
                                            currency="EUR"
                                            currencyDisplay="symbol"
                                        />
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}>
                                        <FormattedNumber
                                            value={yearlyTaxSum}
                                            style="currency"
                                            currency="EUR"
                                            currencyDisplay="symbol"
                                        />
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}>
                                        <FormattedNumber
                                            value={yearlyTaxAllowanceSum}
                                            style="currency"
                                            currency="EUR"
                                            currencyDisplay="symbol"
                                        />
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}>
                                        <FormattedNumber
                                            value={yearlyReferences}
                                            style="currency"
                                            currency="EUR"
                                            currencyDisplay="symbol"
                                        />
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </>
                        );
                    }} />
            </>
        );
    }

    private getColumns = (): Array<ColumnProps<BudgetEntry>> => {
        return [
            {
                title: 'Revenu',
                key: 'name',
                dataIndex: 'name',
                render: ( value: string, record: BudgetEntry ) => {
                    return record.name;
                }
            },
            {
                title: 'Annuel',
                key: 'annual',
                dataIndex: 'annual',
                render: ( value: string, record: BudgetEntry ) => {
                    const multiplier = record.yearly ? 1 : 12
                    return ( <FormattedNumber
                        value={( record.value || 0 ) * multiplier}
                        style="currency"
                        currency="EUR"
                        currencyDisplay="symbol"
                    /> );
                }
            },
            {
                title: 'Charges sur revenu',
                key: 'tax',
                dataIndex: 'tax',
                render: ( value: string, record: BudgetEntry ) => {
                    const multiplier = record.yearly ? 1 : 12
                    return ( <FormattedNumber
                        value={( record.value || 0 ) * ( record.tax / 100 ) * multiplier}
                        style="currency"
                        currency="EUR"
                        currencyDisplay="symbol"
                    /> );
                }
            },
            {
                title: 'Abattement',
                key: 'taxAllowance',
                dataIndex: 'taxAllowance',
                render: ( value: string, record: BudgetEntry ) => {
                    const multiplier = record.yearly ? 1 : 12
                    return ( <FormattedNumber
                        value={( record.value || 0 ) * ( record.taxAllowance / 100 ) * multiplier}
                        style="currency"
                        currency="EUR"
                        currencyDisplay="symbol"
                    /> );
                }
            },
            {
                title: 'Référence',
                key: 'reference',
                dataIndex: 'reference',
                render: ( value: string, record: BudgetEntry ) => {
                    const multiplier = record.yearly ? 1 : 12
                    return ( <FormattedNumber
                        value={( record.value || 0 ) * ( 1 - record.taxAllowance / 100 ) * multiplier}
                        style="currency"
                        currency="EUR"
                        currencyDisplay="symbol"
                    /> );
                }
            },
        ]
    }

}

export default TaxReportTable;