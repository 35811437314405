import moment from 'moment';

/* Actions */
import { TradingResetDataStoredAction, TradingEditTradingPlanAction, TradingMainImportReceivedAction, TradingAddTradeAction, TradingEditTradeAction, TradingRemoveTradeAction, TradingAddPlannedTradeAction, TradingEditPlannedTradeAction, TradingRemovePlannedTradeAction, TradingStoreLogoAction } from "./trading_actions"

/* Constants */
import * as ACTION_NAMES from "../action_names"

/* Models */
import { Trade } from "../../models"

/* State */
import { initialState, TradingState } from "./trading_states"

/*Storage action*/
import { webStorage } from "../../utils/web_storage"

type Actions = TradingResetDataStoredAction | TradingMainImportReceivedAction | TradingAddTradeAction | TradingEditTradeAction | TradingRemoveTradeAction | TradingAddPlannedTradeAction | TradingEditPlannedTradeAction | TradingEditTradingPlanAction | TradingRemovePlannedTradeAction | TradingStoreLogoAction;

function tradingReducer( state = initialState, action: Actions ) {
    let stateTradesToModify: Trade[] = JSON.parse( JSON.stringify( state.trades ) );
    let statePlannedTradesToModify: Trade[] = JSON.parse( JSON.stringify( state.plannedTrades ) );
    let indexTradeToModify = null;

    switch ( action.type ) {
        case ACTION_NAMES.RESET_DATA_STORED:
            webStorage.clearWebStorage();
            return initialState;

        case ACTION_NAMES.MAIN_IMPORT_RECEIVED:
            let data = JSON.parse( action.payload.trading ) as TradingState;
            let budgetData = data ? data : state;
            return Object.assign( {}, state, budgetData );

        case ACTION_NAMES.TRADING_ADD_TRADE:
            stateTradesToModify.push( action.payload );

            stateTradesToModify.sort( ( a, b ) => new Date( a.start_at ).getTime() - new Date( b.start_at ).getTime() )

            return Object.assign( {}, state, {
                trades: stateTradesToModify
            } );

        case ACTION_NAMES.TRADING_ADD_PLANNED_TRADE:
            statePlannedTradesToModify.push( action.payload );

            return Object.assign( {}, state, {
                plannedTrades: statePlannedTradesToModify
            } );

        case ACTION_NAMES.TRADING_EDIT_TRADE:
            indexTradeToModify = stateTradesToModify.findIndex( ( trade: Trade ) => trade.id === action.payload.id );

            if ( indexTradeToModify !== -1 ) {
                stateTradesToModify[indexTradeToModify] = action.payload;
            }

            stateTradesToModify.sort( ( a, b ) => new Date( a.start_at ).getTime() - new Date( b.start_at ).getTime() )

            return Object.assign( {}, state, {
                trades: stateTradesToModify
            } );

        case ACTION_NAMES.TRADING_EDIT_PLANNED_TRADE:
            indexTradeToModify = statePlannedTradesToModify.findIndex( ( trade: Trade ) => trade.id === action.payload.id );

            if ( indexTradeToModify !== -1 )
                statePlannedTradesToModify[indexTradeToModify] = action.payload;

            return Object.assign( {}, state, {
                plannedTrades: statePlannedTradesToModify
            } );

        case ACTION_NAMES.TRADING_EDIT_TRADING_PLAN:
            return Object.assign( {}, state, {
                tradingPlan: action.payload
            } );

        case ACTION_NAMES.TRADING_REMOVE_TRADE:
            indexTradeToModify = stateTradesToModify.findIndex( ( trade: Trade ) => trade.id === action.payload );

            if ( indexTradeToModify !== -1 ) {
                stateTradesToModify.splice( indexTradeToModify, 1 );
            }

            return Object.assign( {}, state, {
                trades: stateTradesToModify
            } );

        case ACTION_NAMES.TRADING_REMOVE_PLANNED_TRADE:
            indexTradeToModify = statePlannedTradesToModify.findIndex( ( trade: Trade ) => trade.id === action.payload );

            if ( indexTradeToModify !== -1 ) {
                statePlannedTradesToModify.splice( indexTradeToModify, 1 );
            }

            return Object.assign( {}, state, {
                plannedTrades: statePlannedTradesToModify
            } );

        case ACTION_NAMES.TRADING_STORE_TICKER_LOGO: {

            return Object.assign( {}, state, {
                tickerLogos: Object.assign( {}, state.tickerLogos, action.payload )
            } );

        }

        default:
            return state;
    }
};

export default tradingReducer;