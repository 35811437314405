import React from 'react';
import { Table, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table'
import { FormattedNumber } from 'react-intl'

/*Models*/
import { IBKRPosition } from '../../models'

//utils
import { GREEN_COLOR, RED_COLOR } from 'src/utils/helpers';

interface Props {
    loading: boolean;
    positions: IBKRPosition[];
}

interface State {
}

class IBKRPortfolio extends React.Component<Props, State> {

    constructor( props: Props ) {
        super( props );
    }

    render() {
        const { positions, loading } = this.props

        return (
            <div>
                <Table
                    size="small"
                    dataSource={positions}
                    columns={this.getColumns()}
                    rowClassName={() => 'editable-row'}
                    rowKey={( record: IBKRPosition ) => record.conid}
                    loading={loading}
                    pagination={false}
                />
            </div>
        );
    };

    private getColumns = (): Array<ColumnProps<IBKRPosition>> => {

        return [
            {
                title: 'Ticker',
                key: 'contractDesc',
                dataIndex: 'contractDesc'
            },
            {
                title: 'Position',
                key: 'position',
                dataIndex: 'position'
            },
            {
                title: 'AVG Price',
                key: 'avgPrice',
                dataIndex: 'avgPrice',
                render: ( value: number, record: IBKRPosition ) => {
                    return (
                        <FormattedNumber
                            value={value}
                            style="currency"
                            currency={record.currency}
                            currencyDisplay="narrowSymbol"
                        />
                    )
                }
            },
            {
                title: 'Last',
                key: 'mktPrice',
                dataIndex: 'mktPrice',
                render: ( value: number, record: IBKRPosition ) => {
                    return (
                        <FormattedNumber
                            value={value}
                            style="currency"
                            currency={record.currency}
                            currencyDisplay="narrowSymbol"
                        />
                    )
                }
            },
            {
                title: 'Unrealized P&L',
                key: 'unrealizedPnl',
                dataIndex: 'unrealizedPnl',
                render: ( value: number, record: IBKRPosition ) => {
                    return (
                        <span style={{ color: value ? ( value > 0 ? GREEN_COLOR : RED_COLOR ) : null }}>
                            <FormattedNumber
                                value={value}
                                style="currency"
                                currency={record.currency}
                                currencyDisplay="narrowSymbol"
                            />
                        </span>
                    )
                }
            },
            {
                title: 'Realized P&L',
                key: 'realizedPnl',
                dataIndex: 'realizedPnl',
                render: ( value: number, record: IBKRPosition ) => {
                    return (
                        <span style={{ color: value ? ( value > 0 ? GREEN_COLOR : RED_COLOR ) : null }}>
                            <FormattedNumber
                                value={value}
                                style="currency"
                                currency={record.currency}
                                currencyDisplay="narrowSymbol"
                            />
                        </span>
                    )
                }
            },
        ]

    }

}

export default IBKRPortfolio;