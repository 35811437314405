import React from 'react';
import { Table, Space } from 'antd';
import moment from 'moment';
import { ColumnProps } from 'antd/lib/table'
import { FormattedNumber } from 'react-intl'

/*Models*/
import { LoanConfiguration, LoanIteration, LoanIterationSums } from '../../models/index'

/*Helpers*/
import { DATE_FORMAT } from '../../utils/helpers'

interface Props {
    iterations: LoanIteration[],
    configuration: LoanConfiguration
}

interface State {
    loanIterationSums: LoanIterationSums
}

class LoanTable extends React.Component<Props, State> {

    constructor( props: Props ) {
        super( props );

        this.state = {
            loanIterationSums: {
                capital: 0,
                interest: 0,
                iteration: 0,
                payment: 0
            }
        }
    }

    render() {
        const { iterations } = this.props

        return ( <div>
            <Table size="small"
                dataSource={iterations}
                columns={this.getColumns()}
                rowClassName={() => 'editable-row'}
                rowKey={record => record.iteration}
                expandable={{
                    expandedRowRender: record => {
                        let data = this.getLoanIterationDetails( record.iteration, record.monthlyPayment )

                        return (
                            <> <Table size="small"
                                showHeader={false}
                                dataSource={[data]}
                                pagination={false}
                                rowKey={record => record.iteration}
                                columns={this.getColumnsDetails( record )} /></>
                        )
                    }
                }} />
        </div> );
    };

    private getColumnsDetails = ( loanIteration: LoanIteration ): Array<ColumnProps<LoanIterationSums>> => {
        const { configuration } = this.props
        return [
            {
                title: 'Itération',
                key: 'iteration',
                dataIndex: 'iteration',
                ellipsis: true,
                render: ( value: string, record: LoanIterationSums ) => {
                    return null;
                }
            },
            {
                title: 'Date',
                key: 'capital',
                dataIndex: 'capital',
                ellipsis: true,
                render: ( value: string, record: LoanIterationSums ) => {
                    return null;
                }
            },
            {
                title: 'Capital restant dû',
                key: 'remainingCapital',
                dataIndex: 'remainingCapital',
                ellipsis: true,
                render: ( value: string, record: LoanIterationSums ) => {
                    return null;
                }
            },
            {
                title: 'Mensualité',
                key: 'monthlyPayment',
                dataIndex: 'monthlyPayment',
                ellipsis: true,
                render: ( value: string, record: LoanIterationSums ) => {
                    let total = loanIteration.monthlyPayment * configuration.loanIterations;
                    return ( <div className="bold-font flex"><FormattedNumber
                        value={record.payment}
                        style="currency"
                        currency="EUR"
                        currencyDisplay="symbol"
                    /><div className="mgr-5 mgl-5">/</div><FormattedNumber
                            value={total}
                            style="currency"
                            currency="EUR"
                            currencyDisplay="symbol"
                        /></ div> );
                }
            },
            {
                title: 'Dont capital',
                key: 'capitalPart',
                dataIndex: 'capitalPart',
                ellipsis: true,
                render: ( value: string, record: LoanIterationSums ) => {
                    return ( <div className="bold-font flex"><div className="green-color"><FormattedNumber
                        value={record.capital}
                        style="currency"
                        currency="EUR"
                        currencyDisplay="symbol"
                    /></div><div className="mgr-5 mgl-5">/</div><FormattedNumber
                            value={configuration.loanAmount}
                            style="currency"
                            currency="EUR"
                            currencyDisplay="symbol"
                        /></div> );
                }
            },
            {
                title: 'Dont intérêts',
                key: 'interestsPart',
                dataIndex: 'interestsPart',
                ellipsis: true,
                render: ( value: string, record: LoanIterationSums ) => {
                    let total = ( loanIteration.monthlyPayment * configuration.loanIterations ) - configuration.loanAmount;
                    return ( <div className="bold-font flex"><div className="red-color"><FormattedNumber
                        value={record.interest}
                        style="currency"
                        currency="EUR"
                        currencyDisplay="symbol"
                    /> </div><div className="mgr-5 mgl-5">/</div><FormattedNumber
                            value={total}
                            style="currency"
                            currency="EUR"
                            currencyDisplay="symbol"
                        /></div> );
                }
            },
            {
                title: 'Assurance',
                key: 'loanInsuranceAmount',
                dataIndex: 'loanInsuranceAmount',
                ellipsis: true,
                render: ( value: string, record: LoanIterationSums ) => {
                    let insurance = configuration.loanInsuranceAmount * record.iteration;
                    let insuranceTotal = ( configuration.loanInsuranceAmount * configuration.loanIterations );
                    return ( <div className="bold-font flex"><div className="red-color"><FormattedNumber
                        value={insurance}
                        style="currency"
                        currency="EUR"
                        currencyDisplay="symbol"
                    /></div><div className="mgr-5 mgl-5">/</div><FormattedNumber
                            value={insuranceTotal}
                            style="currency"
                            currency="EUR"
                            currencyDisplay="symbol"
                        /></div> );
                }
            },
            {
                title: 'Mensualité avec assurance',
                key: 'monthlyLoanInsuranceAmount',
                dataIndex: 'monthlyLoanInsuranceAmount',
                ellipsis: true,
                render: ( value: string, record: LoanIterationSums ) => {
                    let monthlyInsurance = ( loanIteration.monthlyPayment + configuration.loanInsuranceAmount ) * record.iteration;
                    let monthlyInsuranceTotal = ( loanIteration.monthlyPayment + configuration.loanInsuranceAmount ) * configuration.loanIterations;
                    return ( <div className="bold-font flex"><FormattedNumber
                        value={monthlyInsurance}
                        style="currency"
                        currency="EUR"
                        currencyDisplay="symbol"
                    /><div className="mgr-5 mgl-5">/</div><FormattedNumber
                            value={monthlyInsuranceTotal}
                            style="currency"
                            currency="EUR"
                            currencyDisplay="symbol"
                        /></ div> );
                }
            }
        ]
    }

    private getColumns = (): Array<ColumnProps<LoanIteration>> => {
        const { configuration } = this.props
        return [
            {
                title: 'Itération',
                key: 'iteration',
                dataIndex: 'iteration',
                ellipsis: true,
                render: ( value: string, record: LoanIteration ) => {
                    return record.iteration;
                }
            },
            {
                title: 'Date',
                key: 'capital',
                dataIndex: 'capital',
                ellipsis: true,
                render: ( value: string, record: LoanIteration ) => {
                    return moment( record.iterationDate ).format( DATE_FORMAT );
                }
            },
            {
                title: 'Capital restant dû',
                key: 'remainingCapital',
                dataIndex: 'remainingCapital',
                ellipsis: true,
                render: ( value: string, record: LoanIteration ) => {
                    return this.getInputFormattedNumber( record.remainingCapital );
                }
            },
            {
                title: 'Mensualité',
                key: 'monthlyPayment',
                dataIndex: 'monthlyPayment',
                ellipsis: true,
                render: ( value: string, record: LoanIteration ) => {
                    return ( this.getInputFormattedNumber( record.monthlyPayment ) );
                }
            },
            {
                title: 'Dont capital',
                key: 'capitalPart',
                dataIndex: 'capitalPart',
                ellipsis: true,
                render: ( value: string, record: LoanIteration ) => {
                    return ( this.getInputFormattedNumber( record.capitalPart ) );
                }
            },
            {
                title: 'Dont intérêts',
                key: 'interestsPart',
                dataIndex: 'interestsPart',
                ellipsis: true,
                render: ( value: string, record: LoanIteration ) => {
                    return ( this.getInputFormattedNumber( record.interestsPart ) );
                }
            },
            {
                title: 'Assurance',
                key: 'loanInsuranceAmount',
                dataIndex: 'loanInsuranceAmount',
                ellipsis: true,
                render: ( value: string, record: LoanIteration ) => {
                    return ( this.getInputFormattedNumber( configuration.loanInsuranceAmount ) );
                }
            },
            {
                title: 'Mensualité avec assurance',
                key: 'monthlyLoanInsuranceAmount',
                dataIndex: 'monthlyLoanInsuranceAmount',
                ellipsis: true,
                render: ( value: string, record: LoanIteration ) => {
                    return ( this.getInputFormattedNumber( record.monthlyPayment + configuration.loanInsuranceAmount ) );
                }
            }
        ]
            ;
    }

    private getInputFormattedNumber( value: number ) {
        return (
            <FormattedNumber
                value={value}
                style="currency"
                currency="EUR"
                currencyDisplay="symbol"
                maximumFractionDigits={4}
            />
        );

    }

    private getLoanIterationDetails = ( iteration: number, loanMonthlyPayment: number ): LoanIterationSums => {

        const { iterations } = this.props

        const monthlyPayment = loanMonthlyPayment

        const sums: LoanIterationSums = iterations.reduce( ( acc, it, idx ) => {

            if ( idx <= iteration ) {
                acc.interest = acc.interest + it.interestsPart
                acc.capital = acc.capital + it.capitalPart
                acc.payment = acc.payment + monthlyPayment
            }

            return acc
        }, {
            iteration,
            interest: 0,
            capital: 0,
            payment: 0

        } as LoanIterationSums )

        return sums


    }


}

export default LoanTable;