
import en_US from 'antd/lib/locale/en_US'
import enMessages from 'src/wordings/en'

//import fr_FR from 'antd/lib/locale/fr_FR'
//import frMessages from 'src/wordings/fr'

//import { webStorage } from './web_storage'

export function getLocaleProvider() {

    //const language = webStorage.getLanguage()

    /*
    if ( language === 'fr_FR' ) {
        return fr_FR
    }

    return fr_FR

    */

    return en_US

}

export function getMessages() {

    //const language = webStorage.getLanguage()

    /*
    if ( language === 'fr_FR' ) {
        return frMessages
    }

    return frMessages
    */

    return enMessages

}