import React from 'react';
import { Card, Row, Col, Tag, Statistic, Divider, Menu, Dropdown, Drawer, Space } from 'antd';
import {
    ClockCircleTwoTone,
    EditOutlined,
    CloseOutlined,
    MoreOutlined,
    EyeOutlined,
    MinusOutlined
} from '@ant-design/icons';
import moment from 'moment';

/*Models*/
import { Trade, Position } from '../../models';

//utils
import { DATE_FORMAT, DATE_TIME_FORMAT, getTradeExitTargetPrice, GREEN_COLOR, RED_COLOR, TARGET_COLOR } from '../../utils/helpers';

interface Props {
    trade: Trade,
    onEditTrade: ( trade: Trade ) => void;
    onRemoveTrade: ( id: string, planned_trade: boolean ) => void;
    handleViewDetails: ( trade: Trade ) => void;
}

interface State {
    visible: boolean;
}

class TradeView extends React.Component<Props, State> {

    constructor( props: Props ) {
        super( props );
        this.state = {
            visible: false
        }
    }

    getPositionTag = ( trade: Trade ) => {
        if ( trade.position ) {
            if ( trade.position === Position.Long )
                return <Tag color="cyan">LONG</Tag>;
            else
                return <Tag color="orange">SHORT</Tag>;
        }
        else
            return;
    }

    editTrade = () => {
        this.props.onEditTrade( this.props.trade );
    }

    removeTrade = () => {
        this.props.onRemoveTrade( this.props.trade.id, this.props.trade.planned_trade );
    }

    handleMenuClick = ( e: any ) => {
        switch ( e.key ) {
            case 'edit':
                this.editTrade();
                return;
            case 'remove':
                this.removeTrade();
                return;
            default:
                return;
        }
    }

    showDrawer = () => {
        this.props.handleViewDetails( this.props.trade );
    }


    render() {

        const { trade } = this.props

        const menu = (
            <Menu onClick={this.handleMenuClick}>
                <Menu.Item key="edit" icon={<EditOutlined />}>
                    Edit trade
              </Menu.Item>
                <Menu.Item key="remove" icon={<CloseOutlined />}>
                    Delete trade
              </Menu.Item>
            </Menu>
        );

        return ( <>
            <Card size="small" onDoubleClick={this.showDrawer}>
                <Row align="middle">
                    <Col xs={12} lg={8} style={{ fontSize: 16 }}><Space><strong>{trade.ticker}</strong></Space></Col>
                    <Col xs={12} lg={8}>
                        {
                            trade.planned_trade ? (
                                <Space>
                                    <ClockCircleTwoTone twoToneColor="#227093" /> {moment( trade.start_at ).format( DATE_FORMAT )}
                                </Space>
                            ) : (
                                    <Space>
                                        <ClockCircleTwoTone twoToneColor="#227093" /> {moment( trade.start_at ).format( DATE_TIME_FORMAT )}
                                        <span>→</span>
                                        <span>{moment.duration( trade.duration, 's' ).humanize()}</span>
                                    </Space>
                                )
                        }
                    </Col>
                    <Col xs={24} lg={8}>
                        <Row justify="end">
                            <Col>
                                <Space>
                                    {this.getPositionTag( trade )}
                                    <Divider type="vertical"></Divider>
                                    <Dropdown overlay={menu} className="clickable">
                                        <MoreOutlined />
                                    </Dropdown>
                                    <Divider type="vertical"></Divider>
                                    <EyeOutlined key="show" onClick={this.showDrawer} />
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Divider plain style={{ marginTop: 12, marginBottom: 18 }} />

                <Row justify="space-between">
                    <Col><Statistic title="Quantity" value={trade.qty} /></Col>
                    <Col><Statistic title="Entry price" value={trade.avg_entry_price} precision={2} suffix="$" /></Col>
                    {trade.planned_trade ? null : <Col><Statistic title="Exit price" value={trade.avg_exit_price} precision={2} suffix="$" /></Col>}
                    {trade.planned_trade ? ( <Col><Statistic title="Stop price" value={trade.stop_price} precision={2} suffix="$" /></Col> ) : null}
                    <Col><Statistic title="Risk" value={trade.risk} precision={2} suffix="$" /></Col>
                    {
                        trade.planned_trade ? (
                            <Col><Statistic title="Target" value={getTradeExitTargetPrice( trade, 1.5 )} precision={2} valueStyle={{ color: TARGET_COLOR, fontWeight: 'bold' }} suffix="$" /></Col>
                        ) : (
                                <>
                                    <Col><Statistic title="Net P/L" value={trade.net_PL} precision={2} valueStyle={{ fontWeight: 'bold', color: trade.net_PL && trade.net_PL < 0 ? RED_COLOR : GREEN_COLOR }} suffix="$" /></Col>
                                    <Col><Statistic title="Net R" value={trade.net_R} precision={2} valueStyle={{ fontWeight: 'bold', color: trade.net_R && trade.net_R < 0 ? RED_COLOR : GREEN_COLOR }} /></Col>
                                </>
                            )
                    }

                </Row>

                {
                    trade.comments ? (
                        <div className="mgt-20 italic">
                            {trade.comments}
                        </div>
                    ) : null
                }

            </Card>
        </> );
    };
}

export default TradeView;