export const DEFAULT_CONFIG_OSMOZIS = {
    "Nom ": {
        "order": 1,
        "hide": false,
        "exportKey": "buyer_lastname",
        "transformation": {
            "activated": false
        }
    },
    "Prénom": {
        "order": 0,
        "hide": false,
        "exportKey": "buyer_firstname",
        "transformation": {
            "activated": false
        }
    },
    "Date de reservation": {
        "order": 2,
        "hide": false,
        "exportKey": "order_date",
        "transformation": {
            "activated": false
        }
    },
    "Date d'arrivée": {
        "order": 3,
        "hide": false,
        "exportKey": "event_date_start",
        "transformation": {
            "activated": false
        }
    },
    "Date de départ": {
        "order": 4,
        "hide": false,
        "exportKey": "event_date_end",
        "transformation": {
            "activated": false
        }
    },
    "Nbre de personne": {
        "order": 6,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "Montant du séjour (hors frais, taxe et assurances) ": {
        "order": 5,
        "hide": false,
        "exportKey": "order_amount",
        "transformation": {
            "activated": false
        }
    },
    "Sommes versées": {
        "order": 8,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "Montant de l'assurance": {
        "order": 6,
        "hide": false,
        "exportKey": "insurance_amount",
        "transformation": {
            "activated": false
        }
    },
    "Numéro de SEJOUR": {
        "order": 7,
        "hide": false,
        "exportKey": "order_reference",
        "transformation": {
            "activated": false
        }
    },
    "PAYS": {
        "order": 8,
        "hide": false,
        "exportKey": "preferred_locale",
        "transformation": {
            "activated": false
        }
    },
    "MAIL": {
        "order": 9,
        "hide": false,
        "exportKey": "buyer_email",
        "transformation": {
            "activated": false
        }
    },
    "Type camping ou location": {
        "order": 11,
        "hide": false,
        "exportKey": "event_type",
        "transformation": {
            "activated": false
        }
    },
    "Participants (Nom et prénom)": {
        "order": 18,
        "hide": false,
        "exportKey": "insured_persons",
        "transformation": {
            "activated": false
        }
    },
    "added_column_1": {
        "order": 15,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "added_column_2": {
        "order": 16,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "added_column_3": {
        "order": 17,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "added_column_4": {
        "order": 18,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "added_column_5": {
        "order": 19,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "added_column_6": {
        "order": 20,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "added_column_7": {
        "order": 21,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "added_column_8": {
        "order": 22,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "added_column_9": {
        "order": 23,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "added_column_10": {
        "order": 24,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    }
}

export const DEFAULT_CONFIG_LES_STATIONS = {
    "Date commande": {
        "order": 2,
        "hide": false,
        "exportKey": "order_date",
        "transformation": {
            "activated": false
        }
    },
    "Destination": {
        "order": 2,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "Noms prénoms assurés": {
        "order": 1,
        "hide": false,
        "exportKey": "buyer_lastname",
        "transformation": {
            "activated": false
        }
    },
    "Date arrivée": {
        "order": 3,
        "hide": false,
        "exportKey": "event_date_start",
        "transformation": {
            "activated": false
        }
    },
    "Date départ": {
        "order": 4,
        "hide": false,
        "exportKey": "event_date_end",
        "transformation": {
            "activated": false
        }
    },
    "Montant total hors assurance": {
        "order": 5,
        "hide": false,
        "exportKey": "order_amount",
        "transformation": {
            "activated": false
        }
    },
    "Prime nette assurance": {
        "order": 6,
        "hide": false,
        "exportKey": "insurance_amount",
        "transformation": {
            "activated": true,
            "mode": "calculation",
            "exportBaseColumn": "Montant total hors assurance",
            "exportBaseColumnMultiplier": 5,
            "exportBaseColumnDivider": 100
        }
    },
    "Commande": {
        "order": 2,
        "hide": false,
        "exportKey": "order_date",
        "transformation": {
            "activated": false
        }
    },
    "Email": {
        "order": 9,
        "hide": false,
        "exportKey": "buyer_email",
        "transformation": {
            "activated": false
        }
    },
    "Téléphone": {
        "order": 10,
        "hide": false,
        "exportKey": "buyer_phone",
        "transformation": {
            "activated": false
        }
    },
    "Langue": {
        "order": 8,
        "hide": false,
        "exportKey": "preferred_locale",
        "transformation": {
            "activated": false
        }
    },
    "added_column_1": {
        "order": 12,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "added_column_2": {
        "order": 13,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "added_column_3": {
        "order": 14,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "added_column_4": {
        "order": 15,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "added_column_5": {
        "order": 16,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "added_column_6": {
        "order": 17,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "added_column_7": {
        "order": 18,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "added_column_8": {
        "order": 19,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "added_column_9": {
        "order": 20,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "added_column_10": {
        "order": 21,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    }
}

export const DEFAULT_CONFIG_CHAUMES = {
    "Référence ": {
        "order": 7,
        "hide": false,
        "exportKey": "order_reference",
        "transformation": {
            "activated": false
        }
    },
    "Date de réservation": {
        "order": 2,
        "hide": false,
        "exportKey": "order_date",
        "transformation": {
            "activated": false
        }
    },
    "Nom de votre établissement": {
        "order": 13,
        "hide": false,
        "exportKey": "event_name",
        "transformation": {
            "activated": false
        }
    },
    "Date de début du séjour": {
        "order": 3,
        "hide": false,
        "exportKey": "event_date_start",
        "transformation": {
            "activated": false
        }
    },
    "Date de fin du séjour": {
        "order": 4,
        "hide": false,
        "exportKey": "event_date_end",
        "transformation": {
            "activated": false
        }
    },
    "Nom de famille de l'assuré ": {
        "order": 1,
        "hide": false,
        "exportKey": "buyer_lastname",
        "transformation": {
            "activated": false
        }
    },
    "Prénom de l'assuré": {
        "order": 0,
        "hide": false,
        "exportKey": "buyer_firstname",
        "transformation": {
            "activated": false
        }
    },
    "Adresse mail de l'assuré ": {
        "order": 9,
        "hide": false,
        "exportKey": "buyer_email",
        "transformation": {
            "activated": false
        }
    },
    "Langue de l'assuré ": {
        "order": 8,
        "hide": false,
        "exportKey": "preferred_locale",
        "transformation": {
            "activated": false
        }
    },
    "Données additionnelles (par exemple le nom des autres participants au séjour)": {
        "order": 12,
        "hide": false,
        "exportKey": "additional_data",
        "transformation": {
            "activated": false
        }
    },
    "Type d'évènement (location ou emplacement)": {
        "order": 11,
        "hide": false,
        "exportKey": "event_type",
        "transformation": {
            "activated": false
        }
    },
    " Montant total de la réservation": {
        "order": 5,
        "hide": false,
        "exportKey": "order_amount",
        "transformation": {
            "activated": false
        }
    },
    "Montant de la prime d'assurance ": {
        "order": 6,
        "hide": false,
        "exportKey": "insurance_amount",
        "transformation": {
            "activated": false
        }
    },
    "added_column_1": {
        "order": 14,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "added_column_2": {
        "order": 15,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "added_column_3": {
        "order": 16,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "added_column_4": {
        "order": 17,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "added_column_5": {
        "order": 18,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "added_column_6": {
        "order": 19,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "added_column_7": {
        "order": 20,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "added_column_8": {
        "order": 21,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "added_column_9": {
        "order": 22,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    },
    "added_column_10": {
        "order": 23,
        "hide": false,
        "exportKey": "",
        "transformation": {
            "activated": false
        }
    }
}