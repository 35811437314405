import React from 'react';
import { Space, Form, InputNumber, Button, DatePicker } from 'antd';
import moment from 'moment';

/*Models*/
import { LoanConfiguration, LoanIteration, LoanIterationSums } from '../../models/index'
import { DATE_FORMAT } from '../../utils/helpers';

interface Props {
    loan: LoanConfiguration
    setLoan: ( loan: LoanConfiguration, loanIteration: LoanIteration[] ) => void
    resetLoan: () => void
}

interface State {
    editedLoan: LoanConfiguration
}

class LoanForm extends React.Component<Props, State> {

    constructor( props: Props ) {
        super( props );

        this.state = {
            editedLoan: this.props.loan
        }
    }

    render() {
        const { editedLoan } = this.state

        const Picker: any = DatePicker

        if ( editedLoan.loanFirstPaymentDate )
            editedLoan.loanFirstPaymentDate = moment( editedLoan.loanFirstPaymentDate )


        return ( <div>
            <Form layout="inline" initialValues={editedLoan} onFinish={this.calculateLoan}>
                <Form.Item label="Montant du prêt" name="loanAmount">
                    <InputNumber />
                </Form.Item>
                <Form.Item label="Taux d'intérêt nominal - annuel (%)" name="loanInterestRate">
                    <InputNumber />
                </Form.Item>
                <Form.Item label="Durée du prêt (mois)" name="loanIterations">
                    <InputNumber />
                </Form.Item>
                <Form.Item label="Prix assurance (par mois)" name="loanInsuranceAmount">
                    <InputNumber />
                </Form.Item>
                <Form.Item label="Date de début du prêt" name="loanFirstPaymentDate">
                    <Picker format={DATE_FORMAT} onChange={( value: any ) => this.handleChange( value, 'loanFirstPaymentDate' )} />
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button type="primary" htmlType="submit">Génerer</Button>
                        <Button htmlType="button" onClick={this.props.resetLoan}>Réinitialiser</Button>
                    </Space>
                </Form.Item>
            </Form>
        </div> );
    };

    private handleChange = ( value: any, name: string ) => {

        const { editedLoan } = this.state

        this.setState( {
            editedLoan: Object.assign( {}, editedLoan, {
                [name]: value
            } )
        } )

    }

    private calculateLoan = ( values: LoanConfiguration ) => {

        if ( this.state.editedLoan.loanFirstPaymentDate )
            values.loanFirstPaymentDate = this.state.editedLoan.loanFirstPaymentDate;

        const monthlyPayment = this.getLoanMonthlyPayment( values.loanAmount, values.loanInterestRate, values.loanIterations )
        let result: LoanIteration[] = [this.getLoanIteration( 1, values.loanAmount, monthlyPayment, values.loanInterestRate, values.loanFirstPaymentDate )]

        for ( let i = 1; i < values.loanIterations; i++ ) {
            result.push( this.getLoanIteration( i + 1, result[i - 1].remainingCapital, monthlyPayment, values.loanInterestRate, values.loanFirstPaymentDate ) )
        }

        this.props.setLoan( values, result )
    }

    private getLoanMonthlyPayment = ( loanAmount: number, loanInterestRate: number, loanIterations: number ) => {

        let monthlyInterestRate = ( loanInterestRate / 100 ) / 12
        let monthlyPayment = ( loanAmount * monthlyInterestRate ) / ( 1 - Math.pow( ( 1 + monthlyInterestRate ), -loanIterations ) )

        return monthlyPayment
    }

    private getLoanIteration = ( iteration: number, remainingCapital: number, monthlyPayment: number, loanInterestRate: number, loanFirstPaymentDate: moment.Moment | null ): LoanIteration => {

        const monthlyInterestRate = ( loanInterestRate / 100 ) / 12

        const interestsPart = remainingCapital * monthlyInterestRate
        const capitalPart = monthlyPayment - interestsPart

        return {
            remainingCapital: remainingCapital - capitalPart,
            monthlyPayment,
            capitalPart,
            interestsPart,
            iteration,
            iterationDate: loanFirstPaymentDate ? moment( loanFirstPaymentDate ).add( iteration, 'months' ) : moment().add( iteration, 'months' )
        }
    }

}

export default LoanForm;