import { IMessageEvent, w3cwebsocket as W3CWebSocket} from "websocket";

class WebSocketConnection {

    private client: W3CWebSocket;

    constructor(address: string) {
        this.client = new W3CWebSocket( address);
        
        this.client.onopen = () => {
            console.log( 'WebSocket Client Connected' );
            
            this.heartbeat()
            // this.subscribeLiveOrders()
            // this.subscribeMarketData('265598', "[\"31\",\"83\"]" )
        };
        
        this.listenOnTopic()

    }

    /**
     * Listen on server answers.
     */
    private listenOnTopic() {
        this.client.onmessage = async ( message: IMessageEvent ) => {

            //Read data
            if ( message.data instanceof Blob ) {
                let result = await message.data.text()

                try {
                    const dataFromServer = JSON.parse( result );
                            
                    if ( dataFromServer.topic.includes( "smd" ) ) {
                        console.log( dataFromServer );
                    } else if ( dataFromServer.topic === "sor" ) {
                        console.log( dataFromServer );
                    } else if ( dataFromServer.topic === "spl" ) {
                        console.log( dataFromServer );
                    } else {
                        console.log( dataFromServer );
                    }
                } catch ( e ) {
                    console.log( result )
                }
            } else if ( typeof message.data === "string" )
                console.log( message.data )
        };
        
    }

    /**
     * To maintain active the websocket connection.
     */
    public heartbeat =() =>{
     
        setInterval( () => {
            let request = 'ech+hb'
            this.client.send( request )
        }, 60 * 1000 )
    }

    /**
     * To request real time data for trading and analysis
     * @param conid Contract identifier
     * @param fields List of available tick types
     */
    public subscribeMarketData = ( conid: string, fields: string ) => {
        let request = `smd+${ conid }+{"fields":${ fields }}`
        
        this.client.send( request )
    }

    /**
     * Unsubscribe from market data
     * @param conid Contract identifier
     */
    public unsubscribeMarketData = ( conid: string ) => {
        let request = `smd+${ conid }+{}`

        this.client.send(request)
    }

    /**
     * To request for streaming live orders. 
     * NB : When there is an update to your order only the change to the order is relayed back along with the orderId. 
     * Most commonly this would involve status changes and partial fills.
     */
    public subscribeLiveOrders= () => {
        let request = `sor+{}`

        this.client.send(request)  
    }

    /**
     * Unsubscribe from live orders
     */
    public unsubscribeLiveOrders= () => {
        let request = `uor+{}`

        this.client.send(request)  
    }

    /**
     * For existing positions, receive Profit and Loss updates.
     * In the payload response the daily profit and loss (dpl) and unrealized profit and loss (upl) are received as a total value for all positions.
     * Updates are relayed back as quickly as once per second but can vary based on market activity.
     */
    public subscribeProfitAndLoss = () => {
        let request = `spl+{}`

        this.client.send(request)  
    }

    /**
     * Unsubscribe from profit and loss
     */
    public unsubscribeProfitAndLoss= () =>{
        let request = `upl{}`

        this.client.send(request)  
    }

}

export default WebSocketConnection;