import { webStorage } from "src/utils/web_storage";
import { IBKRPosition, IBKRTicker, IBKROrder, IBKRConfig } from "../../models";

export const initialState: IBKRState = {

    config: {
        gatewayURL: webStorage.getIBKRGateway(),
        RUnit: 12,
        RTarget: 1.75
    },

    loadingSession: false,
    sessionId: null,

    tickerSearchLoading: false,
    tickerSearchResults: [],

    positionsLoading: false,
    positions: [],

    selectedStocks: [],

    ordersLoading: false,
    orders: [],

    accounts: [],
    selectedAccount: null

};

export interface IBKRState {

    config: IBKRConfig;

    loadingSession: boolean;
    sessionId: number;

    tickerSearchLoading: boolean;
    tickerSearchResults: IBKRTicker[];

    positionsLoading: boolean;
    positions: IBKRPosition[];

    selectedStocks: IBKRTicker[];

    ordersLoading: boolean;
    orders: IBKROrder[];

    accounts: string[];
    selectedAccount: string;

}

