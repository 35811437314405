import React from 'react';
import { MapStateToProps, connect } from 'react-redux'
import { Tabs } from 'antd'

/*Actions*/
import { updateTaxesEntry } from '../redux/taxes/taxes_actions'

/*Models*/
import { ReduxProps, BudgetEntry, BudgetTypeEnum, BudgetResult } from '../models'
import { IRootState } from '../redux/store'

/*Component*/
import EntryLayout from '../components/budget/entry_layout'
import TaxReportView from '../components/tax_report/tax_report_view'

interface Props extends ReduxProps {
    taxes: BudgetEntry[];
    taxesAmount: number;
}

interface State {
    active_key: string;
    taxesAmount: number;
}

class Taxes extends React.Component<Props, State> {
    public static select: MapStateToProps<any, any, IRootState> = ( state: IRootState ) => {
        return {
            taxes: state.taxes.taxes,
            taxesAmount: state.taxes.taxesAmount
        } as Props
    }

    constructor( props: Props ) {
        super( props )

        this.state = {
            active_key: 'rapport',
            taxesAmount: props.taxesAmount
        }
    }

    private onSaveEntries = ( result: BudgetResult ) => {
        this.setState( {
            taxesAmount: result.sumValue
        } )

        this.props.dispatch( updateTaxesEntry( {
            taxes: result.entries,
            taxesAmount: this.state.taxesAmount
        } ) )
    }

    render() {
        const { taxes } = this.props
        const { active_key, taxesAmount } = this.state

        return ( <>
            <Tabs activeKey={active_key} centered onChange={this.handleTabChange}>
                <Tabs.TabPane tab="Rapport" key="rapport">
                    <TaxReportView entries={taxes}></TaxReportView>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Revenus" key="revenus">
                    <EntryLayout entryType={BudgetTypeEnum.TaxInput} entries={taxes} sumValue={taxesAmount} saveEntries={this.onSaveEntries}></EntryLayout>
                </Tabs.TabPane>
            </Tabs>
        </>
        );
    }

    private handleTabChange = ( activeKey: string ) => {
        this.setState( {
            active_key: activeKey
        } )
    }

}

export default connect( Taxes.select )( Taxes as any );
