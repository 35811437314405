/*Utils*/
import { Action, createAction } from "../../utils/action-type-helper"

import { Dispatch } from "redux"

/*Constant*/
import * as ACTION_NAMES from "../action_names";


/*Models*/
import { PersistRootState, Trade } from "../../models/index"
export type TradingResetDataStoredAction = Action<typeof ACTION_NAMES.RESET_DATA_STORED, PersistRootState>;
export function resetDataStored() {
    return createAction( ACTION_NAMES.RESET_DATA_STORED, null );
};

export type TradingMainImportReceivedAction = Action<typeof ACTION_NAMES.MAIN_IMPORT_RECEIVED, PersistRootState>;
export function mainImportReceived( payload: PersistRootState ) {
    return ( dispatch: Dispatch ) => {
        dispatch( createAction( ACTION_NAMES.MAIN_IMPORT_RECEIVED, payload ) );
    };
};

export type TradingAddTradeAction = Action<typeof ACTION_NAMES.TRADING_ADD_TRADE, Trade>;
export function addTrade( payload: Trade ) {
    return createAction( ACTION_NAMES.TRADING_ADD_TRADE, payload );
};

export type TradingAddPlannedTradeAction = Action<typeof ACTION_NAMES.TRADING_ADD_PLANNED_TRADE, Trade>;
export function addPlannedTrade( payload: Trade ) {
    return createAction( ACTION_NAMES.TRADING_ADD_PLANNED_TRADE, payload );
};

export type TradingEditTradeAction = Action<typeof ACTION_NAMES.TRADING_EDIT_TRADE, Trade>;
export function editTrade( payload: Trade ) {
    return createAction( ACTION_NAMES.TRADING_EDIT_TRADE, payload );
};

export type TradingEditPlannedTradeAction = Action<typeof ACTION_NAMES.TRADING_EDIT_PLANNED_TRADE, Trade>;
export function editPlannedTrade( payload: Trade ) {
    return createAction( ACTION_NAMES.TRADING_EDIT_PLANNED_TRADE, payload );
};

export type TradingEditTradingPlanAction = Action<typeof ACTION_NAMES.TRADING_EDIT_TRADING_PLAN, Trade>;
export function editTradingPlan( payload: string ) {
    return createAction( ACTION_NAMES.TRADING_EDIT_TRADING_PLAN, payload );
};

export type TradingRemoveTradeAction = Action<typeof ACTION_NAMES.TRADING_REMOVE_TRADE, string>;
export function removeTrade( payload: string ) {
    return createAction( ACTION_NAMES.TRADING_REMOVE_TRADE, payload );
};

export type TradingRemovePlannedTradeAction = Action<typeof ACTION_NAMES.TRADING_REMOVE_PLANNED_TRADE, string>;
export function removePlannedTrade( payload: string ) {
    return createAction( ACTION_NAMES.TRADING_REMOVE_PLANNED_TRADE, payload );
};

export type TradingStoreLogoAction = Action<typeof ACTION_NAMES.TRADING_STORE_TICKER_LOGO, string>;
export function storeTickerLogo( payload: { [ticker: string]: string } ) {
    return createAction( ACTION_NAMES.TRADING_STORE_TICKER_LOGO, payload );
};



