/*Utils*/
import { Action, createAction } from "../../utils/action-type-helper"

import { Dispatch } from "redux"

/*Constant*/
import * as ACTION_NAMES from "../action_names";


/*Models*/
import { PersistRootState, BudgetEntry } from "../../models/index"
import { BudgetState } from './budget_states'

export type BudgetMainImportReceivedAction = Action<typeof ACTION_NAMES.MAIN_IMPORT_RECEIVED, PersistRootState>;
export function mainImportReceived( payload: PersistRootState ) {
    return ( dispatch: Dispatch ) => {
        dispatch( createAction( ACTION_NAMES.MAIN_IMPORT_RECEIVED, payload ) );
    };
};

export type BudgetUpdateExpensesEntryAction = Action<typeof ACTION_NAMES.EXPENSES_UPDATE_ENTRY, BudgetState>;
export function updateExpensesEntry( payload: BudgetState ) {
    return createAction( ACTION_NAMES.EXPENSES_UPDATE_ENTRY, payload );
};

export type BudgetUpdateIncomesEntryAction = Action<typeof ACTION_NAMES.INCOMES_UPDATE_ENTRY, BudgetState>;
export function updateIncomesEntry( payload: BudgetState ) {
    return createAction( ACTION_NAMES.INCOMES_UPDATE_ENTRY, payload );
};

export type BudgetUpdateTaxesEntryAction = Action<typeof ACTION_NAMES.TAXES_UPDATE_ENTRY, BudgetState>;
export function updateTaxesEntry( payload: BudgetState ) {
    return createAction( ACTION_NAMES.TAXES_UPDATE_ENTRY, payload );
};