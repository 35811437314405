import React from 'react';

interface Props {

}

interface State {

}

class Home extends React.Component<Props, State> {

    render() {
        return (
            <div>Home</div>
        );
    }

}

export default Home;

