import { Product } from "../../models"

export const initialState: ProductsState = {
    products: []
};

export interface ProductsState {
    products: Product[];
}

