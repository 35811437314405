/*Utils*/
import { Action, createAction } from "../../utils/action-type-helper"

import { Dispatch } from "redux"

/*Constant*/
import * as ACTION_NAMES from "../action_names";

/*Models*/
import { PersistRootState } from "../../models/index"
import { TaxesState } from './taxes_states'

export type TaxesMainImportReceivedAction = Action<typeof ACTION_NAMES.MAIN_IMPORT_RECEIVED, PersistRootState>;
export function mainImportReceived( payload: PersistRootState ) {
    return ( dispatch: Dispatch ) => {
        dispatch( createAction( ACTION_NAMES.MAIN_IMPORT_RECEIVED, payload ) );
    };
};

export type TaxesUpdateEntryAction = Action<typeof ACTION_NAMES.TAXES_UPDATE_ENTRY, TaxesState>;
export function updateTaxesEntry( payload: TaxesState ) {
    return createAction( ACTION_NAMES.TAXES_UPDATE_ENTRY, payload );
};